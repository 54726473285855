import React, { useState } from 'react';
import { BRICKFiltersContext } from '../contexts/BRICKFiltersContext';
import BRICKFiltersContainer from './BRICKFiltersContainer';
import WrapperGVD from './WrapperGVD';
import ProductsUnassigned from './ProductsUnassigned';

const WrapperBRICKFilters = () => {
  const [filters, setFilters] = useState({
    SEGMENT: '',
    FAMILY: '',
    CLASS: '',
    BRICK: ''
  });

  const resetFilters = () => {
    setFilters({
      SEGMENT: '',
      FAMILY: '',
      CLASS: '',
      BRICK: ''
    })
  };

  const areFiltersEmpty = () =>
    (!filters.BRICK.trim().length || !filters.CLASS.trim().length || !filters.FAMILY.trim().length || !filters.SEGMENT.trim().length);

  const [loadingCounter, setLoadingCounter] = useState(0);

  const showLoading = () => setLoadingCounter(currentCounter => currentCounter + 1)

  const hideLoading = () => setTimeout(() => setLoadingCounter(currentCounter => currentCounter - 1))

  return (
    <BRICKFiltersContext.Provider value={{ BRICKFilters: filters, setFilters, resetFilters, areFiltersEmpty, showLoading, hideLoading }}>
      <BRICKFiltersContainer />
      <div className="products-list">
        <div className="header">
          <span className="column">Brand</span>
          <span className="column auto">Product</span>
          <span className="column">Image</span>
          <span className="column">Ownership</span>
          <span className="column">Manufacture</span>
          <span className="column">Percentage</span>
        </div>
        <div className="container request-processing-indicator">
          <WrapperGVD />
          <ProductsUnassigned />
          {loadingCounter > 0 && (
            <div className="request-processing">
              <span>Processing...</span>
            </div>
          )}
        </div>
      </div>
    </BRICKFiltersContext.Provider>
  );
};
export default WrapperBRICKFilters;