import { useContext, useState } from "react";
import { HTTP_STATUS_CODES } from "../../api/API";
import { listProducts } from "../../api/SupplierProducts";
import { BRICKFiltersContext } from "./contexts/BRICKFiltersContext";

const useListProduct = (gvdID) => {
  const { BRICKFilters, areFiltersEmpty, hideLoading, showLoading } = useContext(BRICKFiltersContext);

  const [products, setProducts] = useState(null);

  const fetchProducts = async () => {
    if (areFiltersEmpty()) {
      return;
    }

    showLoading();
    setProducts([]);
    const res = await listProducts(gvdID,
      BRICKFilters.SEGMENT, BRICKFilters.FAMILY, BRICKFilters.CLASS, BRICKFilters.BRICK
    );
    hideLoading();

    if (res.status != HTTP_STATUS_CODES.OK) return false
    setProducts(res.data);
  };

  return [products, fetchProducts];
};

export default useListProduct;