import React from 'react';
import PageSkeletonComponent from '../../components/PageSkeletonComponent/PageSkeletonComponent';
import './GS1AUS.css';

const GS1AUS = () => {
  return (
    <PageSkeletonComponent>

      <h1>GS1 Australia Update Page</h1>

      <hr />
      <h2>Purpose of Page</h2>
      This is the home page for importing and validating the GS1 Australian Data.

      <h2>Who Can Access</h2>
      This page can only be accessed by authorised employees of Bring Back Australia.

    </PageSkeletonComponent>
  );
};

export default GS1AUS;