import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../rootReducer";
import { Auth } from '../../api/Auth';

export interface authState {
  processing: boolean;
  succeeded: boolean;
  failed: boolean;
  pwDontMatch: boolean;
  error: string;
  authUser:any
}

export const initialState: authState = {
  processing: false,
  succeeded: false,
  failed: false,
  pwDontMatch: false,
  error: "",
  authUser:{}
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setError: (state: authState, { payload }: PayloadAction<any>) => {
      state.error = payload;
    },
    setSucceeded: (state: authState, { payload }: PayloadAction<any>) => {
      state.succeeded = payload;
    },
    setFailed: (state: authState, { payload }: PayloadAction<any>) => {
      state.failed = payload;
    },
    setProcessing: (state: authState, { payload }: PayloadAction<any>) => {
      state.processing = payload;
    },
    setPwDontMatch: (state: authState, { payload }: PayloadAction<any>) => {
      state.pwDontMatch = payload;
    },
    setAuthUser: (state: authState, { payload }: PayloadAction<any>) => {
      state.authUser = payload;
    },
    resetState: (state: authState) => {
      return initialState;
    },
  },
});

export const forgotPassword = (emailInput) => async (dispatch: any) => {
  dispatch(setProcessing(true))
  const req = await Auth().ForgotPassword(emailInput);
  dispatch(setProcessing(false))
  if (req.status === 422){
    dispatch(setError(req.data.message));
    dispatch(setFailed(true));
  } else if (req == false || req.status !== 200) {
      dispatch(setFailed(true));
      return;
    }else {
    dispatch(setSucceeded(true));
  }
};

export const newAccount = (nameInput, emailInput, passwordInput) => async (dispatch: any) => {
  dispatch(setProcessing(true))
  const req = await Auth().PreRegister(nameInput, emailInput, passwordInput);
  dispatch(setProcessing(false))
    if (req == false || req.status !== 200) {
      dispatch(setFailed(true));
      dispatch(setError(
        req?.data?.error ||
            "An error has occurred during the registration process."
    ));
      return;
    }
    dispatch(setSucceeded(true));
};

export const signIn = (email, password) => async (dispatch: any) => {
  if (!email || !password) {
    dispatch(setError(!email ? "Email is required" : "Password is required"));
    return;
  }

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  if (!email.match(emailRegex)) {
    dispatch(setError("Invalid email format"));
    return;
  }

  dispatch(setProcessing(true));
  const req = await Auth().login(email, password);
  dispatch(setProcessing(false));
  if (req === false || req.status !== 200) {
    // console.log("req?.data?.error",req?.data?.error)
    dispatch(setFailed(true));
    dispatch(
        setError(req?.data?.error)
    );
    return;
  }

  return req;
};

export const SignupInfoInformation = (query) => async (dispatch: any) => {
  return await Auth().signupInfo(query);
}

export const signInPromptEndpoint = (query) => async (dispatch: any) => {
  await Auth().signInPrompt(query);
}


export const {
  setFailed,
  setError,
  resetState,
  setProcessing,
  setSucceeded,
  setPwDontMatch,
  setAuthUser
} = authSlice.actions;

export const auth = (state: RootState) => state.auth;

export default authSlice.reducer;
