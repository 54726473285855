import React from 'react';
import PageSkeletonComponent from '../../../components/PageSkeletonComponent/PageSkeletonComponent';
import './GS1GPC.css';

const GS1GPC = () => {
  return (
    <PageSkeletonComponent>

    <h1>GS1 - Global Product Catalogue</h1>
      <br />
      <hr />

      <a href="http://www.gs1.org/standards/gpc" target="_blank">
        GPC Standards
      </a>

      <h2>Confluence</h2>
      Click this link to go to the Product Requirements documentation :
      <a target="_blank" rel="noopener noreferrer" href="https://trickletreat.atlassian.net/wiki/spaces/BBA/pages/211779608/Global+Product+Classification+GPC">Global Product Classification (GPC)</a><br />
      <br />
      <hr />
            
      <h2>Who Can Access</h2>
      
      <h3>Public</h3>
      This information is readily available from the GS1 Website.  Any logged in user has access to this page.

      <h2>Purpose of Page</h2>
      This page is just to share information about the Global Product Catalogue and the origin of the standard for our users benefit.
      
    </PageSkeletonComponent>
  );
};

export default GS1GPC;