const getUser = () => JSON.parse(localStorage.getItem('user'));
const getUserSignupInfo = () => JSON.parse(localStorage.getItem('signupInfo'));
const getParentCompanies = () => JSON.parse(localStorage.getItem('parentCompanies'));

const setUser = (user) => {
  if (user === null) return;
  localStorage.setItem('user', JSON.stringify(user));
};

const setUserSignupInfo = (data) => {
  localStorage.setItem('signupInfo', JSON.stringify(data));
};

const setParentCompanies = (data) => {
  localStorage.setItem('parentCompanies', JSON.stringify(data));
};


const getToken = () => {
  const token = localStorage.getItem('token');
  if (token == null) return null;
  return JSON.parse(token).access_token;
};

const setToken = (token) => {
  if (token === null) return;
  localStorage.setItem('token', JSON.stringify(token));
};

const logoutUser = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('user');
  localStorage.removeItem('signupInfo');
  localStorage.removeItem('parentCompanies');
};

const isLoggedIn = () => getToken() != null;

export {
  getUser, setUser, getToken, setToken, isLoggedIn, logoutUser,setUserSignupInfo,getUserSignupInfo,setParentCompanies,getParentCompanies
};