const SmallCapChartInvestData = {
  labels: [''],
  datasets: [
    {
      label: 'Directors & Board Members',
      data: [50000],
      backgroundColor: '#FFE991',
    },
    {
      label: 'Retailers',
      data: [0],
      backgroundColor: '#EAFFB5',
    },
    {
      label: 'Suppliers',
      data: [0],
      backgroundColor: '#C1EDA1',
    },
    {
      label: 'Private',
      data: [0],
      backgroundColor: '#FFFFB3',
    },
    {
      label: 'Other',
      data: [0],
      backgroundColor: '#A3D993',
    },
  ],
};

const SmallCapChartInvestOptions = {
  maintainAspectRatio: false,
  indexAxis: 'y',
  scales: {
    y: {
      stacked: true,
      ticks: {
        beginAtZero: true
      },
    },
    x: {
      stacked: true,
      ticks: {
        // Include a dollar sign in the ticks
        callback: function (value, index, values) {
          return '$ ' + value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        }
      },
      max: 250000
    }
  }
};


const BirchalChartInvestData = {
  labels: [''],
  datasets: [
    {
      label: 'Private Investors',
      data: [0],
      backgroundColor: '#FFE991',
    },
    {
      label: 'Business Investors',
      data: [0],
      backgroundColor: '#A3D993',
    },
  ],
};


const BirchalChartInvestOptions = {
  maintainAspectRatio: false,
  indexAxis: 'y',
  scales: {
    y: {
      stacked: true,
      ticks: {
        beginAtZero: true
      },
    },
    x: {
      stacked: true,
      ticks: {
        // Include a dollar sign in the ticks
        callback: function (value, index, values) {
          return '$ ' + value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        }
      },
      max: 2500000
    }
  }
};

export { SmallCapChartInvestData, BirchalChartInvestData, SmallCapChartInvestOptions, BirchalChartInvestOptions };