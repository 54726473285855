import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useAuthContext, useAuthState } from "../context/AuthContext";
import SideMenuAdmin from "./SideMenuAdmin";
import SideMenuRetailer from "./SideMenuRetailer";
import SideMenuServiceProvider from "./SideMenuServiceProvider";
import SideMenuSupplier from "./SideMenuSupplier";
import SideMenuItem from "./SideMenuItem";
import "../assets/css/metisMenu.min.css";
import MetisMenu from "@metismenu/react";
import Footer from "../components/footer/Footer";
import { Disclosure, Transition } from "@headlessui/react";

const SideMenu = ({ toggle, setToggle }) => {
    const userAuth = useAuthContext();
    const user = userAuth.user;
    const userparentCompanies = userAuth.parentCompanies;
    const authState = useAuthState();
    const logout = async () => authState.logoutUser();
    const location = useLocation();

    const getMenuItems = () => {
        return (
            <>
                <SideMenuItem>
                    <li
                        className={`Ul_li--hover ${location.pathname == "/DashboardHome" && "mm-active"
                            }`}
                    >
                        <Link
                            to={"/DashboardHome"}
                            className={`flex items-center h-9 p-[0px_15px] mb-3 cursor-pointer ${location.pathname == "/DashboardHome" &&
                                "mm-active-list"
                                }`}
                        >
                            <i className="i-Bar-Chart text-lg mr-2 text-muted"></i>
                            <span className="item-name text-base text-muted">
                                {userAuth.newUser()
                                    ? "Get Started"
                                    : userparentCompanies?.ParentCompanyID ===
                                        undefined
                                        ? "Get Started"
                                        : "Dashboard"}
                            </span>
                        </Link>
                    </li>
                </SideMenuItem>

                {!userAuth.newUser() &&
                    userparentCompanies?.ParentCompanyID !== undefined && (
                        <li
                            className={`Ul_li--hover ${location.pathname == "/Account-Details" &&
                                "mm-active"
                                }`}
                        >
                            <Link
                                to={"/Account-Details"}
                                className={`flex items-center h-9 p-[0px_15px] mb-3 cursor-pointer ${location.pathname ==
                                    "/Account-Details" &&
                                    "mm-active-list"
                                    }`}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="w-5 h-5 text-lg mr-2 text-muted"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M9 6.75V15m6-6v8.25m.503 3.498l4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 00-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0z"
                                    />
                                </svg>

                                <span className="item-name text-base text-muted">
                                    My Company
                                </span>
                            </Link>
                        </li>
                    )}

                {userparentCompanies?.ParentCompanyID && getMenuToDisplay()}


                {(user.isSupplier || !user.isEmployee) && (
                    <li
                        className={`Ul_li--hover ${location.pathname ==
                            "/productBrowser" && "mm-active"
                            }`}
                    >
                        <Link
                            to={"/productBrowser"}
                            className={`flex items-center h-9 p-[0px_15px] mb-3 cursor-pointer ${location.pathname ==
                                "/productBrowser" &&
                                "mm-active-list"
                                }`}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-5 h-5 text-lg mr-2 text-muted"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M12 6.042A8.967 8.967 0 0 0 6 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 0 1 6 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 0 1 6-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0 0 18 18a8.967 8.967 0 0 0-6 2.292m0-14.25v14.25"
                                />
                            </svg>

                            <span className="item-name text-base text-muted">
                                Product Browser
                            </span>
                        </Link>
                    </li>
                )}
            </>
        );
    };

    const isUserAdmin =
        !user || user.isAdmin || user.isSuperUser || user.isEmployee;

    const getMenuToDisplay = () => {
        if (!user) {
            return null;
        }

        if (isUserAdmin) {
            return <SideMenuAdmin location={location} />;
        }

        if (userAuth.isUserSupplier()) {
            return <SideMenuSupplier />;
        }

        if (userAuth.isUserRetailer()) {
            return <SideMenuRetailer />;
        }

        if (userAuth.isUserServiceProvider()) {
            return <SideMenuServiceProvider />;
        }

        return null;
    };

    const boxShadowStyle = {
        boxShadow:
            "0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04)",
    };

    return (
        <>
            <div
                className={`h-screen fixed top-0 ${toggle ? "-left-60" : "left-0"
                    } w-60 z-[1000] bg-white transition-all duration-200 ease-in`}
                style={boxShadowStyle}
            >
                <div className="pr-3 text-center relative mt-4 mb-2 flex justify-center items-center">
                    <div
                        onClick={() => setToggle(true)}
                        className="cursor-pointer absolute top-0 right-0 w-12 h-7 flex justify-center items-center border rounded bg-gray-200"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="black"
                            className="w-6 h-6"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                            />
                        </svg>
                    </div>
                    <img
                        className="pl-3 w-full object-contain"
                        src="/BBA-Logo-TBl.png"
                        alt="Logo"
                    />
                </div>
                <div className="h-[calc(100vh-130px)] overflow-x-hidden flex justify-between flex-col overflow-y-auto">
                    <div className="side-nav">
                        <div className="main-menu">
                            <MetisMenu className="space-y-4">
                                {user && getMenuItems()}

                                <Disclosure>
                                    {({ open }) => (
                                        <>
                                            <Disclosure.Button className="flex w-full justify-between items-center gap-1 rounded-lg  px-4 pt-2 text-left text-sm font-medium text-black focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75">
                                                <div className="flex items-center justify-start ">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        strokeWidth={1.5}
                                                        stroke="currentColor"
                                                        className="h-5 w-5 text-lg mr-2 text-muted"
                                                    >
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                                                        />
                                                    </svg>
                                                    <span className="font-normal shrink-0 text-muted text-lg">
                                                        My Profile
                                                    </span>
                                                </div>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke-width="1.5"
                                                    stroke="currentColor"
                                                    className={`w-6 h-6 ${open
                                                        ? "rotate-180 transform"
                                                        : ""
                                                        } `}
                                                >
                                                    <path
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        d="m19.5 8.25-7.5 7.5-7.5-7.5"
                                                    />
                                                </svg>
                                            </Disclosure.Button>
                                            <Transition
                                                show={open}
                                                enter="transition duration-100 ease-out"
                                                enterFrom="transform scale-95 opacity-0"
                                                enterTo="transform scale-100 opacity-100"
                                                leave="transition duration-75 ease-out"
                                                leaveFrom="transform scale-100 opacity-100"
                                                leaveTo="transform scale-95 opacity-0"
                                            >
                                                <Disclosure.Panel className="px-4 flex flex-col pb-2 items-start bg-white">
                                                    <div className="w-full">
                                                        <div className="w-full">
                                                            <button
                                                                onClick={logout}
                                                                className="text-muted flex gap-2 items-center item-name text-base ml-3 rounded-2xl hover:bg-primary w-full py-2"
                                                            >
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    fill="none"
                                                                    viewBox="0 0 24 24"
                                                                    strokeWidth={
                                                                        1.5
                                                                    }
                                                                    stroke="currentColor"
                                                                    className="h-5 w-5 text-lg mr-2 text-muted"
                                                                >
                                                                    <path
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                        d="M8.25 9V5.25A2.25 2.25 0 0 1 10.5 3h6a2.25 2.25 0 0 1 2.25 2.25v13.5A2.25 2.25 0 0 1 16.5 21h-6a2.25 2.25 0 0 1-2.25-2.25V15m-3 0-3-3m0 0 3-3m-3 3H15"
                                                                    />
                                                                </svg>
                                                                Logout
                                                            </button>
                                                        </div>
                                                    </div>
                                                </Disclosure.Panel>
                                            </Transition>
                                        </>
                                    )}
                                </Disclosure>
                            </MetisMenu>
                        </div>
                    </div>
                    <Footer login={authState.isUserLoggedIn} />
                </div>
            </div>
            <div
                onClick={() => setToggle(false)}
                className={`cursor-pointer flex fixed left-0 bg-gray-200 rounded w-12 mt-2 justify-center items-center h-5 text-black top-0`}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="black"
                    className="w-6 h-6"
                >
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                    />
                </svg>
            </div>
        </>
    );
};

export default SideMenu;
