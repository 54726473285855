import React from 'react';
import PageSkeletonComponent from '../../components/PageSkeletonComponent/PageSkeletonComponent';
import './GS1USAHome.css';

const GS1USAHome = () => {
  return (
    <PageSkeletonComponent>

<h1>INSTRUCTIONS - GS1 USA - Home Page</h1>

      <hr /><br />


      <h2>Confluence</h2>
      Click this link to go to the Product Requirements documentation :
      <a target="_blank" rel="noopener noreferrer" href="">Loyalty Technical</a><br />

      <h2>Purpose of Page</h2>
      This page is the home page for the technical documentation for implemeting the loyalty program into POS Systems to integrate with our system.

      <h2>Who Can Access</h2>
      
      <h3>Bring Back Staff</h3>
      
      <h3>Supplier Subscribers</h3>

      <h3>Retailer Subscribers</h3>
      
      <h3>Service Subscribers</h3>

    </PageSkeletonComponent>
  );
};

export default GS1USAHome;