import React, { useState } from 'react';
import { SelectedRawWordsContext } from '../context/SelectedRawWordsContext';
import WrapperSelectedGVDDetail from './WrapperSelectedGVDDetail';
import RawWordsContainer from './RawWordsContainer';

const WrapperSelectedRawWords = () => {

  const [words, setWords] = useState([]);

  const addWord = (word) => {
    const currentWords = words;
    currentWords.push(word);
    setWords([...currentWords]);
  };

  const clearAndAddWord = (word) => setWords([word]);

  const removeWord = (word) => {
    const currentWords = words;
    const indexToRemove = currentWords.findIndex(element => element.WordMatchID == word.WordMatchID);

    if (indexToRemove == -1) return;

    currentWords.splice(indexToRemove, 1);

    setWords([...currentWords]);
  };

  const isWordSelected = (word) => words.findIndex(element => element.WordMatchID == word.WordMatchID) != -1;

  const clearWords = () => setWords([]);

  return (
    <SelectedRawWordsContext.Provider value={{ selectedWords: words, addWord, removeWord, isWordSelected, clearWords, clearAndAddWord }}>
      <div className="bg-white mr-2 flex flex-col p-3 rounded-xl w-3/5">
        <RawWordsContainer />
      </div>
      <div className="ml-1 flex-grow flex flex-col w-0">
        <WrapperSelectedGVDDetail />
      </div>
    </SelectedRawWordsContext.Provider>
  );
};

export default WrapperSelectedRawWords;