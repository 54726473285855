import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAuthContext } from '../../../context/AuthContext';
import './NavbarComponent.css';

export default function (props) {

  const userAuth = useAuthContext();
  const user = userAuth.user;

  const logout = async () => userAuth.logoutUser();

  const isSticky = props.isSticky;

  return (
    <div className={'Navbar ' + (isSticky ? 'Sticky' : '')}>
      <Link to="/"><img className="Logo" src="/BBA-Logo-WGG.svg" alt="Bring Back Australia" /></Link>
      <ul className="Menu">
        <li>
          <a><span>Signup</span>
            <div className="LinkEffect" />
          </a>
          <ul>
            <li>
              <Link to="/SignupHome">Signup Home</Link>
            </li>
            <li><Link to="/Signup/SignupIntro">Signup Intro</Link></li>
            <li><Link to="/Signup/SignupType">Signup Type</Link></li>
            <li><Link to="/Signup/SignupSubscription">Signup Subscription</Link></li>
            <li><Link to="/Signup/SignupUsers">Signup Users</Link></li>
          </ul>
        </li>
        <li>
          <a><span>Entity</span>
            <div className="LinkEffect" />
          </a>
          <ul>
            <li>
              <Link to="/EntityHome">Entity Home</Link>
            </li>
            <li><Link to="/Entity/EntityUsers">Our Users</Link></li>
            <li><Link to="/Entity/EntitySubscription">Subscription</Link></li>
            <li><Link to="/Entity/EntityLoyalty">Loyalty Membership</Link></li>
          </ul>
        </li>
        <li>
          <a><span>Account</span>
            <div className="LinkEffect" />
          </a>
          <ul>
            <li><Link to="/AccountHome">Account Home</Link></li>
            <li><Link to="/Account/AccountDetails">Account Details</Link></li>
            <li><Link to="/Account/AccountTransactions">Account Transactions</Link></li>
          </ul>
        </li>
        <li>
          <a><span>Supplier</span>
            <div className="LinkEffect" />
          </a>
          <ul>
            <li><Link to="/SupplierHome">Supplier Home</Link></li>
            <li><Link to="/Supplier/SupplierAnalytics">Supplier Analytics</Link></li>
            <li><Link to="/Supplier/SupplierAdvertising">Supplier Advertising</Link></li>
            <li><Link to="/Supplier/SupplierLocations">Supplier Locations</Link></li>
            <li><Link to="/Supplier/SupplierOverrides">Supplier Overrides</Link></li>
            <li><Link to="/Supplier/SupplierPrefixes">Supplier Prefixes</Link></li>
            <li><Link to="/Supplier/SupplierProducts">Supplier Products</Link></li>
          </ul>
        </li>
        <li>
          <a><span>Product</span>
            <div className="LinkEffect" />
          </a>
          <ul>
            <li><Link to="/ProductHome">Product Home</Link></li>
            <li><Link to="/Product/ProductScan">Scan</Link></li>
            <li><Link to="/Product/ProductBrands">Brand</Link></li>
            <li><Link to="/Product/ProductBricks">Brick / Function / Variant</Link></li>
            <li><Link to="/Product/ProductImages">Images</Link></li>
          </ul>
        </li>
        <li>
          <a><span>Retailer</span>
            <div className="LinkEffect" />
          </a>
          <ul>
            <li><Link to="/RetailerHome">Retailer Home</Link></li>
            <li><Link to="/Retailer/RetailerAdvertising">Retailer Advertising</Link></li>
            <li><Link to="/Retailer/RetailerAnalytics">Retailer Analytics</Link></li>
            <li><Link to="/Retailer/RetailerProducts">Retailer Products</Link></li>
            <li><Link to="/Retailer/RetailerStores">Retailer Stores</Link></li>
          </ul>
        </li>
        <li>
          <a><span>Service</span>
            <div className="LinkEffect" />
          </a>
          <ul>
            <li><Link to="/ServiceHome">Service Home</Link></li>
            <li><Link to="/Service/ServiceAdvertising">Advertising</Link></li>
            <li><Link to="/Service/ServiceAnalytics">Analytics</Link></li>
            <li><Link to="/Service/ServiceStores">Stores</Link></li>
            <li><Link to="/Service/ServiceServices">Services</Link></li>
          </ul>
        </li>

        <li>
          <a><span>Loyalty</span>
            <div className="LinkEffect" />
          </a>
          <ul>
            <li><Link to="/LoyaltyHome">Loyalty Home</Link></li>
            <li><Link to="/Loyalty/LoyaltyProgram">Programs</Link></li>
            <li><Link to="/Loyalty/LoyaltyPartners">Partners</Link></li>
            <li className="SubMenu">
              <a>Technical</a>
              <ul>
                <li><Link to="/Loyalty/LoyaltyEndpoints">Loyalty Endpoints</Link></li>
                <li><Link to="/Loyalty/LoyaltyImplementations">Loyalty Implementations</Link></li>
                <li><Link to="/Loyalty/LoyaltyIntegration">Loyalty Integration</Link></li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <a><span>Database</span>
            <div className="LinkEffect" />
          </a>
          <ul>
            <li><Link to="/DatabaseHome">Database Home Page</Link></li>
            <li><Link to="/Database/DatabaseAPI">API</Link></li>
            <li><Link to="/Database/DatabaseBrowser">Database Browser</Link></li>
          </ul>
        </li>
        <li>
          <a><span>Database Updating</span>
            <div className="LinkEffect" />
          </a>
          <ul>
            <li><Link to="/DatabaseUpdateHome">Database Update Home</Link></li>
            <li><Link to="/DatabaseUpdate/GS1Global">GS1 Global</Link></li>
            <li><Link to="/DatabaseUpdate/GS1AUS">GS1 Australia</Link></li>
            <li><Link to="/DatabaseUpdate/GS1USA">GS1 USA</Link></li>
          </ul>
        </li>

        <li>
          <a><span>Support</span>
            <div className="LinkEffect" />
          </a>
          <ul>

            <li><Link to="/SupportHome">Support Home Page</Link></li>
            <li className="SubMenu">
              <a>GS1</a>
              <ul>
                <li><Link to="/Instructions/GS1/GS1Home">GS1 Homepage</Link></li>
                <li><Link to="/Instructions/GS1/GS1GPC">Global Product Catalogue</Link></li>
                <li><Link to="/Instructions/GS1/GS12">GS12</Link></li>
                <li><Link to="/Instructions/GS1/GS13">GS13</Link></li>
              </ul>
            </li>
            <li className="SubMenu">
              <a>GS1 AUS</a>
              <ul>
                <li><Link to="/Instructions/GS1AUS/GS1AUSHome">GS1 AUS Homepage</Link></li>
                <li><Link to="/Instructions/GS1AUS/GS1AUSImages">Images</Link></li>
                <li><Link to="/Instructions/GS1AUS/GS1AUS2">GS1 AUS2</Link></li>
                <li><Link to="/Instructions/GS1AUS/GS1AUS3">GS1 AUS3</Link></li>
              </ul>
            </li>
            <li className="SubMenu">
              <a>GS1 USA</a>
              <ul>
                <li><Link to="/Instructions/GS1USA/GS1USAHome">GS1 USA Homepage</Link></li>
                <li><Link to="/Instructions/GS1USA/GS1USAImages">Images</Link></li>
                <li><Link to="/Instructions/GS1USA/GS1USA2">GS1 AUS2</Link></li>
                <li><Link to="/Instructions/GS1USA/GS1USA3">GS1 AUS3</Link></li>
              </ul>
            </li>
          </ul>
        </li>

        <li>
          <a><span>Testing</span>
            <div className="LinkEffect" />
          </a>
          <ul>
            <li><Link to="/TestingHome">Testing Home Page</Link></li>
            <li><Link to="/Testing/TestingSearch">Product Scan Search</Link></li>
          </ul>
        </li>
      </ul>

      <div className="SearchBox">
        <input type="text" placeholder="Search..." />
      </div>

      {user !== null && (
        <div className="UserName">
          <ul className="Menu">
            <li>
              <a><span>{user.NameFirst}</span>
                <div className="LinkEffect" />
              </a>
              <ul>
                <li><Link to="/UserHome">User Home Page</Link></li>
                <li><Link to="/User/UserProfile">User Profile Page</Link></li>
                <li><a onClick={logout}>Logout</a></li>
              </ul>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};
