import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";
import "./SignInPage.css";
import { useAppSelector, useAppDispatch } from "../../store/store";
import { auth, signIn, setError, SignupInfoInformation, resetState, setAuthUser, setSucceeded } from "../../store/slices/authSlice";
import AuthPrompt from "../../components/AuthPrompt";

export default function SignInPage(props) {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const userContext = useAuthContext();
	const { failed, processing, error, authUser, succeeded } = useAppSelector(auth);
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");

	async function handleSubmit(event) {
		event.preventDefault();

		if (processing) {
			return;
		}

		const req = await dispatch(signIn(email.trim(), password));

		if (req && req?.status === 200) {

			if (req.data?.user?.isAppUser && !req.data?.user?.isWebUser) {
				dispatch(setSucceeded(true))
				dispatch(setAuthUser(req.data?.user))
				dispatch(setError('You are Mobile App User'))
				return;
			}

			setTimeout(async () => {
				if (req?.data?.ParentCompanies && req?.data?.ParentCompanies?.[0]) {
					userContext.setParentCompaniesInformation(req.data.ParentCompanies[0]);
				}

				setEmail("");
				setPassword("");
				dispatch(setError(""));

				userContext.setUser(req.data.user);
				userContext.setToken({
					access_token: req.data.access_token,
				});

				if (!(req?.data?.ParentCompanies && req?.data?.ParentCompanies?.[0])) {
					try {
						let SignupInfoData = await dispatch(SignupInfoInformation({ FK_UserID: req.data?.user?.UserID }));
						userContext.setSignupInfoInformation(SignupInfoData?.data?.SignupInfo[0]);
					} catch (error) {
						// Handle the error from dispatch(SignupInfoInformation(...)) if needed
						console.error("Error in SignupInfoInformation:", error);
					}
				}
			});
		}
	}

	const clearError = () => {
		dispatch(setError(""));
	};

	return (
		<div className="w-full flex justify-center h-full items-center overflow-hidden">
			<div className="lg:max-w-lg md:max-w-lg w-full mx-auto bg-white px-4 pb-3 rounded-sm shadow-xl">
				<form onSubmit={handleSubmit}>
					<div className="flex flex-col justify-center items-center">
						<img
							className="w-[300px]"
							src="BBA-Logo-TBl-S.png"
							alt="BBA-Logo-TBl-S"
						/>
						<p className="font-bold text-center text-lg">Welcome back!</p>
					</div>

					<div className="flex flex-col mb-4">
						<label>Email</label>
						<input
							className={`rounded-full border py-1.5 px-2 ${failed ? "border-red-500" : "border-gray"
								}`}
							type="text"
							placeholder="Enter your email..."
							value={email}
							onChange={(e) => {
								setEmail(e.target.value);
								clearError();
							}}
						/>
					</div>
					<div className="flex flex-col">
						<label>Password</label>
						<input
							className={`rounded-full border py-1.5 px-2 ${failed ? "border-red-500" : "border-gray"
								}`}
							type="password"
							placeholder="Enter your password..."
							value={password}
							onChange={(e) => {
								setPassword(e.target.value);
								clearError();
							}}
						/>
					</div>
					{error.length > 2 && <div className="text-red-500 font-bold mt-3">{error}</div>}
					<div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-5 mt-4">
						<button
							className={`rounded-full text-white py-1.5 lg:w-24 md:w-32 w-full ${processing ? "bg-gray-400" : "bg-primary"
								}`}
							disabled={processing}
							onClick={handleSubmit}
						>
							Login
						</button>
						<div className="flex w-full justify-end gap-2 items-center">
							<Link to="/ForgotPassword" className="whitespace-nowrap">Forgot Password?</Link>|
							<Link to="/SignUp" className="whitespace-nowrap">Create an Account</Link>
						</div>
					</div>
				</form>
			</div>
			<AuthPrompt open={succeeded} requestType={'SignIn'} payload={{ firstname: authUser?.NameFirst, email: authUser?.Email, id: authUser.UserID }} />
		</div>
	);
}
