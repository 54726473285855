import React from 'react';
import PageSkeletonComponent from '../components/PageSkeletonComponent/PageSkeletonComponent';
import './DatabaseUpdateHome.css';

const DatabaseUpdateHome = () => {
  return (
    <PageSkeletonComponent>

      <h1>DATABASE UPDATE - Home Page (Dashboard)</h1>

      <hr />

      <h2>Purpose of Page</h2>
      This is the home page for database updating

      <h2>Who Can Access</h2>
      This page can only be accessed by authorised employees of Bring Back Australia.

    </PageSkeletonComponent>
  );
};

export default DatabaseUpdateHome;