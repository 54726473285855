import React from 'react';
import PageSkeletonComponent from '../../components/PageSkeletonComponent/PageSkeletonComponent';
import './RetailerStores.css';

const RetailerStores = () => {
  return (
    <PageSkeletonComponent>
     <h1>RETAILER - Stores Page</h1>

      <hr />

      <h2>Purpose of Page</h2>
      Listing of the stores associated with a retailer.<br /><br />
      The stores can be grouped, filtered by Region, State, Size... etc...
      
      <h2>Who Can Access</h2>
      <b>WHO IS EXPECTED TO ACCESS THIS PAGE</b>

    </PageSkeletonComponent>
  );
};

export default RetailerStores;