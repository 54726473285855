import React from "react";
import { Link, useLocation } from "react-router-dom";

const SideMenuSupplier = () => {
    const location = useLocation();

    return (
        <>
            <li
                className={`Ul_li--hover ${location.pathname == "/ManageBrands" && "mm-active"
                    }`}
            >
                <Link
                    to={"/ManageBrands"}
                    className={`flex items-center h-9 p-[0px_15px] mb-3 cursor-pointer ${location.pathname == "/ManageBrands" && "mm-active-list"
                        }`}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        className="w-5 h-5 text-lg mr-2 text-muted"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M2.25 13.5h3.86a2.25 2.25 0 0 1 2.012 1.244l.256.512a2.25 2.25 0 0 0 2.013 1.244h3.218a2.25 2.25 0 0 0 2.013-1.244l.256-.512a2.25 2.25 0 0 1 2.013-1.244h3.859m-19.5.338V18a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18v-4.162c0-.224-.034-.447-.1-.661L19.24 5.338a2.25 2.25 0 0 0-2.15-1.588H6.911a2.25 2.25 0 0 0-2.15 1.588L2.35 13.177a2.25 2.25 0 0 0-.1.661Z"
                        />
                    </svg>

                    <span className="item-name text-base text-muted">
                        My Brands
                    </span>
                </Link>
            </li>
            <li
                className={`Ul_li--hover ${location.pathname == "/DashboardHome" && "mm-active"
                    }`}
            >
                <Link
                    to={"/ProductsPage"}
                    className={`flex items-center h-9 p-[0px_15px] mb-3 cursor-pointer ${location.pathname == "/ProductsPage" &&
                        "mm-active-list"
                        }`}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        className="w-5 h-5 text-lg mr-2 text-muted"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0 1 11.186 0Z"
                        />
                    </svg>
                    <span className="item-name text-base text-muted">
                        My Products
                    </span>
                </Link>
            </li>
        </>
    );
};

export default SideMenuSupplier;
