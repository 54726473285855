import React, { useContext, useEffect, useState } from 'react'
import { getUnassignedWords, listIgnoresSubstitutesForWordMatch, listProductsRelatedToWordMatch } from '../../../../api/GroupVariantDetail';
import { HTTP_STATUS_CODES } from '../../../../api/API';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faListUl } from '@fortawesome/free-solid-svg-icons'
import { SelectedRawWordsContext } from '../context/SelectedRawWordsContext';
import RawWordsProductList from './RawWordsProductList';
import RawWordsIgnoreSubstitute from './RawWordsIgnoreSubstitute';
import { AutoSizer, List } from 'react-virtualized';
import { ContextSelectedBrick } from '../../contexts/ContextSelectedBrick';
import { useFormFieldOnChange } from '../../../../utils/FormHooks';
import { useAppSelector, useAppDispatch } from "../../../../store/store";
import {
	gvdValidate,
	fetchUnassignedWords,
  fetchListProducts,
  fetchIgnoreSubstitute
} from "../../../../store/slices/gvdValidateSlice";

const RawWordsContainer = () => {
  const dispatch = useAppDispatch();
  const { rawWords, proModalProps, processing } = useAppSelector(gvdValidate);
  const { BRICK } = useContext(ContextSelectedBrick);

  const { selectedWords, addWord, removeWord, isWordSelected, clearAndAddWord, clearWords } = useContext(SelectedRawWordsContext);

  const [rawWordsSearchBox, setRawWordsSearchBox, onChangeRawWordsSearchBox] = useFormFieldOnChange('');

  const [modalShow, setModalShow] = useState(false);
  const [proModalShow, setProModalShow] = useState(false);
  const [modalProps, setModalProps] = useState({});

  const handleShow = () => setModalShow(true);
  const handleClose = () => setModalShow(false);
  const handleProShow = () => setProModalShow(true);
  const handleProClose = () => setProModalShow(false);

  useEffect(() => {
    fetchRawWords();
  }, [BRICK]);

  const fetchRawWords = async () => {
    if (BRICK == null) return;
    dispatch(fetchUnassignedWords(BRICK, rawWordsSearchBox))
  };

  const onSelectWord = (wordIndex) => {
    return () => {
      if (isWordSelected(rawWords[wordIndex])) {
        removeWord(rawWords[wordIndex]);
      } else {
        addWord(rawWords[wordIndex]);
      }
    };
  };

  const listProducts = async (e, wordIndex) => {
    e.stopPropagation();
    const wordMatch = rawWords[wordIndex];
    dispatch(fetchListProducts(wordMatch.WordMatchID, wordMatch))
    handleProShow();
  };

  const showIgnoreSubstitute = async (e, wordIndex) => {
    e.stopPropagation();
    const wordMatch = rawWords[wordIndex];

    const json = await dispatch(fetchIgnoreSubstitute(wordMatch.WordMatchID));

    if (json.status != HTTP_STATUS_CODES.OK) {
      return;
    }

    const onWordMatchUpdate = (wordMatch) => {
      // const currentWordMatches = words;
      // currentWordMatches[wordIndex] = wordMatch;
      // setWords([...currentWordMatches]);
      clearWords();
      fetchRawWords();
    };

    clearAndAddWord(wordMatch);
    setModalProps({
      onWordMatchUpdate,
      wordMatch,
      ignoresSubstitutes: json.data,
    })
    handleShow();
  };

  function rowRenderer({
    key, // Unique key within array of rows
    index, // Index of row within collection
    isScrolling, // The List is currently being scrolled
    isVisible, // This row is visible within the List (eg it is not an overscanned row)
    style, // Style object to be applied to row (to position it)
  }) {

    const isRowSelected = isWordSelected(rawWords[index]);
    return (
      <div key={key} style={style} className={`py-1 px-2 flex flex-row items-center cursor-pointer gap-2 ${index%2 !== 0 ? 'bg-white' : 'bg-[#cce4dc]'}`} onClick={onSelectWord(index)}>
        <input readOnly={true} type="checkbox" checked={isRowSelected} />
        <FontAwesomeIcon icon={faListUl} color="#707070" onClick={(e) => listProducts(e, index)} />
        <div className="gvd-word-description">{rawWords[index].Words.join(' - ')}</div>
        <div className='flex-grow flex justify-end'>
          <FontAwesomeIcon icon={faPen} color="#707070" onClick={(e) => showIgnoreSubstitute(e, index)} />
        </div>
      </div>
    );
  }

  const doRawWordsSearch = (event) => {
    event.preventDefault();
    if (!rawWordsSearchBox.trim().length) {
      return;
    }
    fetchRawWords();
  };

  return (
    <>
      <div className="p-2 border-b border-[#707070] flex items-center gap-2">
        <p className="font-semibold whitespace-nowrap">Raw Words</p>
        <div className="w-full">
          {rawWords.length > 0 && (
            <form onSubmit={doRawWordsSearch}>
              <input className='w-full border-gray rounded py-1 border px-3' onChange={onChangeRawWordsSearchBox} value={rawWordsSearchBox} type="text" placeholder="Search..." />
            </form>
          )}
        </div>
        {selectedWords.length > 0 && <p className="whitespace-nowrap">
          Rows selected: {selectedWords.length}
        </p>}
      </div>
      <div className="gvd-words-list container-body p-0 flex-grow flex flex-col h-0 overflow-y-auto relative">
        {!processing && rawWords.length == 0 ? (
          <div className='h-full flex justify-center items-center'>
            <h4 className='text-lg'>No records</h4>
          </div>
        ) : null}

        {processing && (
          <div className="h-full flex justify-center items-center">
            <h4 className='text-lg'>Loading ...</h4> 
          </div>
        )}

        {rawWords.length > 0 &&
          <AutoSizer>
            {({ height, width }) => (
              <List
                width={width}
                height={height}
                rowHeight={28}
                rowCount={rawWords.length}
                rowRenderer={rowRenderer}
              />
            )}
          </AutoSizer>
        }
        <RawWordsIgnoreSubstitute {...modalProps} modalShow={modalShow} handleClose={handleClose} />
        <RawWordsProductList {...proModalProps} proModalShow={proModalShow} handleProClose={handleProClose} />
      </div>
    </>
  );
};

export default RawWordsContainer;