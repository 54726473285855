import DashboardContainer from "../../dashboard-components/DashboardContainer";
import React, { useEffect, useCallback, useState } from "react";
import { useAppSelector, useAppDispatch } from "../../store/store";
import ReactDataGrid, { filterTypes } from "@inovua/reactdatagrid-enterprise";
import { HTTP_STATUS_CODES } from "../../api/API";
import {
	productsPage,
	setActive,
	setLimit,
	setPage,
	fetchData,
	setDataSource, setFilterValues,
} from "../../store/slices/productsPageSlice";
import "@inovua/reactdatagrid-enterprise/index.css";
import defaultColumns from "./productGridColumns";
import defaultFilterValue from "./productGridFilters";
import { Link } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";
import Tooltip from "../../components/tootip";
import Loader from "../../components/loader/Loader";
import AssignBrandDialog from "../../components/Dialogs/AssignBrandDialog";
import AssignProductCategory from "../../components/Dialogs/AssignProductCategory";
import AssignAttributes from "../../components/Dialogs/AssignAttributes";

const ProductsPage = () => {
	const [loading, setLoading] = useState(false);
	const dispatch = useAppDispatch();
	const userAuth = new useAuthContext();
	const userparentCompanies = userAuth.parentCompanies;
	const { active, dataSource, page, limit, filterValues } = useAppSelector(productsPage);
	const [timeoutId, setTimeoutId] = useState(null);
	const [gridRef, setGridRef] = useState(null)
	const [assignBrandDialog, setAssignBrandDialog] = useState(false);
	const [assignProductCategory, setAssignProductCategory] = useState(false);
	const [assignAttributes, setAssignAttributes] = useState(false);
	const [selected, setSelected] = useState({ 2: true, 5: true });
	const handleFilterValueChange = (o) => {

		if (timeoutId) {
			clearTimeout(timeoutId);
		}

		// Set a new timeout
		const newTimeoutId = setTimeout(() => {
			// Dispatch your action after the timeout
			dispatch(setFilterValues(o));
		}, 2000); // Adjust the timeout value as needed (2 second in this example)

		// Save the new timeout ID in the state
		setTimeoutId(newTimeoutId);
	};

	useEffect(() => {
		loadData();
	}, [active, page, limit, filterValues]);


	const loadData = () => {

		const fetchApiData = async ({ ParentCompanyID, filter, page, limit }) => {
			setLoading(true);
			const res = await dispatch(
				fetchData(ParentCompanyID, filter, page, limit)
			);

			if (res.status !== HTTP_STATUS_CODES.OK) {
				setLoading(false);
				return Promise.resolve({ data: [], count: 0 });
			}
			setLoading(false);
			return Promise.resolve({
				data: res.data.data,
				count: res.data.total,
			});
		};

		dispatch(setDataSource(fetchApiData({
			ParentCompanyID: userparentCompanies?.ParentCompanyID,
			active: active,
			filter: filterValues,
			page: page,
			limit: limit
		})));
	};

	const onSelectionChange = useCallback(({ selected }) => {
		setSelected(selected)
	}, []);


	const renderRowContextMenu = (menuProps, { rowProps, cellProps }) => {
		const { GTIN } = rowProps.data;

		menuProps.autoDismiss = true;
		menuProps.items = [
			{
				label:
					<Link to={`/edit-product/${GTIN}`} className="w-full">
						<button style={{ cursor: 'pointer' }} className="w-full">Edit Details</button>
					</Link>
			},
			// {
			// 	label: <button style={{ cursor: 'pointer' }} className="w-full" onClick={() => setAssignBrandDialog(true)}>Brand</button>
			// },
			// {
			// 	label: <button style={{ cursor: 'pointer' }} className="w-full" onClick={() => setAssignProductCategory(true)}>Categories</button>
			// },
			// {
			// 	label: <button style={{ cursor: 'pointer' }} className="w-full" onClick={() => setAssignAttributes(true)}>Attributes</button>
			// }
		];
	};
	return (
		<DashboardContainer title={""}>
			<div className="">
				<div className="flex justify-start gap-3 items-center rounded bg-primary pt-3 px-2">
					<button
						onClick={() => dispatch(setActive(1))}
						className={`${active === 1
							? "bg-white text-black"
							: "text-white"
							} uppercase !overflow-visible relative rounded-b-none font-medium text-sm rounded-t px-10 py-2`}
					>
						<Tooltip trigger={<p className="flex items-center gap-2 ">gs1<p className="w-4 h-4 lowercase flex justify-center items-center bg-black text-white rounded-full">i</p></p>} placement={'left'} tooltipText={'This page shows your Product Data (GS1)'} />
					</button>

					<button
						onClick={() => dispatch(setActive(2))}
						className={`${active === 2
							? "bg-white text-black"
							: "text-white"
							} uppercase !overflow-visible relative rounded-b-none font-medium text-sm rounded-t px-10 py-2`}
					>
						<Tooltip trigger={<p className="flex items-center gap-2">Categorised <p className="w-4 lowercase flex justify-center items-center h-4 bg-black text-white rounded-full">i</p></p>} placement={'left'} tooltipText={'This page shows your Product Data (Categorised)'} />

					</button>

					<button
						onClick={() => dispatch(setActive(3))}
						className={`${active === 3
							? "bg-white text-black"
							: "text-white"
							} uppercase !overflow-visible relative rounded-b-none font-medium text-sm rounded-t px-10 py-2`}
					>
						<Tooltip trigger={<p className="flex items-center gap-2">Non-Categorised <p className="w-4 lowercase flex justify-center items-center h-4 bg-black text-white rounded-full">i</p></p>} placement={'left'} tooltipText={'This page shows your Product Data (Non-Categorised)'} />
					</button>
				</div>
				<div className="bg-white rounded-t-none rounded-b">
					<ReactDataGrid
						licenseKey={'AppName=TrickleTreatApp,Company=TrickleTreat,ExpiryDate=2023-11-18,LicenseDeveloperCount=1,LicenseType=single_app,Ref=TrickleTreatLicenseRef,Z=14765888661045189689-1957688953-209693348514765888662114428621'}
						onReady={setGridRef}
						idProperty="ProductID"
						style={{ marginTop: 10, minHeight: '90vh' }}
						columns={defaultColumns}
						checkboxColumn
						onSelectionChange={onSelectionChange}
						defaultFilterValue={filterValues}
						filterTypes={filterTypes}
						rowHeight={90}
						renderRowContextMenu={renderRowContextMenu}
						pagination="remote"
						onFilterValueChange={(o) => handleFilterValueChange(o)}
						dataSource={dataSource}
						onSkipChange={(o) => dispatch(setPage(Math.round(Math.max(o - 1, 0) / limit) + 1))}
						onLimitChange={(o) => dispatch(setLimit(o))}
						defaultLimit={10}
					/>
				</div>
			</div>

			<AssignBrandDialog setAssignBrandDialog={setAssignBrandDialog} assignBrandDialog={assignBrandDialog} />
			<AssignProductCategory setAssignProductCategory={setAssignProductCategory} assignProductCategory={assignProductCategory} />
			<AssignAttributes setAssignAttributes={setAssignAttributes} assignAttributes={assignAttributes} />
		</DashboardContainer>
	);
};

export default ProductsPage;
