import React from 'react';
import PageSkeletonComponent from '../../components/PageSkeletonComponent/PageSkeletonComponent';
import './SupplierAnalytics.css';

const SupplierAnalytics = () => {
  return (
    <PageSkeletonComponent>

     <h1>SUPPLIER - Analytics Page</h1>

      <hr />

      <h2>Purpose of Page</h2>
      General page to show whatever analytics we have that suits suppliers, what we show to users will be dependent upon the subscription level of the entity.
      
      <h2>Who Can Access</h2>
      <b>WHO IS EXPECTED TO ACCESS THIS PAGE</b>

    </PageSkeletonComponent>
  );
};

export default SupplierAnalytics;