import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../rootReducer";
import {
  listCountries,
  listselection,
  listStates,
  listClientSecret,
  getAccountContactDetails,
  updateAccountContactDetail,
  searchCompanyByGTIN,
  claimCompany,
  confirmTermsConditions,
  updateAccountReview,
  removeCompanyByGTIN,
  updateSignup,createCompany,
  updateCompanyImageDetail,
  getAccountContactDetailsForLoginUser,
  listStatesForAccountDetails, updateAccountContactDetailLoginUser
} from '../../api/SignUp';
import {Auth} from "../../api/Auth";
import {getUser} from "../../provider/AuthProvider";

export interface IOrderState {
  country: Array<any>;
  state: Array<any>;
  total: number;
  is_more: boolean;
  loader: boolean;
  error: object;
  form: any;
  wizardIndex: number;
  clientSecret: string;
  isContactValidate: boolean;
  isShippingValidate: boolean;
  isPaymentValidate: boolean;
  numberOfEmployees:Array<any>;
  annualTurnover:Array<any>;
  industries:Array<any>;
}

export const initialState: IOrderState = {
  country: [],
  state: [],
  form: {},
  total: 0,
  is_more: false,
  loader: false,
  error: {},
  wizardIndex: 0,
  clientSecret: "",
  isContactValidate: false,
  isShippingValidate: false,
  isPaymentValidate: false,
  numberOfEmployees:[],
  annualTurnover:[],
  industries:[],
};

export const getStartedSlice = createSlice({
  name: "getStarted",
  initialState,
  reducers: {
    setCountry: (state: IOrderState, { payload }: PayloadAction<any>) => {
      state.country = payload;
    },
    setState: (state: IOrderState, { payload }: PayloadAction<any>) => {
      state.state = payload;
    },
    setNumberOfEmployee: (state: IOrderState, { payload }: PayloadAction<any>) => {
      state.numberOfEmployees = payload;
    },
    setAnnualTurnover: (state: IOrderState, { payload }: PayloadAction<any>) => {
      state.annualTurnover = payload;
    },
    setIndustries:(state: IOrderState, { payload }: PayloadAction<any>) => {
      state.industries = payload;
    },
    setAccountDetail: (state: IOrderState, { payload }: PayloadAction<any>) => {
      if(payload?.page){
      state.form = payload;
      }else{
const form = {
        ...state.form,
        company:payload.company,
        first_name:payload.user?.NameFirst,
        last_name:payload.user?.NameSurname,
        prefered_name:payload.user?.NamePreferred,
        position:payload.user?.Position,
        direct_landline_number:payload.user?.PhoneDirect,
        mobile_number:payload.user?.PhoneMobile,
        company_name:payload.signup?.CompanyName,
        trading_name:payload.signup?.TradingName,
        company_abn:payload.signup?.CompanyABN,
        number_of_employees:payload.signup?.EmployeeCount,
        annual_turnover:payload.signup?.CompanyTurnover,
        industry:payload?.signup?.FK_MainIndustry.toString(),
        OtherIndustry:payload?.signup?.OtherIndustry,
        company_phone_number:payload.signup?.PhoneCompany,
        website:payload.signup?.Website,
        company_address_1:payload.signup?.Address1,
        company_address_2:payload.signup?.Address2,
        city:payload.signup?.City,
        state:payload.signup?.State,
        postcode:payload.signup?.Postcode,
        country_id:(payload.signup?.FK_Country || state.form?.country_id),
        prefix:payload?.prefix,
        signup:payload?.signup,
        acceptTerms:false,
      }
      state.form = form;
      }
    },
    setAccountDetailLoginUser: (state: IOrderState, { payload }: PayloadAction<any>) => {
      state.form = {
        ...state.form,
        ...payload,
      };
    },
    setCompany: (state: IOrderState, { payload }: PayloadAction<any>) => {
      return (state = {
        ...state,
        form: {
          ...state.form,
          company: payload,
        },
      });
    },
    setIndustry: (state: IOrderState, { payload }: PayloadAction<any>) => {
      return (state = {
        ...state,
        form: {
          ...state.form,
          industry: payload?.SignupInfo?.[0]?.FK_MainIndustry,
          OtherIndustry: payload?.SignupInfo?.[0]?.OtherIndustry,
        },
      });
    },
    setClientSecret: (state: IOrderState, { payload }: PayloadAction<any>) => {
      state.clientSecret = payload.client_secret;
    },
    orderSubmit: (state: IOrderState, { payload }: PayloadAction<any>) => {
      state.form = {};
    },
    setIndex: (state: IOrderState, { payload }: PayloadAction<any>) => {
      state.wizardIndex = payload;
    },
    addForm: (state: IOrderState, { payload }: PayloadAction<any>) => {
      state.form = payload;
    },
    setError: (state: IOrderState, { payload }: PayloadAction<any>) => {
      state.error = payload;
    },
    setLoader: (state: IOrderState, { payload }: PayloadAction<any>) => {
      state.loader = payload;
    },
    contactValidate: (state: IOrderState, { payload }: PayloadAction<any>) => {
      state.isContactValidate = payload;
    },
    shippingValidate: (state: IOrderState, { payload }: PayloadAction<any>) => {
      state.isShippingValidate = payload;
    },
    paymentValidate: (state: IOrderState, { payload }: PayloadAction<any>) => {
      state.isPaymentValidate = payload;
    },
  },
});

export const submitOrder = (query: object) => async (dispatch: any) => {
  dispatch(setError({}));
  dispatch(setLoader(true));
  const json = await confirmTermsConditions(query);
  if (json.status == 204) {
    dispatch(orderSubmit(json.data));
  } else if (json.status == 400) {
    dispatch(setError(json.data));
  }
  dispatch(setLoader(false));
  return json;
};

export const submitAccountDetails = (query: object) => async (dispatch: any) => {
  dispatch(setError({}));
  dispatch(setLoader(true));
  const json = await updateAccountContactDetail(query);
  if (json.status == 200) {
  } else if (json.status == 400) {
    dispatch(setError(json.data));
  }
  dispatch(setLoader(false));
  return json;
};

export const submitAccountDetailsForLoginUser = (query: object) => async (dispatch: any) => {
  dispatch(setError({}));
  dispatch(setLoader(true));
  const json = await updateAccountContactDetailLoginUser(query);
  if (json.status == 200) {
  } else if (json.status == 400) {
    dispatch(setError(json.data));
  }
  dispatch(setLoader(false));
  return json;
};

export const uploadCompanyImage = (query: object) => async (dispatch: any) => {
  return await updateCompanyImageDetail(query);
};

export const submitAccountReview = (query: object) => async (dispatch: any) => {
  const json = await updateAccountReview();
  return json;
};

export const submitClaimCompany = (query: object) => async (dispatch: any) => {
  const json = await claimCompany(query);
  return json;
};

export const updateSignupInformation = (query: object) => async (dispatch: any) => {
  const json = await updateSignup(query);
  return json;
};

export const fetchAccountDetails = () => async (dispatch: any) => {
  const json = await getAccountContactDetails();
  if (json?.status == 200) {
    await dispatch(setAccountDetail(json.data));
  }else {
    await dispatch(setAccountDetail({user: getUser()}));
  }
  return json;
};

export const fetchAccountDetailsAfterLogin = (ParentCompanyID) => async (dispatch: any) => {
  const json = await getAccountContactDetailsForLoginUser(ParentCompanyID);
  if (json?.status == 200) {
    await dispatch(setAccountDetailLoginUser(json?.data?.ParentCompanyInfo[0]));
  }else {
    await dispatch(setAccountDetail({user: getUser()}));
  }
  return json;
};


export const setFormStateOnly = (payload) => async (dispatch: any) => {

  const pay = {
    'page': true,
    'OtherIndustry': payload?.OtherIndustry,
    'account_type': payload?.supplier,
    'annual_turnover': payload?.CompanyTurnover,
    'city': payload?.ApplicationCity,
    'company_name': payload?.ApplicationCompanyName,
    'company_abn': payload?.ApplicationCompanyABN,
    'company_address_1': payload?.ApplicationAddress1,
    'company_address_2': payload?.ApplicationAddress2,
    'company_phone_number': payload?.ApplicationPhoneCompany,
    'country_id': payload?.ApplicationCountryCodeN3,
    'industry': payload?.FK_MainIndustry,
    'number_of_employees': payload?.EmployeeCount,
    'postcode': payload?.ApplicationPostcode,
    'state': payload?.ApplicationState,
    'trading_name': payload?.ApplicationTradingName,
    'website': payload?.ApplicationWebsite,
    'signup_id': payload?.signupID,
    'country_of_ownership':payload?.country_of_ownership
  };

  await dispatch(setAccountDetail(pay));
};

export const setFormExtCmpyStateOnly = (payload) => async (dispatch: any) => {

    const pay = {
    'page': true,
    'company_name':payload?.CompanyName,
    'trading_name':payload?.TradingName,
    'company_abn':payload?.TaxNumber,
    'number_of_employees':payload?.FK_EmployeeCount,
    'annual_turnover':payload?.FK_CompanyTurnover,
    'industry':payload?.FK_MainIndustry,
    'OtherIndustry':payload?.IndustryOther,
    'company_phone_number':payload?.PhoneCompany,
    'website':payload?.Website,
    'company_address_1': payload?.AddressStreet1,
    'company_address_2': payload?.AddressStreet2,
    'city':payload?.AddressCity,
    'state':payload?.AddressState,
    'postcode':payload?.AddressPostCode,
    'country_id':payload?.AddressCountry,
    'ParentCompanyID':payload?.ParentCompanyID
  };

  await dispatch(setAccountDetail(pay));
};

export const fetchCountry = () => async (dispatch: any) => {
  const json = await listCountries();
  if (json.status == 200) {
    dispatch(setCountry(json.data));
  }
  return json;
};



export const fetchState = (query: object) => async (dispatch: any) => {
  const json = await listStates(query);
  if (json.status == 200) {
    dispatch(setState(json.data));
  }
  return json;
};

export const fetchStateForAccountDetails = (query: object) => async (dispatch: any) => {
  const json = await listStatesForAccountDetails(query);
  if (json.status == 200) {
    dispatch(setState(json.data?.StateInfo));
  }
  return json;
};

export const fetchNumberOfEmployees = () => async (dispatch: any) => {
  const json = await listselection({SystemCode:"SUPPLIER", SelectionCode:"EMPLOYEECOUNT"});
  if (json.status == 200) {
    dispatch(setNumberOfEmployee(json.data));
  }
  return json;
};

export const fetchAnnualTurnover = () => async (dispatch: any) => {
  const json = await listselection({SystemCode:"SUPPLIER", SelectionCode:"TURNOVER"});
  if (json.status == 200) {
    dispatch(setAnnualTurnover(json.data));
  }
  return json;
};

export const fetchIndustries = () => async (dispatch: any) => {
  const json = await listselection({SystemCode:"SUPPLIER", SelectionCode:"INDUSTRY"});
  if (json.status == 200) {
    dispatch(setIndustries(json.data));
  }
  return json;
};

export const fetchCompanyByGtin = (query: object) => async (dispatch: any) => {
  return await searchCompanyByGTIN(query);
};

export const removeCompanyByGtin = (query: object) => async (dispatch: any) => {
  return await removeCompanyByGTIN(query);
};

export const fetchClientSecret = (query: object) => async (dispatch: any) => {
  const json = await listClientSecret(query);
  if (json.status == 200) {
    dispatch(setClientSecret(json.data));
  }
  return json;
};

export const setContactValidate = (query: object) => async (dispatch: any) => {
  dispatch(contactValidate(query));
};

export const setShippingValidate = (query: object) => async (dispatch: any) => {
  dispatch(shippingValidate(query));
};

export const setPaymentValidate = (query: object) => async (dispatch: any) => {
  dispatch(paymentValidate(query));
};

export const SignupInfoInformation = (FK_UserID) => async (dispatch: any) => {
  const json = await Auth().signupInfo(FK_UserID);
  if (json.status == 200) {
    dispatch(setIndustry(json.data));
  }
  return json;
}

export const featchCreateNewCompany = (payload) => async (dispatch: any) => {
  return await createCompany(payload);
}



export const {
  setLoader,
  setError,
  addForm,
  setIndex,
  orderSubmit,
  setAccountDetailLoginUser,
  setCountry,
  setState,
  setNumberOfEmployee,
  setAnnualTurnover,
  setIndustries,
  setCompany,
  setAccountDetail,
  setClientSecret,
  contactValidate,
  shippingValidate,
  paymentValidate,
  setIndustry
} = getStartedSlice.actions;

export const orderSelector = (state: RootState) => state.getStarted;

export default getStartedSlice.reducer;
