import { getToken } from "../provider/AuthProvider";
import { getAuthRequest } from "./API";

const start = async () => {
  try {
    return await getAuthRequest(getToken()).get('import/product/start');
  } catch (error) {
    return false;
  }
};

const importProds = async (UpdateID, prods) => {
  try {
    return await getAuthRequest(getToken()).post('import/product/import', { UpdateID, prods });
  } catch (error) {
    return false;
  }
};

const finish = async (UpdateID) => {
  try {
    return await getAuthRequest(getToken()).post('import/product/finish', { UpdateID });
  } catch (error) {
    return false;
  }
};

export { start, importProds, finish };