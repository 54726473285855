import React from 'react';
import PageSkeletonComponent from '../../components/PageSkeletonComponent/PageSkeletonComponent';
import './EntityLoyalty.css';

const EntityLoyalty = () => {
  return (
    <PageSkeletonComponent>

      <h1>ENTITY - Loyalty Membership Page</h1>

      <hr />
      <h2>Purpose of Page</h2>
      The user can see the Loyalty Membership for their entity<br /><br />
      
      From here the use can:
      <li>Sign Up for the Loyalty Program</li>
      <li>Check their Loyalty Subscription</li> 
      <li>Update their Loyalty Subscription</li><br />

      <h2>Who Can Access</h2>
      <b>WHO IS EXPECTED TO ACCESS THIS PAGE</b>

    </PageSkeletonComponent>
  );
};

export default EntityLoyalty;