import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../context/AuthContext';
import DashboardContainer from '../../dashboard-components/DashboardContainer';
import './GetStartedAccountWaitingApproval.scss';

const GetStartedAccountWaitingApproval = () => {

  const userAuth = useAuthContext();
  const user = userAuth.user;

  const navigate = useNavigate();

  const here = (e) => {
    e.preventDefault();
    navigate('/DashboardHome');
  };

  return (
    <DashboardContainer title={"Your application is being processed"}>
      <div className="application-being-processed-container">
        <div className="message">
          <span className="hi-user">{user.NameFirst},</span>
          <span className="submitted-for-approval">Your application has been submitted for approval, allow two business days for approval.</span>
          <span className="go-to-home-page">
            Click <a href="" onClick={here}>here</a> to go to the home page.
          </span>
        </div>
      </div>
    </DashboardContainer>
  );
};

export default GetStartedAccountWaitingApproval;