import React from 'react';
import PageSkeletonComponent from '../components/PageSkeletonComponent/PageSkeletonComponent';
import { useNavigate } from 'react-router-dom'
import './LoyaltyHome.css';

const LoyaltyHome = () => {
  const navigate = useNavigate()
  return (
    <PageSkeletonComponent>

      <h1>LOYALTY - Home Page (Dashboard)</h1>

      <hr />

      <h2>Purpose of Page</h2>
      <b>This is the landing page for the Loyalty Program</b><br /><br />
      If the users Entity is registered with the Loyalty Program it will show a Dashboard with relevant information and/or statistics.<br /><br />
      If the users Entity is not registered with the Loyalty Program then they will be given an overview, benefits presentation of the Loyalty Program along with a Call to Action to join the program.<br /><br />
      Our solution for the Loyalty Program would look to use a Third-Party solution that we interface with, we would just have access to that solution from here.

      <h2>Who Can Access</h2>
      <b>WHO IS EXPECTED TO ACCESS THIS PAGE</b>

      <br/><br />
      If the users entity is a member of our loyalty program they will be shown their Dashboard.<br /><br />
      If the users entity is not a member of our loyalty program they will be shown a teaser of the Dashboard of what they are missing out on, together with a link to the Loyalty Subscription Page.<br /><br />
      <button onClick={() => {
        navigate('Entity/EntitySubscription')
      }}>a good button</button>
    </PageSkeletonComponent>
  );
};

export default LoyaltyHome;