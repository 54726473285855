import React, { useContext, useState } from 'react';
import GVDItem from './GVDItem';
import GVDItemDetail from './GVDItemDetail';
import { ContextSelectedBrick } from '../contexts/ContextSelectedBrick';
import { useAppSelector, useAppDispatch } from "../../../store/store";
import {
	gvdEditior,
  fetchVariantData,
  addNewVariantDetail,
  onRenameVariant,
  onDeleteVariant
} from "../../../store/slices/gvdEditiorSlice";

type GVDItemVariantProps = {
  group: any,
  variant: any
}

const GVDItemVariant = ({ group, variant }: any) => {
  const dispatch = useAppDispatch();
  const { details } = useAppSelector(gvdEditior);
  const { BRICK } = useContext(ContextSelectedBrick);

  const updatedVariant = { ...variant, Total: details.length };
  
  const onAddNew = (description: string) => {
    dispatch(addNewVariantDetail(description, variant.VariantID))
    dispatch(fetchVariantData(BRICK, group.GroupingID, variant.VariantID));
  };

  const onRename = (description: string) => {
    dispatch(onRenameVariant(description, variant.VariantID, group.GroupingID));
  };

  const onDelete = async () => {
    dispatch(onDeleteVariant(variant.VariantID,group.GroupingID))
  };

  const fetchSubItems = () => {
    dispatch(fetchVariantData(BRICK, group.GroupingID, variant.VariantID));
  };

  return (
    <GVDItem
      description={variant.VariantDesc}
      fetchSubItems={fetchSubItems}
      onAddNew={onAddNew}
      onRename={onRename}
      onDelete={updatedVariant > 0 ? false : onDelete}>

{(Array.isArray(details[variant.VariantID]) ? details[variant.VariantID] : []).map((detail, key) => (
  <GVDItemDetail key={key} group={group} variant={variant} detail={detail} />
))}
      
    </GVDItem>
  );
};

export default GVDItemVariant;