import { getToken } from "../provider/AuthProvider";
import { getAuthRequest } from "./API";

export const MenuGPC = {
  getSegments: async () => {
    try {
      return await getAuthRequest(getToken()).get('menu/gpc/segment');
    } catch (error) {
      return false;
    }
  },

  getFamilies: async (segmentId) => {
    try {
      return await getAuthRequest(getToken()).get('menu/gpc/family/' + segmentId);
    } catch (error) {
      return false;
    }
  },

  getClasses: async (familyId) => {
    try {
      return await getAuthRequest(getToken()).get('menu/gpc/class/' + familyId);
    } catch (error) {
      return false;
    }
  },

  getBricks: async (classId) => {
    try {
      return await getAuthRequest(getToken()).get('menu/gpc/brick/' + classId);
    } catch (error) {
      return false;
    }
  },

  getGroupings: async (brickId) => {
    try {
      return await getAuthRequest(getToken()).get('menu/gpc/grouping/' + brickId);
    } catch (error) {
      return false;
    }
  },

  getVariants: async (groupingId) => {
    try {
      return await getAuthRequest(getToken()).get('menu/gpc/variant/' + groupingId);
    } catch (error) {
      return false;
    }
  },

  getDetails: async (variantId) => {
    try {
      return await getAuthRequest(getToken()).get('menu/gpc/detail/' + variantId);
    } catch (error) {
      return false;
    }
  }
};