import React from "react";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";
import "./DashboardHome.scss";
import {
    fetchAccountDetails,
    fetchAnnualTurnover,
    fetchIndustries,
    fetchNumberOfEmployees,
    setIndex, SignupInfoInformation
} from "../../store/slices/getStartedSlice";
import { useAppDispatch } from "../../store/store";
import { getUserSignupInfo } from "../../provider/AuthProvider";
import DashboardContainer from "../../dashboard-components/DashboardContainer";

const DashboardHome = () => {
    const userAuth = new useAuthContext();
    const user = userAuth.user;
    const userparentCompanies = userAuth.parentCompanies;
    const userSignupInfo = userAuth.signupInfo;
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const getStarted = async (e) => {
        e.preventDefault();
        const userSignupInfo_2 = getUserSignupInfo();
        const FK_Progress_value = userSignupInfo_2?.FK_Progress ?? 1000140;
        if (userparentCompanies?.ParentCompanyID) {
            navigate("/DashboardHome");
        }
        else if (FK_Progress_value === 1000149 && userparentCompanies?.ParentCompanyID === undefined) {
            navigate("/UnderReview");
        } else {
            if (FK_Progress_value !== 1000140) {
                await Promise.all([
                    dispatch(fetchNumberOfEmployees()),
                    dispatch(fetchAnnualTurnover()),
                    dispatch(fetchIndustries()),
                    dispatch(fetchAccountDetails()),
                    // dispatch(SignupInfoInformation(user?.UserID)),
                ]);
            }

            const progressMapping = {
                1000140: { index: 0, path: "/GetStarted/AccountDetails" },
                1000145: { index: 1, path: "/GetStarted/AccountPrefix" },
                1000147: { index: 2, path: "/GetStarted/AccountReview" },
                1000148: { index: 3, path: "/GetStarted/AccountPayment" },
            };

            const progressInfo = progressMapping[FK_Progress_value];
            if (progressInfo && userSignupInfo_2) {
                dispatch(setIndex(progressInfo.index));
                navigate(progressInfo.path);
            } else {
                dispatch(setIndex(0));
                navigate("/GetStarted/AccountDetails");
            }
        }
    };

    if (userparentCompanies?.ParentCompanyID === undefined) {
        return (
            <DashboardContainer title={""}>
                <div className="h-[calc(100vh-200px)] flex justify-center items-center">
                    <div className="max-w-sm w-full m-auto bg-white text-black shadow-lg p-3">
                        <h4>Hi {user.NameFirst},</h4>
                        <p className="text-lg">
                            Welcome to Bring Back Australia
                            {user.isAdmin || user.isSuperUser ? ' for Admins' :
                                user.isEmployee ? ' for Employees' : ''}
                        </p>
                        <div className={'flex justify-end py-1.5'}>
                            <p className="text-md p-0 m-0 py-1">
                                To get started {" "}
                            </p>
                            <button onClick={getStarted} className={'rounded-full text-white bg-primary w-24 ml-2'}>
                                Click Here
                            </button>
                        </div>
                    </div>
                </div>
            </DashboardContainer>
        )
    }


    const getViewMessage = () => {
        if (user) {
            if (user.isSuperUser || user.isAdmin) {
                return '(Admin View)';
            } else if (user.isSupplier) {
                return '(Supplier View)';
            } else if (user.isRetailer) {
                return '(Retailer View)';
            } else if (user.isServiceProvider) {
                return '(ServiceProvider View)';
            }
        }
        return '';
    };

    return (
        <div className='flex justify-center items-center h-screen'>
            <h1 className='text-3xl font-bold'>{`Coming Soon ${getViewMessage()}`}</h1>
        </div>
    );
};

export default DashboardHome;
