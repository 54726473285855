import React, { useState, useEffect, Fragment } from 'react';
import { Tab } from "@headlessui/react";
import EditModal from './EditModal';
import OptionsModal from './OptionsModal';
import { useAppSelector, useAppDispatch } from "../../store/store";
import { setProcessing, setError, fetchBrandProductCount, brandValidationSubstitute, setBrands, fetchBrandActions, setModified, setSucceeded, setActive, setIsSelectedRow, setFilterValues, setLimit, setPage } from "../../store/slices/brandSlice.tsx";
import { Alert } from '@mui/material';
import DashboardHeader from '../../dashboard-components/DashboardHeader';
import { useAuthContext } from '../../context/AuthContext';
import ReactDataGrid from "@inovua/reactdatagrid-enterprise";
import "@inovua/reactdatagrid-enterprise/index.css";
import { HTTP_STATUS_CODES } from "../../api/API";

const ManageBrands = () => {
  const userAuth = new useAuthContext();
  const userparentCompanies = userAuth.parentCompanies;
  const dispatch = useAppDispatch();
  const { substituteFilterValues, filterValues, succeeded, error, brands, isSelectedRow, allBrands, active, perPage, limit, modified } = useAppSelector(store => store.brandSlice);
  const [isOpen, setIsOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(null);
  const [timeoutId, setTimeoutId] = useState(null);
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const [gridRef, setGridRef] = useState(null)
  const [loading, setLoading] = useState(false);
  const [confirmationDialog, setConfirmationDialog] = useState({
    isOpen: false,
    brandData: null,
  });

  const [unlinkConfirmationDialog, setUnlinkConfirmationDialog] = useState({
    isOpen: false,
    brandData: null,
  });

  useEffect(() => {
    if (isOpen || isOptionsOpen) {
      brands.then((result) => {
        const brandsData = result.data?.[0]?.CompanyID;
        if (brandsData) {
          dispatch(brandValidationSubstitute({ FK_CompanyID: brandsData }))
        }
      });
    }
  }, [isOpen, isOptionsOpen])

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [filterValues]);

  useEffect(() => {
    fetchData();
  }, [perPage, limit]);

  const fetchData = async () => {
    try {
      setLoading(true)
      const { data, status } = await dispatch(fetchBrandProductCount(
        userparentCompanies?.ParentCompanyID,
        filterValues,
        perPage,
        limit,
      ));

      let obj;

      if (status !== HTTP_STATUS_CODES.OK) {
        obj = Promise.resolve({ data: [], count: 0 });
      }

      obj = Promise.resolve({
        data: data.data,
        count: data.total,
      });

      dispatch(setBrands(obj));

      const brandsData = data?.data?.[0]?.CompanyID;
      if (brandsData) {
        dispatch(brandValidationSubstitute({ FK_CompanyID: brandsData }))
      }

    } catch (error) {
      console.error('Error fetching brand product count:', error);
      // Handle the error if needed
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    dispatch(setError(''));
    dispatch(setModified(false));
    dispatch(setProcessing(false));

    // Delayed dispatch after 5 seconds
    const timeoutId = setTimeout(() => {
      dispatch(setSucceeded(false));
    }, 3000);

    fetchData();

    return () => clearTimeout(timeoutId);
  }, [succeeded]);

  const handleFilterValueChange = (o) => {

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    // Set a new timeout
    const newTimeoutId = setTimeout(() => {
      // Dispatch your action after the timeout
      dispatch(setFilterValues(o));
    }, 2000); // Adjust the timeout value as needed (2 second in this example)

    // Save the new timeout ID in the state
    setTimeoutId(newTimeoutId);
  };


  const data = [
    {
      label: 'Verified',
      tables: [
        {
          tableName: 'Verified Brands',
          keyValue: 'Verified',
          header: ['Image', 'Brand', 'Sub Brand', 'No of Products', 'Brand Substitute Count', 'Actions'],
          body: brands !== null ? brands : Promise.resolve({ data: [], count: 0 })
        },
        {
          tableName: 'Substitute Brands',
          keyValue: 'substitutions',
          header: ['Image', 'Brand', 'Sub Brand', 'Actions'],
          body: isSelectedRow ? Promise.resolve({ data: allBrands.filter((o) => o.Substitute == isSelectedRow), count: 0 }) : Promise.resolve({ data: [], count: 0 })
        },
      ]
    },
    {
      label: 'Unverified',
      tables: [
        {
          tableName: 'Non-Verified Brands',
          keyValue: 'NON-Verified',
          header: ['Image', 'Brand', 'Sub Brand', 'No of Products', 'Actions'],
          body: brands !== null ? brands : Promise.resolve({ data: [], count: 0 })
        }
      ]
    }
  ];

  const showUnlinkConfirmation = (data) => {
    setUnlinkConfirmationDialog({
      isOpen: true,
      brandData: data,
    });
  };

  const hideUnlinkConfirmation = () => {
    setUnlinkConfirmationDialog({
      isOpen: false,
      brandData: null,
    });
  };


  const showUnverifyConfirmation = (data) => {
    setConfirmationDialog({
      isOpen: true,
      brandData: data,
    });
  };

  const hideUnverifyConfirmation = () => {
    setConfirmationDialog({
      isOpen: false,
      brandData: null,
    });
  };

  const confirmUnverify = async () => {
    if (confirmationDialog.brandData) {
      await unVerifyHandler(confirmationDialog.brandData);
      hideUnverifyConfirmation();
    }
  };


  const closeModal = () => {
    setIsOpen(false);
    setTimeout(() => {
      dispatch(setError(''));
      setIsEdit(null);
    }, 1000);
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const CloseOptionsModal = () => {
    setIsOptionsOpen(false);
    setTimeout(() => {
      dispatch(setError(''));
      setIsEdit(null);
    }, 1000);
  };

  const OpenOptionsModal = () => {
    setIsOptionsOpen(true);
  };

  const handleTableChange = (index) => {
    dispatch(setIsSelectedRow(null))
    dispatch(setActive(index));
  };

  const unVerifyHandler = async (data) => {
    const substitutes = allBrands.filter((o) => o.Substitute === data.BrandID);

    if (substitutes.length > 0) {
      substitutes.forEach((substituteData) => {
        confirmUnlink(substituteData);
      });
    }

    await dispatch(fetchBrandActions({
      BrandID: data.BrandID,
      ValidBrand: 0,
      Substitute: null
    }));
  };

  const unlinkHandler = async (data) => {
    showUnlinkConfirmation(data);
  }

  const confirmUnlink = async (data) => {
    await dispatch(fetchBrandActions({
      BrandID: data.BrandID,
      ValidBrand: 0,
      Substitute: null
    }));
    hideUnlinkConfirmation();
  };

  const defaultColumns = [
    {
      name: 'BrandImageURL',
      minWidth: 80,
      header: 'Image',
      filterable: false,
      align: 'center',
      defaultFlex: 1,
      render: ({ value }) => (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '95%' }}>
          <img
            src={value || '/temp.jpg'}
            alt="BrandImageURL"
            style={{
              width: '5em',
              height: '5.5em',
              objectFit: 'contain',
              margin: 0,
              padding: 0,
            }}
          />
        </div>
      ),
    },
    {
      name: 'BrandID',
      minWidth: 100,
      header: 'BrandID',
      filterable: false,
      defaultFlex: 1,
      align: 'center',
      type: 'number',
      render: ({ value }) => (
        <span className='font-bold text-center'>{value}</span>
      ),
    },
    {
      name: 'Brand',
      minWidth: 80,
      header: 'Brand',
      align: 'center',
      defaultFlex: 2,
      render: ({ value }) => (
        <span className='font-bold text-center'>{value}</span>
      ),
    },
    {
      name: 'BrandSub',
      minWidth: 80,
      header: 'Sub Brand',
      defaultFlex: 3,
      align: 'center',
      render: ({ value }) => (
        <span className='font-bold text-center text-gray-500'>
          {value ? value : <span>{"<<No Sub-Brand>>"}</span>}
        </span>
      ),
    },
    {
      name: 'ProductCount',
      minWidth: 80,
      header: 'No of Product',
      type: 'number',
      align: 'center',
      filterable: false,
      defaultFlex: 4,
      render: ({ value }) => (
        <span className={`font-bold text-center flex justify-center ${!value && 'text-gray-500'}`}>{value || '0'}</span>
      ),
    },
    {
      name: 'BrandSubstituteCount',
      minWidth: 80,
      header: 'BrandSubstituteCount',
      type: 'number',
      align: 'center',
      filterable: false,
      defaultFlex: 5,
      render: ({ value }) => (
        <span className={`font-bold text-center flex justify-center ${!value && 'text-gray-500'}`}>{value || '0'}</span>
      ),
    },
    {
      name: 'Action',
      minWidth: 80,
      header: 'Action',
      align: 'center',
      filterable: false,
      type: 'string',
      defaultFlex: 6,
      render: ({ row, data }) => (
        <>
          {data.RecordType === 'VER' && (
            <>
              <button
                className='bg-gray-400 hover:bg-gray-500 ripple text-white rounded-md px-2 py-1 focus-visible:outline-none focus-visible:ring-0 focus:outline-none focus:ring-0 mr-2'
                onClick={() => {
                  setIsEdit(data);
                  openModal();
                }}
              >
                Edit
              </button>
              <button
                className='bg-gray-400 hover:bg-gray-500 ripple text-white rounded-md px-2 py-1 focus-visible:outline-none focus-visible:ring-0 focus:outline-none focus:ring-0'
                onClick={() => showUnverifyConfirmation(data)}
              >
                Unverify
              </button>
            </>
          )}
          {data.RecordType === 'NON' && (
            <button
              className='bg-gray-400 hover:bg-gray-500 ripple text-white rounded-md px-2 py-1 focus-visible:outline-none focus-visible:ring-0 focus:outline-none focus:ring-0 mr-2'
              onClick={() => {
                setIsEdit(data);
                OpenOptionsModal();
              }}
            >
              Verify
            </button>
          )}
        </>
      ),
    }
  ];


  const substituteDefaultColumns = [
    {
      name: 'BrandImageURL',
      minWidth: 80,
      header: 'Image',
      align: 'center',
      defaultFlex: 1,
      render: ({ value, data }) => (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '95%' }}>
          <img
            src={value ? value : '/temp.jpg'}
            alt="BrandImageURL"
            style={{
              width: '5em',
              height: '5.5em',
              objectFit: 'contain',
              margin: 0,  // Remove margin
              padding: 0, // Remove padding
            }}
          />
        </div>
      ),
    },
    { name: 'BrandID', minWidth: 100, header: 'BrandID', defaultFlex: 1, type: 'number', align: 'center', },
    { name: 'Brand', minWidth: 80, header: 'Brand', defaultFlex: 2, align: 'center', },
    { name: 'BrandSub', minWidth: 80, header: 'Sub Brand', align: 'center', defaultFlex: 3 },
    {
      name: 'Action',
      minWidth: 80,
      header: 'Action',
      type: 'string',
      align: 'center',
      defaultFlex: 5,
      render: ({ row, data }) => (
        <button
          className='bg-gray-400 hover:bg-gray-500 ripple text-white rounded-md px-2 py-1 focus-visible:outline-none focus-visible:ring-0 focus:outline-none focus:ring-0 mr-2'
          onClick={() => unlinkHandler(data)}
        >
          Unlink
        </button>
      ),
    }
  ];

  return (
    <Tab.Group>
      <div className="w-full mx-auto">
        <DashboardHeader title={""} nav={[]} />
        <div className="space-y-4">
          <div>
            <div className="flex justify-between items-center mt-2 rounded bg-primary pt-3 px-2">
              <Tab.List>
                {data.map((panel, index) => (
                  <Tab key={index} as={Fragment}>
                    {({ selected }) => (
                      <button
                        className={`${selected ? "bg-white text-blac" : "text-white"
                          } !overflow-visible relative font-medium text-sm rounded-t px-10 py-2`}
                        onClick={() => handleTableChange(index)}
                      >
                        {panel.label}
                      </button>
                    )}
                  </Tab>
                ))}
              </Tab.List>
              <button
                className="bg-gray-400 hover:bg-gray-500 ripple text-white rounded-md px-2 py-1 focus-visible:outline-none focus-visible:ring-0 focus:outline-none focus:ring-0"
                onClick={() => {
                  openModal();
                }}
              >
                Create New Brand
              </button>
            </div>
            <Tab.Panels className={'rounded-b-xl shadow-md bg-white'}>
              {
                data.map((panel, index) => (
                  <Tab.Panel key={index}>
                    {panel.tables.map((table, index2) => (
                      <div key={index2}>
                        {table.tableName === 'Substitute Brands' ? (
                          <ReactDataGrid
                            idProperty={table.tableName}
                            style={{ marginTop: 5, minHeight: '26vh' }}
                            enableFiltering={false}
                            columns={substituteDefaultColumns}
                            defaultFilterValue={substituteFilterValues}
                            rowHeight={90}
                            dataSource={table.body}
                          />
                        ) : (
                          <ReactDataGrid
                            onReady={setGridRef}
                            idProperty={'BrandID'}
                            style={{
                              marginTop: 5,
                              minHeight: table.tableName === 'Non-Verified Brands' ? '89vh' : '62vh', zIndex: 10
                            }}
                            columns={defaultColumns}
                            enableSelection={table.tableName !== 'Non-Verified Brands'}
                            selection={isSelectedRow}
                            onSelectionChange={(onSelectionChange) =>
                              dispatch(setIsSelectedRow(onSelectionChange?.data?.BrandID))
                            }
                            onFilterValueChange={(o) => handleFilterValueChange(o)}
                            defaultFilterValue={filterValues}
                            rowHeight={90}
                            loading={loading}
                            pagination="remote"
                            dataSource={table.body}
                            onSkipChange={(o) => { dispatch(setPage(Math.round(Math.max(o - 1, 0) / limit) + 1)); }}
                            onLimitChange={(o) => { dispatch(setLimit(o)); }}
                            defaultLimit={10}
                          />
                        )}
                      </div>
                    ))}
                  </Tab.Panel>
                ))
              }
            </Tab.Panels>

            <div className='py-2'>
              {error && error.length > 0 && <Alert severity='error'>{error}</Alert>}
              {succeeded && <Alert severity="success">Changes Saved Successfully</Alert>}
            </div>
            {confirmationDialog.isOpen && (
              <div className="fixed inset-0 bg-gray-700 bg-opacity-75 flex items-center justify-center z-50">
                <div className="bg-white p-4 rounded-md">
                  <p>{`Do you want to Un-Verify ${confirmationDialog.brandData.Brand}-${confirmationDialog.brandData.BrandSub}?`}</p>
                  <div className="mt-4 flex justify-end">
                    <button className="bg-gray-400 text-white px-3 py-1 rounded-md mr-2" onClick={hideUnverifyConfirmation}>
                      No
                    </button>
                    <button className="bg-red-500 text-white px-3 py-1 rounded-md" onClick={confirmUnverify}>
                      Yes
                    </button>
                  </div>
                </div>
              </div>
            )}
            {unlinkConfirmationDialog.isOpen && (
              <div className="fixed inset-0 bg-gray-700 bg-opacity-75 flex items-center justify-center z-50">
                <div className="bg-white p-4 rounded-md">
                  <p>{`Do you want to Unlink ${unlinkConfirmationDialog.brandData.Brand}-${unlinkConfirmationDialog.brandData.BrandSub} ?`}</p>
                  <div className="mt-4 flex justify-end">
                    <button className="bg-gray-400 text-white px-3 py-1 rounded-md mr-2" onClick={hideUnlinkConfirmation}>
                      No
                    </button>
                    <button className="bg-red-500 text-white px-3 py-1 rounded-md" onClick={() => confirmUnlink(unlinkConfirmationDialog.brandData)}>
                      Yes
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <EditModal isOpen={isOpen} closeModal={closeModal} isEdit={isEdit} />
      <OptionsModal isOpen={isOptionsOpen} closeModal={CloseOptionsModal} isEdit={isEdit} />
    </Tab.Group>
  );
};

export default ManageBrands;
