import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../rootReducer";
import {
  listAccountsApproved,
  listAccountsToBeApproved,
  listAccountsWaitingMoreInfo,
  addCheck,
  approve,
  getSignUpDetail,
  listChecks,
  reject,
  getParentCompanyDetails,
  getParentCompanyDetailsByID,
  getParentCompaniesDetails,
  getStripePaymentDetails,
  getCalculateWorkdays,
  getCalculateDueDate,
  getHistoryData,
  getChecksDropdown,
  saveHistoryData,
  saveSubmitData,
  getHistoryDataByID,
  sendemail
} from "../../api/SignupApproval";
import {getProductCountryOrigin } from "../../api/SupplierProducts"
import {updateAccountContactDetailLoginUser,listselection,listStates, saveCompanyParentCompany, setLUPP} from "../../api/SignUp"
import { format } from "date-fns";

export interface SignupApprovalState {
  accsToApprove: any;
  accsApproved: any;
  accsMoreInfo: any;
  signupDetails: any;
  CountryOrigin:any;
  ParentCompanies:any;
  singleDetails:any;
  loader: boolean;
  loading: boolean;
  filterValues:any;
  RecordType:string;
  limit:any
  error: object;
  errorMsg: object;
  isAccsToApprove: boolean;
  isAccsApproved: boolean;
  isAccsMoreInfo: boolean;
  page: any;
  history:any;
  options:any;
  numberOfEmployees:Array<any>;
  annualTurnover:Array<any>;
  industries:Array<any>;
  state: Array<any>;
}

export const initialState: SignupApprovalState = {
  accsToApprove: {},
  accsApproved: {},
  accsMoreInfo: {},
  signupDetails: {},
  CountryOrigin: {},
  singleDetails: {},
  ParentCompanies:[],
  loader: false,
  loading: false,
  errorMsg: null,
  error: {},
  filterValues:{},
  RecordType:"ENTERING",
  limit:10,
  page:1,
  isAccsToApprove: false,
  isAccsApproved: false,
  isAccsMoreInfo: false,
  history:[],
  options:{},
  state: [],
  numberOfEmployees:[],
  annualTurnover:[],
  industries:[],
};

export const signupApprovalSlice = createSlice({
  name: "signupApproval",
  initialState,
  reducers: {
    setAccsToApprove: (state: SignupApprovalState, { payload }: PayloadAction<any>) => {
      state.accsToApprove = payload;
    },
    setAccsApproved: (state: SignupApprovalState, { payload }: PayloadAction<any>) => {
      state.accsApproved = payload;
    },
    setAccsMoreInfo: (state: SignupApprovalState, { payload }: PayloadAction<any>) => {
      state.accsMoreInfo = payload;
    },
    setSignupDetails: (state: SignupApprovalState, { payload }: PayloadAction<any>) => {
      state.signupDetails = payload;
    },
    isAccsToApprove: (state: SignupApprovalState, { payload }: PayloadAction<any>) => {
      state.isAccsToApprove = payload;
    },
    isAccsApproved: (state: SignupApprovalState, { payload }: PayloadAction<any>) => {
      state.isAccsApproved = payload;
    },
    isAccsMoreInfo: (state: SignupApprovalState, { payload }: PayloadAction<any>) => {
      state.isAccsMoreInfo = payload;
    },
    setError: (state: SignupApprovalState, { payload }: PayloadAction<any>) => {
      state.error = payload;
    },
    setErrorMsg: (state: SignupApprovalState, { payload }: PayloadAction<any>) => {
      state.errorMsg = payload;
    },
    setLoader: (state: SignupApprovalState, { payload }: PayloadAction<any>) => {
      state.loader = payload;
    },
    setLoading: (state: SignupApprovalState, { payload }: PayloadAction<any>) => {
      state.loading = payload;
    },
    setSingleDetails: (state: SignupApprovalState, { payload }: PayloadAction<any>) => {
      state.singleDetails = payload;
    },
     setCountryOrigin: (state: SignupApprovalState, { payload }: PayloadAction<any>) => {
      state.CountryOrigin = payload;
    },
    setParentCompanies: (state: SignupApprovalState, { payload }: PayloadAction<any>) => {
      state.ParentCompanies = payload;
    },
    setRecordType: (state: SignupApprovalState, { payload }: PayloadAction<any>) => {
      const modifiedPayload = payload?.toUpperCase().replace(/\s/g, '');
      state.RecordType = modifiedPayload;
    },
     setPage: (state: SignupApprovalState, { payload }: PayloadAction<any>) => {
      state.page = payload;
    },
    setLimit: (state: SignupApprovalState, { payload }: PayloadAction<any>) => {
      state.limit = payload;
    },
    setHistory: (state: SignupApprovalState, { payload }: PayloadAction<any>) => {
      // state.history = [...(payload || []),...state.history];
      state.history = payload;
  },
    setOptions: (state: SignupApprovalState, { payload }: PayloadAction<any>) => {
      state.options = payload;
    },
     setState: (state: SignupApprovalState, { payload }: PayloadAction<any>) => {
      state.state = payload;
    },
    setNumberOfEmployee: (state: SignupApprovalState, { payload }: PayloadAction<any>) => {
      state.numberOfEmployees = payload;
    },
    setAnnualTurnover: (state: SignupApprovalState, { payload }: PayloadAction<any>) => {
      state.annualTurnover = payload;
    },
    setIndustries:(state: SignupApprovalState, { payload }: PayloadAction<any>) => {
      state.industries = payload;
    },
  },
});

export const fetchAccsToApprove = () => async (dispatch: any) => {
  dispatch(isAccsToApprove(true));
  const json = await listAccountsToBeApproved();
    if (json.status == 200) {
      dispatch(setAccsToApprove(json.data));
    } else {
      dispatch(setError(json.data));
    }
    dispatch(isAccsToApprove(false));
  return json;
};

export const fetchAccsApproved = () => async (dispatch: any) => {
  dispatch(isAccsApproved(true));
  const json = await listAccountsApproved();
    if (json.status == 200) {
      dispatch(setAccsApproved(json.data));
    } else {
      dispatch(setError(json.data));
    }
    dispatch(isAccsApproved(false));
  return json;
};

export const fetchAccsMoreInfo = () => async (dispatch: any) => {
  dispatch(isAccsMoreInfo(true));
  const json = await listAccountsWaitingMoreInfo();
    if (json.status == 200) {
      dispatch(setAccsMoreInfo(json.data));
    } else {
      dispatch(setError(json.data));
    }
    dispatch(isAccsMoreInfo(false));
  return json;
};

export const fetchSignUpDetails = (SignupID: any) => async (dispatch: any) => {
  dispatch(setLoader(true));
  const json = await getSignUpDetail(SignupID);
    if (json.status == 200) {
      dispatch(setSignupDetails(json.data));
    } else {
      dispatch(setSignupDetails(json.message));
    }
    dispatch(setLoader(false));
  return json;
};

export const fetchSignupHistory = (SignupID: any) => async (dispatch: any, getState: () => RootState) => {
  dispatch(setLoading(true));
  const json = await listChecks(SignupID);
  if (json.status == 200) {
    const currentSignupDetails = getState().signupApproval.signupDetails;
    dispatch(setSignupDetails({ ...currentSignupDetails, signuphistory: json.data }));
  } else {
    dispatch(setError(json.data));
  }
  dispatch(setLoading(false));
  return json;
};

export const addAccount = (SignupID: any, SignupCheck: any, Title: any, Text: any) => async (dispatch: any, getState: () => RootState) => {
  dispatch(setLoading(true));
  const json = await addCheck(SignupID, SignupCheck, Title, Text);
  dispatch(setLoading(false));
  return json;
};


export const approveAccount = (SignupID: any) => async (dispatch: any, getState: () => RootState) => {
  dispatch(setLoading(true));
  const json = await approve(SignupID);
  dispatch(setLoading(false));
  return json;
};

export const rejectAccount = (SignupID: any) => async (dispatch: any, getState: () => RootState) => {
  dispatch(setLoading(true));
  const json = await reject(SignupID);
  dispatch(setLoading(false));
  return json;
};


export const fethchParentCompanyDetails = (filterValues) => async (dispatch: any, getState: () => RootState) => {
  dispatch(setLoading(true));

  try {
    const { data, status } = await getParentCompanyDetails(filterValues);
    if (status === 200) {
      const recordsWithWorkdays = await Promise.all(
        data.data?.map(async (record) => {
          if (record?.ApplicationLastUpdate) {
            const json = await getCalculateWorkdays({
              DateFrom: record.ApplicationLastUpdate,
              DateTo: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
            });
            
            const workdays = json?.data?.Workdays || "0";
            const workDuration = json?.data?.WorkDuration || "0";
            
            const  result = await dispatch(fetchCalculateDueDate({ 'DateFrom': record?.DateApplicationLodged, 'SLA_Type': 10 }));
            const dueDate = result?.data?.DueDate || "";

            return {
              ...record,
              calculateWorkdays: `${workdays} (${workDuration})`,
              customDueDate:`${dueDate}`,
            };
          }else  {
            return record;
          }
        })
      );

      const payload = {
        data: recordsWithWorkdays,
        count: data.total,
      };

      dispatch(setSignupDetails(payload));
    }
  } catch (error) {
    console.error('Error fetching parent company details:', error);
  }
};

export const fethchParentCompanyDetailsByID = (ParentCompanyID:any) => async (dispatch: any, getState: () => RootState) => {
  dispatch(setLoading(true));
  const {data, status} = await getParentCompanyDetailsByID(ParentCompanyID);
  if (status === 200) {
    if(data?.StripePaymentID && data?.StripePaymentID !== ""){
      const json = await getStripePaymentDetails(data?.StripePaymentID);
      if(json.status === 200){
        let payload = {
          'stripeTransactionDate':json?.data?.created,
          'stripeTransactionAmount':json?.data?.amount_received,
          'stripeCurrency':json?.data?.currency
        }
          const mergedData = {
        ...data,  // Copy existing properties
        ...payload // Copy properties from payload
      };
       dispatch(setSingleDetails(mergedData));
      }
    }else{
        dispatch(setSingleDetails(data));
    }
     
    }
  dispatch(setLoading(false));
};

export const fetchCountryOrigin = () => async (dispatch: any) => {
  try {
    const response = await getProductCountryOrigin();
    if (response?.status === 200) {
      dispatch(setCountryOrigin(response?.data?.CountryInfo));
    } else {
      console.error('Failed to fetch product details:', response?.status);
    }
  } catch (error) {
    console.error('Error fetching product details:', error);
  }
};

export const fetchParentCompanies = (payload) => async (dispatch: any) => {
  try {
     dispatch(setLoading(true));
    const {status,data} = await getParentCompaniesDetails(payload);
    if (status === 200) {
      dispatch(setParentCompanies(data?.ParentCompanyInfo));
    } else {
      dispatch(setErrorMsg(data?.message))
      console.error('Failed to fetch product details:', status);
    }
  } catch (error) {
    console.error('Error fetching product details:', error);
  } finally {
      dispatch(setLoading(false));
  }
};

export const fetchCalculateDueDate = (payload) => async (dispatch: any) => {
    return await getCalculateDueDate(payload);
};

export const fetchHistoryData = (payload) => async (dispatch: any) => {
    const {data,status} = await getHistoryData(payload);
    if (status === 200) {
      dispatch(setHistory(data?.SignupHistoryInfo));
    }else{
      dispatch(setHistory([]));
    }
};

export const fetchHistoryDataByID = (SignupID) => async (dispatch: any) => {
    const {data,status} = await getHistoryDataByID(SignupID);
    console.log('data', data)
    if (status === 200) {
      dispatch(setHistory(data));
    }else{
      dispatch(setHistory([]));
    }
};

export const fetchChecksDropdown = (paylaod) => async (dispatch: any) => {
    const {data,status} = await getChecksDropdown(paylaod);
    if (status === 200) {
      dispatch(setOptions(data?.SystemSelectInfo));
    }
};


export const fetchsaveHistoryData = (paylaod) => async (dispatch: any) => {
    const {data,status} = await saveHistoryData(paylaod);
    if (status === 200) {
      return data;
    }else{
      return [];
    }
};



export const sendEmailToUser = (paylaod) => async (dispatch: any) => {
    const {data,status} = await sendemail(paylaod);
    if (status === 200) {
      return data;
    }else{
      return [];
    }
};



export const fetchSaveSubmitData = (paylaod) => async (dispatch: any) => {
    const {data,status} = await saveSubmitData(paylaod);
      return data;
};

export const updateCompanyDetail = (paylaod) => async (dispatch: any) => {
    const {data,status} = await updateAccountContactDetailLoginUser({
      'CompanyName':paylaod?.company_name,
      'TradingName':paylaod?.trading_name,
      'TaxNumber':paylaod?.company_abn,
      'FK_EmployeeCount':paylaod?.number_of_employees,
      'FK_CompanyTurnover':paylaod?.annual_turnover,
      'FK_MainIndustry':paylaod?.industry,
      'IndustryOther':paylaod?.OtherIndustry,
      'PhoneCompany':paylaod?.company_phone_number,
      'Website':paylaod?.website,
      'AddressStreet1':paylaod?.company_address_1,
      'AddressStreet2':paylaod?.company_address_2,
      'AddressCity':paylaod?.city,
      'AddressState':paylaod?.state,
      'AddressPostCode':paylaod?.postcode,
      'AddressCountry':paylaod?.country_id,
      'ParentCompanyID':paylaod?.ParentCompanyID
    });
       if (status === 200) {
      return data;
    }else{
      return [];
    }
};

export const fetchNumberOfEmployees = () => async (dispatch: any) => {
  const json = await listselection({SystemCode:"SUPPLIER", SelectionCode:"EMPLOYEECOUNT"});
  if (json.status == 200) {
    dispatch(setNumberOfEmployee(json.data));
  }
  return json;
};

export const fetchAnnualTurnover = () => async (dispatch: any) => {
  const json = await listselection({SystemCode:"SUPPLIER", SelectionCode:"TURNOVER"});
  if (json.status == 200) {
    dispatch(setAnnualTurnover(json.data));
  }
  return json;
};

export const fetchIndustries = () => async (dispatch: any) => {
  const json = await listselection({SystemCode:"SUPPLIER", SelectionCode:"INDUSTRY"});
  if (json.status == 200) {
    dispatch(setIndustries(json.data));
  }
  return json;
};

export const fetchState = (query: object) => async (dispatch: any) => {
  const json = await listStates(query);
  if (json.status == 200) {
    dispatch(setState(json.data));
  }
  return json;
};


export const updateCompany = (query: object) => async (dispatch: any) => {
  const json = await updateAccountContactDetailLoginUser(query);
  if (json.status == 200) {
    dispatch(setState(json.data));
  }
  return json;
};

export const saveCompanyWithParentCompany = (query: object) => async (dispatch: any) => {
  const json = await saveCompanyParentCompany(query);
  if (json.status == 200) {
    dispatch(setState(json.data));
  }
  return json;
};


export const LUPP = (query: object) => async (dispatch: any) => {
  const json = await setLUPP(query);

  return json;
};






export const {
  setLoader,
  setError,
  setAccsToApprove,
  setAccsApproved,
  setAccsMoreInfo,
  isAccsToApprove,
  isAccsApproved,
  isAccsMoreInfo,
  setSignupDetails,
  setLoading,
  setErrorMsg,
  setSingleDetails,
  setCountryOrigin,
  setParentCompanies,
  setRecordType,
  setLimit,
  setPage,
  setHistory,
  setOptions,
  setNumberOfEmployee,
  setAnnualTurnover,
  setIndustries,
  setState
} = signupApprovalSlice.actions;

export const signupApproval = (state: RootState) => state.signupApproval;

export default signupApprovalSlice.reducer;
