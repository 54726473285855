import React from 'react';
import PageSkeletonComponent from '../../private-pages/components/PageSkeletonComponent/PageSkeletonComponent';
import "./NotFoundPage.css";

export default function NotFoundPage() {

  return (
    <PageSkeletonComponent>
      <div className="NotFound">
        <h3>Sorry, page not found</h3>
      </div>
    </PageSkeletonComponent>
  );

}