import React, { useEffect, useState } from 'react';
import { useFormFieldOnChange } from '../../utils/FormHooks';
import { useNavigate } from 'react-router-dom';
import DashboardContainer from '../../dashboard-components/DashboardContainer';

import './GetStartedAccountTypeSupplier.scss';
import { assignToTradingGroup, createTradingGroup, listTradingGroup } from '../../api/SignUp';
import { HTTP_STATUS_CODES } from '../../api/API';
import { useLocation } from 'react-router-dom';

const GetStartedAccountTypeRetailer = () => {

  const location = useLocation();
  const navigate = useNavigate();

  const [selectedGroupList, setSelectedGroupList, onChangeGroupList] = useFormFieldOnChange('');
  const [selectedGroupText, setSelectedGroupText, onChangeGroupText] = useFormFieldOnChange('');

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionError, setSubmissionError] = useState(false);
  const [secsToNextStep, setSecsToNextStep] = useState(null);

  const [reqProcessed, setReqProcessed] = useState(false);

  const [groupNotInformed, setGroupNotInformed] = useState(false);

  const [groups, setGroups] = useState(null);

  useEffect(() => {
    fetchGroups();
  }, []);

  const fetchGroups = async () => {
    const res = await listTradingGroup();

    setGroups([]);
    if (res.status != HTTP_STATUS_CODES.OK) {
      return;
    }
    setGroups(res.data);
  };

  const submitForm = async (e) => {
    e.preventDefault();
    setGroupNotInformed(false);
    setSubmissionError(false);

    if (!selectedGroupList.trim().length && !selectedGroupText.trim().length) {
      setGroupNotInformed(true);
      return false;
    }

    setIsSubmitting(true);

    let groupID = selectedGroupList;
    if (selectedGroupText.trim().length > 0) {
      const res = await createTradingGroup(selectedGroupText, location.state?.industry);

      if (res.status != HTTP_STATUS_CODES.OK) {
        setIsSubmitting(false);
        setSubmissionError(true);
        return false;
      }

      groupID = res.data.GroupID;
    }

    const res = await assignToTradingGroup(groupID);
    if (res.status != HTTP_STATUS_CODES.NO_CONTENT) {
      setIsSubmitting(false);
      setSubmissionError(true);
      return false;
    }
    setReqProcessed(true);
    setIsSubmitting(false);
    setSecsToNextStep(3);
  };

  const resetForm = (e) => {
    e.preventDefault();
    setSelectedGroupList('')
    setSelectedGroupText('');
  };

  useEffect(() => {
    if (secsToNextStep == null) return;
    if (secsToNextStep <= 0) {
      navigate('/GetStarted/IEI');
      return;
    }
    setTimeout(() => {
      setSecsToNextStep(secsToNextStep - 1);
    }, 1000);
  }, [secsToNextStep]);

  return (
    <DashboardContainer title={"Trading Group Selection"}>
      <div className="dashboard-get-started">
        <form className="form get-started-account-type retailer" style={{ width: 'auto' }}>
          <div>
            <div>
              <label>Select a trading group or inform a different one</label>
            </div>
            <div style={{ marginTop: '10px' }}>
              <label style={{ marginRight: '10px' }}>Trading Group</label>
              {groups == null && (
                <span style={{ fontStyle: 'italic', fontSize: '15px', fontWeight: 'bold' }}>Loading...</span>
              )}
              {groups != null && (
                <select value={selectedGroupList} onChange={onChangeGroupList}>
                  <option value="">-- SELECT --</option>
                  {groups.map((group, index) => (
                    <option key={index} value={group.GroupID}>{group.GroupName ?? 'NULL'}</option>
                  ))}
                </select>
              )}
            </div>
            <div style={{ marginTop: '10px', marginBottom: '10px' }}>
              <input value={selectedGroupText} onChange={onChangeGroupText} type="text" placeholder="Enter a new trading group name" />
            </div>
          </div>
          {isSubmitting && !submissionError && (
            <div style={{ fontWeight: 'bold', fontStyle: 'italic', textAlign: 'center' }}>
              We are processing your request ...
            </div>
          )}
          {submissionError && (
            <div style={{ fontWeight: 'bold', fontStyle: 'italic', textAlign: 'center', color: 'red' }}>
              Something went wrong, please contact our support!
            </div>
          )}
          {groupNotInformed && (
            <div style={{ fontWeight: 'bold', fontStyle: 'italic', textAlign: 'center', color: 'red' }}>
              Please, inform a valid Tranding Group
            </div>
          )}
          {reqProcessed && <tr>
            <div style={{ fontWeight: 'bold', fontStyle: 'italic', textAlign: 'center' }}>
              All set up! You will be taken to the next step in {secsToNextStep} second(s)
            </div>
          </tr>}
          {!isSubmitting && !reqProcessed && (
            <div style={{ marginTop: '10px' }} className="action-footer">
              <button onClick={submitForm}>Next</button>
              <button onClick={resetForm}>Reset</button>
            </div>
          )}
        </form>
      </div>
    </DashboardContainer >
  );
};

export default GetStartedAccountTypeRetailer;
