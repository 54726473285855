import React, { useContext } from 'react';
import { DataTableSelectedRowsContext } from './DataTableSelectedRowsContext';
import './DataTable.css';

const DataTable = ({
  onClickRow, columns, columnsInput, actions, rowIdentifier, rowData, actionFooter,
  multipleSelection, showCheckboxSelection
}) => {

  const { selectedRows, setSelectedRows } = useContext(DataTableSelectedRowsContext);

  let tableClass = "DataTable";
  tableClass += onClickRow != undefined ? " ClickableRow" : '';

  const onRowClick = (e, item) => {
    if (onClickRow == undefined) return;
    onClickRow(e, item);

    if (multipleSelection) {
      const currentItem = selectedRows.indexOf(item);
      if (currentItem != -1) {
        selectedRows.splice(currentItem, 1);
      } else {
        selectedRows.push(item);
      }
      setSelectedRows([...selectedRows]);
    }
  };

  const isItemSelected = (item) => selectedRows.indexOf(item) != -1;

  const getTotalSelectedRecords = () => {
    if (selectedRows.length == 0 || selectedRows.length > 1) {
      return selectedRows.length + ' records selected';
    }
    return '1 record selected';
  };

  return (
    <>
      <div className="DataTableContainer">
        <table className={tableClass}>
          <thead>
            <tr>
              {rowData.length > 0 && showCheckboxSelection && (<th style={{width: '1px'}}></th>)}
              {columns.map((column, key) => (
                <th key={key}>{column.title}</th>
              ))}
              {columnsInput && columnsInput.map((column, key) => (
                <th key={key}>{column.title}</th>
              ))}
              {actions && <th colSpan={actions.length}>Actions</th>}
            </tr>
          </thead>

          <tbody>
            {rowData.length == 0 ?
              (<tr><td colSpan={actions && actions.length + 4}><b>No records</b></td></tr>) :
              (rowData.map(item => (
                <tr key={item[rowIdentifier]}
                  className={(isItemSelected(item) ? 'Selected' : '')}
                  onClick={e => onRowClick(e, item)}
                  key={item[rowIdentifier]}>
                  {showCheckboxSelection && (
                    <td><input type="checkbox" readOnly checked={isItemSelected(item)} /></td>
                  )}
                  {columns.map((column, key) => (
                    <td key={key}>{column.beforePrint(item[column.rowProperty])}</td>
                  ))}
                  {columnsInput && columnsInput.map((column, key) => (
                    <td key={key}>{column.inputComponent}</td>
                  ))}
                  {actions && actions.map((action, key) => (
                    <td key={key}><button onClick={(e) => action.callAction(e, item)}>{action.getDescription()}</button></td>
                  ))}
                </tr>
              )))
            }
          </tbody>
        </table>
      </div>
      {actionFooter && <div className="ActionFooter">
        {multipleSelection && (
          <b><span style={{ marginRight: '20px' }}>{getTotalSelectedRecords()}</span></b>
        )}
        {actionFooter}
      </div>}
    </>
  );
};

const ActionRow = ({ description, actionFunction }) => {

  const getDescription = () => {
    return description;
  };

  const callAction = (e, itemRow) => {
    actionFunction(e, itemRow);
  };

  return {
    getDescription,
    callAction
  }
};

const ColumnInput = ({ title, inputComponent }) => {
  return { title, inputComponent };
};

const Column = ({ title, rowProperty, beforePrint }) => {
  const _beforePrint = beforePrint != undefined ? beforePrint : (value) => value;
  return { title, rowProperty, beforePrint: _beforePrint };
};

export default DataTable;
export { ActionRow, Column, ColumnInput };