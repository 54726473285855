import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormFieldOnChange } from "../../utils/FormHooks";
import { useAppSelector, useAppDispatch } from "../../store/store";
import {
	auth,
	setSucceeded,
	setError,
	setProcessing,
	setPwDontMatch,
	newAccount, resetState,
} from "../../store/slices/authSlice";

import "./SignUpPage.css";
import AuthPrompt from "../../components/AuthPrompt";

const SignUpPage = () => {
	const dispatch = useAppDispatch();
	const { succeeded, processing, error, pwDontMatch } = useAppSelector(auth);
	const navigate = useNavigate();

	const [nameInput, setNameInput, onNameChange] = useFormFieldOnChange("");
	const [emailInput, setEmailInput, onEmailChange] = useFormFieldOnChange("");
	const [passwordInput, setPasswordInput, onPasswordChange] = useFormFieldOnChange("");
	const [confirmPwInput, setConfirmPwInput, onConfirmPwChange] = useFormFieldOnChange("");

	const hideStatusMessages = () => {
		dispatch(setProcessing(false));
		// dispatch(setError(""));
		dispatch(setPwDontMatch(false));
	};

	const resetForm = () => {
		setNameInput("");
		setEmailInput("");
		setPasswordInput("");
		setConfirmPwInput("");
		hideStatusMessages();
	};

	const onInputChange = (e, inputHandler) => {
		inputHandler(e);
		dispatch(setError(""));
		dispatch(setSucceeded(false));
		hideStatusMessages();
	};
	useEffect(() => {
		if (passwordInput.trim() === "" || confirmPwInput.trim() === "") {
			dispatch(setPwDontMatch(false));
			return;
		}
		dispatch(setPwDontMatch(passwordInput !== confirmPwInput));
	}, [passwordInput, confirmPwInput, error, succeeded]);

	const submitForm = async (e) => {
		dispatch(setError(""));
		e.preventDefault();
		if (processing) {
			return;
		}

		if (nameInput.trim() === "" || emailInput.trim() === "") {
			const nameErrorMessage = nameInput.trim() === "" ? "Please Enter Your First Name" : "";
			const emailErrorMessage = emailInput.trim() === "" ? "Please Enter Your Email" : "";
			const errorMessage = nameErrorMessage || emailErrorMessage;
			dispatch(setError(errorMessage));
			return;
		}

		if (passwordInput !== confirmPwInput) {
			dispatch(setPwDontMatch(true));
			return;
		}

		await dispatch(newAccount(nameInput, emailInput, passwordInput));
		// dispatch(setSucceeded(true));
		// if (succeeded) {
		// 	resetForm();
		// }
	};

	const continueAfterRegistration = (e) => {
		e.preventDefault();
		navigate("/");
	};

	const accountCreated = () => (
		<>
			<p style={{ fontWeight: "normal" }} className="text-black text-center">
				Check your email and follow the instructions to verify your account.
			</p>
			<div className="text-center"> {/* Center the content */}
				<button
					className={"bg-primary rounded-full text-white py-1.5 w-1/4 mt-2"}
					onClick={(e) => {
						dispatch(resetState());
						continueAfterRegistration(e);
					}}
				>
					Continue
				</button>
			</div>
		</>

	);

	const formContent = () => (
		<>
			<div className="flex flex-col mb-3">
				<label>First Name</label>
				<input
					type="text"
					placeholder="Enter your name..."
					value={nameInput}
					className="rounded-full border py-1.5 px-2"
					onChange={(e) => onInputChange(e, onNameChange)}
				/>
			</div>
			<div className="flex flex-col mb-3">
				<label>Email</label>
				<input
					type="text"
					placeholder="Enter your email..."
					value={emailInput}
					className="rounded-full border py-1.5 px-2"
					onChange={(e) => onInputChange(e, onEmailChange)}
				/>
			</div>
			<div className="flex flex-col mb-3">
				<label>Password</label>
				<input
					type="password"
					placeholder="Enter your password..."
					value={passwordInput}
					className="rounded-full border py-1.5 px-2"
					onChange={(e) => onInputChange(e, onPasswordChange)}
				/>
			</div>
			<div className="flex flex-col mb-3">
				<label>Confirm your password</label>
				<input
					type="password"
					placeholder="Confirm your password..."
					className="rounded-full border py-1.5 px-2"
					value={confirmPwInput}
					onChange={(e) => onInputChange(e, onConfirmPwChange)}
				/>
			</div>
			{error && (
				<div className="text-red-500 font-bold text-center md:text-left">
					{error}
				</div>
			)}
			{pwDontMatch && (
				<div className="text-red-500 font-bold text-center md:text-left">
					You've entered different passwords!
				</div>
			)}
			{succeeded && (
				<div className="text-red-500 font-bold text-center md:text-left">
					Congratulations, your account has been created.
					<br />
					Please, read the instructions we've sent to your email on how to activate your account.
				</div>
			)}
			<div className="flex align-center gap-2">
				<button
					disabled={processing}
					className={"bg-gray-500 rounded-full text-white py-1.5 w-full"}
					onClick={(e) => {
						e.preventDefault();
						resetForm();
					}}
				>
					Reset
				</button>
				<button
					className={`${processing ? "bg-gray-400" : "bg-primary"} rounded-full text-white py-1.5 w-full`}
					disabled={processing}
					onClick={(e) => {
						dispatch(setProcessing(true));
						submitForm(e).then(r => {
							hideStatusMessages();
						});
					}}
				>
					Confirm
				</button>

			</div>
			<div className="text-right mt-3">
				<Link to="/SignIn">Have an Account? Login here</Link>
			</div>
		</>
	);

	return (
		<div className="w-full flex justify-center h-full items-center overflow-hidden">
			<div className="lg:max-w-lg md:max-w-lg w-full mx-auto bg-white px-4 pb-3 rounded-sm shadow-xl">
				<form>
					<div className="flex flex-col justify-center items-center">
						<img className="w-[300px]" src="BBA-Logo-TBl-S.png" alt="BBA-Logo-TBl-S" />
						<p className="font-bold text-center mb-3 text-lg">
							{!succeeded ? "Create your account" : "Account created"}
						</p>
					</div>
					{succeeded ? accountCreated() : formContent()}
				</form>
			</div>
			<AuthPrompt open={error == 'The email has already been taken.' ? true : false} requestType={'SignUp'} payload={{ firstname: nameInput, email: emailInput, id: 0 }} />
		</div>
	);
};

export default SignUpPage;
