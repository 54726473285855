import React from 'react';

const ProductItem = ({ product }) => {
  return (
    <div className="product-item">
      <span className="column" style={{ width: '52px' }}></span>
      <span className="column auto">
        Brand: {product.Brand} - {product.BrandSub}<br />
        {product.GTIN} - {product.TradeItemDesc}
      </span>
      <span className="column"></span>
      <span className="column"></span>
      <span className="column"></span>
      <span className="column"></span>
    </div>
  );
};

export default ProductItem;