import React, { Fragment, useContext, useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconDefinition } from '@fortawesome/fontawesome-common-types'
import { useForm } from 'react-hook-form';
import { faAngleRight, faAngleDown, faPlusCircle, faPen, faTrash, faSave, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { DashboardPopUpContext } from '../../../dashboard-components/context/DashboardPopUpContext';
import GVDItemDeleteModal from './GVDItemDeleteModal';

type GVDItemProps = {
  description: any,
  children?: React.ReactNode,
  onAddNew: any,
  onRename: any,
  onDelete: any,
  fetchSubItems?: () => any,
}

const GVDItem = ({ description, children, fetchSubItems, onAddNew, onRename, onDelete }: any) => {

  const [hasRequestedData, setHasRequestedData] = useState(false);
  const [showItemSub, setShowItemSub] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }

  const toggleSubItem = () => {
    const show = !showItemSub;
    setShowItemSub(show);

    if (!children) return;

    if (!hasRequestedData) {
      fetchSubItems();
      setHasRequestedData(true);
    }
  };

  const statusIcon = () => !children ? null : <FontAwesomeIcon className='pt-0.5 pr-2 group-hover:text-white' onClick={toggleSubItem} icon={showItemSub ? faAngleDown : faAngleRight} />;

  
  const onClickDelete = async () => {
    onDelete && await onDelete();
  };

  const EditableRow = ({ }) => {
    const { register, handleSubmit, watch, setValue, formState: { errors } } = useForm();
    const { showPopUp } = useContext(DashboardPopUpContext);

    const [processing, setProcessing] = useState(false);
    const [action, setAction] = useState('');

    const cancelOp = () => {
      setAction('')
      setValue('name', description);
    };

    const renameItem = () => setAction('renameItem');

    const addSubItem = () => {
      setAction('addNew');
      setValue('name', '');
    };

    const saveItem = () => {
      setProcessing(true);
      if (action == 'renameItem') {
         onRename(watch('name'));
      }
      else if (action == 'addNew') {
       onAddNew(watch('name'));
      }
      setProcessing(false);
      setAction('');
    };

    const actionIcon = (faIcon: IconDefinition, description: string, click: () => void) => {
      return <FontAwesomeIcon title={description} icon={faIcon} onClick={click} />;
    };

    useEffect(() => {
      setValue('name', description);
    }, []);

    return (
      <div className="p-2 pl-3 pr-3 flex flex-row bg-white border-b mb-2 item-center group request-processing-indicator hover:!bg-[#52947f]" data-has-children={!!children}>
        {statusIcon()}
        {!action.trim().length && (
          <>
            <span className="description cursor-pointer group-hover:text-white">{watch('name') ?? 'Not Defined'}</span>
            <div className="flex-grow text-right hidden cursor-pointer group-hover:text-white group-hover:flex justify-end items-center gap-2">
              {onAddNew && actionIcon(faPlusCircle, 'Add New', addSubItem)}
              {onRename && actionIcon(faPen, 'Rename', renameItem)}
              {onDelete && actionIcon(faTrash, 'Delete', openModal)}
            </div>
          </>
        )}
        {action.trim().length > 0 && (
          <>
            <form onSubmit={handleSubmit(saveItem)}>
              <input
                autoFocus
                {...register('name', { required: true })}
                placeholder={action == 'addNew' ? 'Type a description for the new record' : 'Enter a new description for the record'}
                className="rounded py-2 px-1 w-[250px]" type="text" defaultValue={description} />
            </form>
            <div className="flex-grow text-right hidden cursor-pointer group-hover:flex justify-end items-center gap-2">
              {actionIcon(faTimesCircle, 'Cancel', cancelOp)}
              {actionIcon(faSave, action == 'addNew' ? 'Create' : 'Save', saveItem)}
            </div>
          </>
        )}
        {processing && (
          <div className="request-processing">
            <span>Processing ...</span>
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <div className="gvd-tree-item-container">
        <EditableRow />
        {showItemSub && children && (
          <div className="ml-5">
            {children}
          </div>
        )}
      </div>

      <GVDItemDeleteModal isOpen={isOpen} closeModal={closeModal} onClickYes={onClickDelete} />
    </>
  );
};

export default GVDItem;