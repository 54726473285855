import * as yup from "yup";

const schema = yup.object().shape({
    first_name: yup
        .string()
        .required("First Name is required")
        .min(2, "First name should be at least 2 characters")
        .max(50, "First name should be at most 50 characters"),

    last_name: yup
        .string()
        .required("Last Name is required")
        .min(2, "Last name should be at least 2 characters")
        .max(50, "Last name should be at most 50 characters"),

    prefered_name: yup
        .string()
        .nullable()
        .max(50, "Prefered Name should be at most 50 characters"),

    position: yup
        .string()
        .nullable()
        .max(50, "position should be at most 50 characters"),

    annual_turnover: yup.string().required("Annual turnover is required"),

    number_of_employees: yup
        .string()
        .required("Number of employees is required")
        .min(3, "Number of employees is required")
    ,

    company_name: yup
        .string()
        .required("Company Name is required")
        .min(2, "Company name should be at least 2 characters")
        .max(50, "Company name should be at most 50 characters"),

    trading_name: yup
        .string()
        .required("Trading Name is required")
        .min(2, "Trading name should be at least 2 characters")
        .max(50, "Trading name should be at most 50 characters"),

    company_abn: yup
        .string()
        .test('is-valid-abn', 'Invalid ABN format must be 11 digits', function (value) {
            // Remove spaces
            const cleanedValue = (value || "").replace(/\s+/g, '');

            // Check if the value has exactly 11 digits
            if (!/^\d{11}$/.test(cleanedValue)) {
                return false;
            }

            return true;
        }),

    website: yup
        .string()
        .nullable(),

    industry: yup
        .string()
        .required("Industry is required, select OTHER if not in list"),

    OtherIndustry: yup.string().nullable().when('industry', {
        is: '1000006',
        then: () => yup.string().required('Other is required').max(50, "other should be at most 50 characters"),
    }),

    company_address_1: yup
        .string()
        .required("Address is required")
        .min(2, "Address should be at least 2 characters")
        .max(100, "Address should be at most 50 characters"),

    company_address_2: yup
        .string()
        .nullable()
        .max(100, "Address 2 should be at most 50 characters"),

    postcode: yup
        .string()
        .required("Postcode is required")
        .min(4, "Postcode should be at least 4 characters")
        .max(7, "Postcode should be at most 7 characters"),

    city: yup
        .string()
        .required("City is required")
        .min(2, "City should be at least 2 characters")
        .max(50, "City should be at most 50 characters"),

    country_id: yup.string().required("Country is required"),

    // state: yup.string().required("State is required"),

    mobile_number: yup.string().nullable().test('is-valid-number', 'Phone number must start with 0 or 1 and have 10 digits', function(value) {
        if (!value) return true;  // Allow empty or null

        return /^[01]\d{9}$/.test(value);
    }),

    direct_landline_number: yup.string().nullable().test('is-valid-number', 'Phone number must start with 0 or 1 and have 10 digits', function(value) {
        if (!value) return true;  // Allow empty or null

        return /^[01]\d{9}$/.test(value);
    }),

    company_phone_number: yup.string().nullable().test('is-valid-number', 'Phone number must start with 0 or 1 and have 10 digits', function(value) {
        if (!value) return true;  // Allow empty or null

        return /^[01]\d{9}$/.test(value);
    }),

})
    .test('at-least-one-number', 'Please provide at least one contact number', function(values) {
        const { direct_landline_number, company_phone_number, mobile_number } = values;
        if (!direct_landline_number && !company_phone_number && !mobile_number) {
            return this.createError({ message: 'Please provide at least one contact number' });
        }
        return true;
    });

export default schema;