import * as React from 'react';
import DashboardContainer from '../../../dashboard-components/DashboardContainer';
import BrickSearchContainer from '../components/BrickSearchContainer';
import GVDTreeContainer from './GVDTreeContainer';
import { ContextSelectedBrick } from '../contexts/ContextSelectedBrick';
import { useAppSelector, useAppDispatch } from "../../../store/store";
import {
	gvdEditior,
	setBrick,
	setBrickDesc
} from "../../../store/slices/gvdEditiorSlice";

const GVDEditor = () => {
  const dispatch = useAppDispatch();
  const { brick, brickDesc } = useAppSelector(gvdEditior);

  const refreshBrick = () => {
    const copyBrick = brick;
    dispatch(setBrick(''));
    dispatch(setBrick(copyBrick));
  };

  const setSelectedBrick = (newBrick: string | null) => {
    dispatch(setBrick(newBrick || ''));
  };

  const setSelectedBrickDesc = (newDesc: string | null) => {
    dispatch(setBrickDesc(newDesc || ''));
  };

  return (
    <DashboardContainer title={"GVD Editor"}>
      <ContextSelectedBrick.Provider value={{
        BRICK: brick,
        BrickDesc: brickDesc,
        refreshBrick, setSelectedBrick, setSelectedBrickDesc
      }}>

        <BrickSearchContainer />
        <GVDTreeContainer />

      </ContextSelectedBrick.Provider>
    </DashboardContainer>
  );
};

export default GVDEditor;