import { Tab } from '@headlessui/react';
import React, { Fragment, useEffect, useState } from 'react';
import ReactDataGrid from "@inovua/reactdatagrid-enterprise";
import "@inovua/reactdatagrid-enterprise/index.css";
import Loader from "../../components/loader/Loader";
import { useAppDispatch, useAppSelector } from "../../store/store";
import {
  signupApproval,
  fethchParentCompanyDetails,
  setRecordType,
  setLimit,
  setPage,
  setLoading
} from "../../store/slices/signupApprovalSlice";
import { Link, useNavigate } from 'react-router-dom';
import ApplicationDialog from '../../components/ApplicationDialog';

const SignUpApproval = () => {
  const { signupDetails, loading, RecordType, limit, page } = useAppSelector(signupApproval);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [applicationDialog, setApplicationDialog] = useState(false);
  const [Decision, setDecision] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(setLoading(true));
        await dispatch(fethchParentCompanyDetails({ RecordType: RecordType, limit: limit }));
      } catch (error) {
        console.error('Error fetching parent company details:', error);
      } finally {
        dispatch(setLoading(false));
      }
    };

    fetchData();
  }, [dispatch, RecordType, limit]);


  const data = [
    {
      name: 'Entering',
    },
    {
      name: 'Open',
    },
    {
      name: 'More Info',
    },
    {
      name: 'Decision',
    },
    {
      name: 'Actioned',
    },
  ];

  const formatDateWithOrdinalAndTime = (dateString) => {
    if (!dateString) {
      return ''; // Handle undefined or null dateString
    }
    const options = { weekday: 'long', day: 'numeric', month: 'long', hour: 'numeric', minute: 'numeric' };
    const date = new Date(dateString);

    const getOrdinalSuffix = (day) => {
      if (day >= 11 && day <= 13) {
        return 'th';
      }
      const lastDigit = day % 10;
      switch (lastDigit) {
        case 1: return 'st';
        case 2: return 'nd';
        case 3: return 'rd';
        default: return 'th';
      }
    };

    const dayWithSuffix = date.getDate() + getOrdinalSuffix(date.getDate());
    const weekday = date.toLocaleDateString(undefined, { weekday: 'short' });
    const month = date.toLocaleDateString(undefined, { month: 'short' });

    // Construct the desired date format
    const formattedDate = `${weekday}, ${dayWithSuffix} ${month} (${date.toLocaleTimeString(undefined, { hour: 'numeric', minute: 'numeric' })})`;
    return formattedDate;
  };

  const generateColumns = () => {
    switch (RecordType) {
      case 'ENTERING':
        return [
          {
            name: ['CompanyName'],
            header: 'Company Name',
            textAlign: 'center',
            type: 'string', defaultWidth: 150,
            render: ({ value, data }) => <Link to={`/view-application/${data.SignupID}`}>{value}</Link>
          },
          {
            name: ['ApplicationTradingName'],
            header: 'Trading Name',
            textAlign: 'center',
            type: 'string', defaultWidth: 150,
            render: ({ value, data }) => <Link to={`/view-application/${data.SignupID}`}>{value}</Link>
          },
          {
            name: ['NameFirst'],
            header: 'First Name',
            type: 'string',
            textAlign: 'center',
            defaultWidth: 150,
            render: ({ value, data }) => <Link to={`/view-application/${data.SignupID}`}>{value}</Link>
          },
          {
            name: ['NameSurname'],
            header: 'Surname',
            type: 'string',
            textAlign: 'center',
            defaultWidth: 150,
            render: ({ value, data }) => <Link to={`/view-application/${data.SignupID}`}>{value}</Link>
          },
          { name: 'Position', header: 'Position', type: 'string', textAlign: 'center', defaultWidth: 300, render: ({ value, data }) => <Link to={`/view-application/${data.SignupID}`}>{value}</Link> },
          { name: 'calculateWorkdays', header: 'Inactive Days', type: 'string', defaultWidth: 300, render: ({ value }) => value?.length > 0 ? <Link to={`/view-application/${data.SignupID}`}>{value}</Link> : "" },
        ];
      case 'OPEN':
        return [
          {
            name: ['CompanyName'],
            header: 'Company Name',
            textAlign: 'center',
            type: 'string', defaultWidth: 150,
            render: ({ value, data }) => <Link to={`/view-application/${data.SignupID}`}>{value}</Link>
          },
          {
            name: ['ApplicationTradingName'],
            header: 'Trading Name',
            textAlign: 'center',
            type: 'string', defaultWidth: 150,
            render: ({ value, data }) => <Link to={`/view-application/${data.SignupID}`}>{value}</Link>
          },
          {
            name: ['NameFirst'],
            header: 'First Name',
            type: 'string',
            textAlign: 'center',
            defaultWidth: 150,
            render: ({ value, data }) => <Link to={`/view-application/${data.SignupID}`}>{value}</Link>
          },
          {
            name: ['NameSurname'],
            header: 'Surname',
            type: 'string',
            textAlign: 'center',
            defaultWidth: 150,
            render: ({ value, data }) => <Link to={`/view-application/${data.SignupID}`}>{value}</Link>
          },
          { name: 'Position', header: 'Position', type: 'string', textAlign: 'center', defaultWidth: 200, render: ({ value, data }) => <Link to={`/view-application/${data.SignupID}`}>{value}</Link> },
          { name: 'calculateWorkdays', header: 'Open Days', type: 'string', textAlign: 'center', defaultWidth: 200, render: ({ value }) => value?.length > 0 ? value : "" },
          { name: 'DateApplicationLodged', header: 'Submitted On', type: 'string', textAlign: 'center', defaultWidth: 200, render: ({ value }) => value ? formatDateWithOrdinalAndTime(value) : '' },
          {
            name: 'customDueDate',
            header: 'Due Date',
            type: 'string',
            textAlign: 'center',
            defaultWidth: 200,
            render: ({ value }) => {
              if (value) {
                const formattedDate = formatDateWithOrdinalAndTime(value);
                const currentDate = new Date();
                const dueDate = new Date(value);

                // Check if due date is today
                if (
                  dueDate.getDate() === currentDate.getDate() &&
                  dueDate.getMonth() === currentDate.getMonth() &&
                  dueDate.getFullYear() === currentDate.getFullYear()
                ) {
                  // Due today format (Bold Font)
                  return <span style={{ fontWeight: 'bold' }}>{formattedDate}</span>;
                }

                if (dueDate > currentDate) {
                  return formattedDate;
                }

                return <span style={{ fontWeight: 'bold', color: 'red' }}>{formattedDate}</span>;
              }

              return '';
            }
          }
        ];
      case 'MOREINFO':
        return [
          {
            name: ['CompanyName'],
            header: 'Company Name',
            textAlign: 'center',
            type: 'string', defaultWidth: 150,
            render: ({ value, data }) => <Link to={`/view-application/${data.SignupID}`}>{value}</Link>
          },
          {
            name: ['ApplicationTradingName'],
            header: 'Trading Name',
            textAlign: 'center',
            type: 'string', defaultWidth: 150,
            render: ({ value, data }) => <Link to={`/view-application/${data.SignupID}`}>{value}</Link>
          },
          {
            name: ['NameFirst'],
            header: 'First Name',
            type: 'string',
            textAlign: 'center',
            defaultWidth: 150,
            render: ({ value, data }) => <Link to={`/view-application/${data.SignupID}`}>{value}</Link>
          },
          {
            name: ['NameSurname'],
            header: 'Surname',
            type: 'string',
            textAlign: 'center',
            defaultWidth: 150,
            render: ({ value, data }) => <Link to={`/view-application/${data.SignupID}`}>{value}</Link>

          },
          { name: 'Position', header: 'Position', type: 'string', textAlign: 'center', defaultWidth: 150, render: ({ value, data }) => <Link to={`/view-application/${data.SignupID}`}>{value}</Link> },
          { name: 'MoreInfoCount', header: 'No. of Requests', type: 'string', textAlign: 'center', defaultWidth: 150, render: ({ value }) => value + " " + 'Count(s)' },
          { name: 'calculateWorkdays', header: 'Open Days', type: 'string', textAlign: 'center', defaultWidth: 200, render: ({ value }) => value?.length > 0 ? value : "" },
          { name: 'DateApplicationMoreInfo', header: 'Requested On', type: 'string', textAlign: 'center', defaultWidth: 200, render: ({ value }) => value ? formatDateWithOrdinalAndTime(value) : '' },
        ];
      case 'DECISION':
        return [
          {
            name: ['CompanyName'],
            header: 'Company Name',
            textAlign: 'center',
            type: 'string', defaultWidth: 150,
            render: ({ value, data }) => <Link to={`/view-application/${data.SignupID}`}>{value}</Link>
          },
          {
            name: ['ApplicationTradingName'],
            header: 'Trading Name',
            textAlign: 'center',
            type: 'string', defaultWidth: 150,
            render: ({ value, data }) => <Link to={`/view-application/${data.SignupID}`}>{value}</Link>
          },
          {
            name: ['NameFirst'],
            header: 'First Name',
            textAlign: 'center',
            type: 'string',
            defaultWidth: 150,
            render: ({ value, data }) => <Link to={`/view-application/${data.SignupID}`}>{value}</Link>
          },
          {
            name: ['NameSurname'],
            header: 'Surname',
            textAlign: 'center',
            type: 'string',
            defaultWidth: 150,
            render: ({ value, data }) => <Link to={`/view-application/${data.SignupID}`}>{value}</Link>
          },
          { name: 'Position', header: 'Position', type: 'string', textAlign: 'center', defaultWidth: 200, render: ({ value, data }) => <Link to={`/view-application/${data.SignupID}`}>{value}</Link> },
          { name: 'ApplicationSubmittedRecommendation', header: 'Suggestion', type: 'string', textAlign: 'center', defaultWidth: 150, render: ({ value }) => (value === "A" || value === "a") ? 'Approve' : 'Reject' },
          { name: 'calculateWorkdays', header: 'Open Days', type: 'string', textAlign: 'center', defaultWidth: 150, render: ({ value }) => value?.length > 0 ? value : "" },
          { name: 'DateApplicationSubmittedDecision', header: 'Requested On', type: 'string', textAlign: 'center', defaultWidth: 230, render: ({ value }) => value ? formatDateWithOrdinalAndTime(value) : '' },
        ];
      case 'ACTIONED':
        return [
          {
            name: ['CompanyName'],
            header: 'Company Name',
            textAlign: 'center',
            type: 'string', defaultWidth: 150,
            render: ({ value, data }) => <Link to={`/view-application/${data.SignupID}`}>{value}</Link>
          },
          {
            name: ['ApplicationTradingName'],
            header: 'Trading Name',
            textAlign: 'center',
            type: 'string', defaultWidth: 150,
            render: ({ value, data }) => <Link to={`/view-application/${data.SignupID}`}>{value}</Link>
          },
          {
            name: ['NameFirst'],
            header: 'First Name',
            type: 'string',
            textAlign: 'center',
            defaultWidth: 150,
            render: ({ value, data }) => <Link to={`/view-application/${data.SignupID}`}>{value}</Link>
          },
          {
            name: ['NameSurname'],
            header: 'Surname',
            type: 'string',
            textAlign: 'center',
            defaultWidth: 150,
            render: ({ value, data }) => <Link to={`/view-application/${data.SignupID}`}>{value}</Link>
          },
          { name: 'Position', header: 'Position', type: 'string', textAlign: 'center', defaultWidth: 200, render: ({ value, data }) => <Link to={`/view-application/${data.SignupID}`}>{value}</Link> },
          { name: 'ApplicationOutcome', header: 'Decision', type: 'string', textAlign: 'center', defaultWidth: 150, render: ({ value }) => (value === "A" || value === "a") ? 'Approved' : 'Rejected' },
          { name: 'calculateWorkdays', header: 'Application Duration', type: 'string', textAlign: 'center', defaultWidth: 200, render: ({ value }) => value?.length > 0 ? value : "" },
          { name: 'DateApplicationActioned', header: 'Actioned On', type: 'string', textAlign: 'center', defaultWidth: 200, render: ({ value }) => value ? formatDateWithOrdinalAndTime(value) : '' },
        ];
      default:
        return [];
    }
  };

  const columns = generateColumns();

  const renderRowContextMenu = (menuProps, { rowProps, cellProps }) => {
    const { SignupID } = rowProps.data;
    menuProps.autoDismiss = true;
    menuProps.items = [
      {
        label:
          <Link to={`/view-application/${SignupID}`} className="w-full">
            <button style={{ cursor: 'pointer' }} className="w-full">View Application Details</button>
          </Link>
      },
      {
        label:
          <button
            disabled={RecordType !== 'ACTIONED'}
            onClick={() => {
              setDecision(rowProps.data)
              setTimeout(() => {
                setApplicationDialog(!applicationDialog)
              });
            }} style={{ cursor: 'pointer' }} className={`${RecordType !== 'ACTIONED' ? 'text-gray-500' : 'text-blue-500'} w-full`}> View Decision</button>
      },
    ];
  };

  return (
    <div className="w-full mx-auto">
      <div className="flex flex-col">
        <div>
          <Tab.Group>
            <div className="flex justify-between items-center mt-2 rounded bg-primary pt-3 px-2">
              <Tab.List className="space-x-1">
                {data.map((panel, index) => (
                  <Tab as={Fragment} key={index}>
                    {({ selected }) => (
                      <button
                        onClick={() => dispatch(setRecordType(panel.name))}
                        className={`${selected ? "bg-white border border-transparent pb-2 pt-4 text-black" : "text-white py-2 border-white border-t border-x"
                      } overflow-visible relative font-medium outline-none text-sm rounded-t px-10`}
                      >
                        {panel.name}
                      </button>
                    )}
                  </Tab>
                ))}
              </Tab.List>
            </div>
            <Tab.Panels className="rounded-b-xl shadow-md p-5 bg-white">
              {data.map((panel, index) => (
                <Tab.Panel key={index}>
                  {signupDetails ? ( // Check if signupDetails is defined
                    <ReactDataGrid
                      licenseKey={'AppName=multi_app,Company=TrickleTreat,ExpiryDate=2023-11-18,LicenseDeveloperCount=1,LicenseType=multi_app,Ref=TrickleTreatLicenseRef,Z=14765888661045189689-1957688953-209693348514765888662114428621'}
                      idProperty={panel.name}
                      style={{ marginTop: 5, minHeight: '85vh' }}
                      enableFiltering={false}
                      columns={columns}
                      renderRowContextMenu={renderRowContextMenu}
                      // enableSelection={true}
                      // onSelectionChange={(ev) => navigate(`/view-application/${ev?.data?.SignupID}`)}
                      rowHeight={40}
                      loading={loading}
                      dataSource={loading ? [] : (signupDetails.data ?? [])}  // Use signupDetails.data or provide an empty array if it's undefined
                      pagination="remote"
                      onSkipChange={(o) => dispatch(setPage(Math.round(Math.max(o - 1, 0) / limit) + 1))}
                      onLimitChange={(o) => dispatch(setLimit(o))}
                      defaultLimit={10}
                    />
                  ) : (
                    <Loader />
                  )}
                </Tab.Panel>
              ))}
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
      <ApplicationDialog applicationDialog={applicationDialog} singleDetails={Decision} setapplicationDialog={setApplicationDialog} Decision={Decision} sethandleSave={() => setApplicationDialog(false)} />
    </div>
  );
};

export default SignUpApproval;
