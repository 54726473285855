import React from "react";

export default function UnderReview(){
    return(
        <div className={`w-full px-3 mt-20 rounded-xl overflow-y-auto`}>
            <div
                className="p-4 text-sm text-center text-green-800 rounded-lg bg-[#B3CC2D]">
                <p className="text-lg leading-7 text-black">
                    <span className="font-bold">Your application is currently under review. We will notify you once it is processed.</span>
                </p>
            </div>
        </div>
    );
}