import React, { useContext, useEffect, useState } from 'react'
import { useFormFieldOnChange } from '../../../../utils/FormHooks';
import { ContextSelectedBrick } from '../../contexts/ContextSelectedBrick';
import { SelectedGVDDetailContext } from '../context/SelectedGVDDetailContext';
import { SelectedRawWordsContext } from '../context/SelectedRawWordsContext';
import { useAppSelector, useAppDispatch } from "../../../../store/store";
import {
	gvdValidate,
	fetchAssignGroups,
  fetchAssignGroupVars,
  fetchAssignVariantData,
  setProcessing,
  setAssignVariants,
  setAssignGroups,
  setAssignDetails,
  newGVDAssign,
  assignGVDToWord
} from "../../../../store/slices/gvdValidateSlice";

const AssignCreateGVDContainer = () => {
  const dispatch = useAppDispatch();
  const { assignGroups, assignVariants, assignDetails, processing } = useAppSelector(gvdValidate);
  const [selectedGroup, setSelectedGroup, onSelectedGroupChange] = useFormFieldOnChange('');
  const [selectedVariant, setSelectedVariant, onSelectedVariantChange] = useFormFieldOnChange('');
  const [selectedDetail, setSelectedDetail, onSelectedDetailChange] = useFormFieldOnChange('');

  const gvdFieldsCreation = {
    Group: {
      field: useFormFieldOnChange(''),
      visible: useState(false),
      onNewShow: ['Group', 'Variant', 'Detail']
    },
    Variant: {
      field: useFormFieldOnChange(''),
      visible: useState(false),
      onNewShow: ['Variant', 'Detail']
    },
    Detail: {
      field: useFormFieldOnChange(''),
      visible: useState(false),
      onNewShow: ['Detail']
    }
  };

  const { BRICK, refreshBrick } = useContext(ContextSelectedBrick);
  const { selectedWords, clearWords } = useContext(SelectedRawWordsContext);
  const { refreshGVDs } = useContext(SelectedGVDDetailContext);

  useEffect(() => {
    (BRICK?.trim().length ?? 0) > 0 && loadGVDOptions();
  }, [BRICK]);

  useEffect(() => {
    (selectedGroup?.trim().length ?? 0) > 0 && fetchVariants();
  }, [selectedGroup]);

  useEffect(() => {
    (selectedVariant?.trim().length ?? 0) && fetchDetails();
  }, [selectedVariant]);

  useEffect(() => {
    if (assignGroups.length > 0) {
      setSelectedGroup(String(assignGroups[0].GroupingID));
    } else {
      setSelectedGroup(null);
    }
  }, [assignGroups]);
  
  useEffect(() => {
    if (assignVariants.length > 0) {
      setSelectedVariant(String(assignVariants[0].VariantID));
    } else {
      setSelectedVariant(null);
    }
  }, [assignVariants]); 
  
  useEffect(() => {
    if (assignDetails.length > 0) {
      setSelectedDetail(String(assignDetails[0].DetailID));
    } else {
      setSelectedDetail(null);
    }
  }, [assignDetails]);


  const loadGVDOptions = () => {
    cancel();
    dispatch(setAssignGroups([]));
    dispatch(setAssignVariants([]));
    dispatch(setAssignDetails([]));
    fetchAllGroups();
    refreshGVDs();
  };

  const fetchAllGroups = async () => {
    dispatch(fetchAssignGroups(BRICK));
  };

  const fetchVariants = async () => {
    dispatch(fetchAssignGroupVars(BRICK, selectedGroup));
  };

  const fetchDetails = async () => {
    dispatch(fetchAssignVariantData(BRICK, selectedGroup, selectedVariant));
  };

  const setGvdFieldsVisibility = (fields, visible) => {
    fields.forEach(field => {
      gvdFieldsCreation[field].visible[1](visible);

      if (!visible) {
        gvdFieldsCreation[field].field[1]('');
      }
    });
  };

  const cancel = () => {
    setGvdFieldsVisibility(['Group', 'Variant', 'Detail'], false)
    clearWords();
  };

  let hasCreatedNewGVD = false;
  const assign = async () => {
    if ((BRICK?.trim().length ?? 0) == 0) return;

    const gvd = await getGVDToAssignTo();

    if (gvd == false || selectedWords.length == 0) {

      if (hasCreatedNewGVD) {
        loadGVDOptions();
      }

      dispatch(setProcessing(false));
      return;
    };

    const [group, variant, detail] = gvd;

const VWMID = selectedWords.map(wordMatch => wordMatch.WordMatchID);
dispatch(assignGVDToWord(BRICK, group, variant, detail, VWMID))


    loadGVDOptions();
    refreshBrick()
  };

  const getGVDToAssignTo = async () => {
    let group = selectedGroup;
    let variant = selectedVariant;
    let detail = selectedDetail;

    let createNewGVD = false;
    if (gvdFieldsCreation.Group.visible[0] == true) {
      group = gvdFieldsCreation.Group.field[0];
      createNewGVD = true;
    }

    if (gvdFieldsCreation.Variant.visible[0] == true) {
      variant = gvdFieldsCreation.Variant.field[0];
      createNewGVD = true;
    }

    if (gvdFieldsCreation.Detail.visible[0] == true) {
      detail = gvdFieldsCreation.Detail.field[0];
      createNewGVD = true;
    }

    if (createNewGVD) {
      dispatch(newGVDAssign(BRICK, group, variant, detail))
      hasCreatedNewGVD = true;
    }
    return [group, variant, detail];
  };

  const getDropdown = (label, data, key, desc, dropdownValue, onSelectedOnChange) => {
    const fieldCreation = gvdFieldsCreation[label];

    const createNewFn = () => setGvdFieldsVisibility(fieldCreation.onNewShow, true);

    return (
      <>
        <label className="col-span-2 font-semibold truncate">{label}</label>
        {!fieldCreation.visible[0] && (
          <>
            <select className="rounded border border-gray py-2 col-span-8" value={dropdownValue ?? ''} onChange={onSelectedOnChange}>
              {data.map((item, index) => (
                <option key={index} value={item[key]}>{item[desc] ?? 'Not Defined'}</option>
              ))}
            </select>
            <button className="bg-primary col-span-2 hover:bg-lightPrimary text-white py-2 px-3 flex justify-center items-center rounded" onClick={createNewFn}>+</button>
          </>
        )}

        {fieldCreation.visible[0] && (
          <>
            <input value={fieldCreation.field[0]} onChange={fieldCreation.field[2]} className="rounded col-span-8 border border-gray py-2 px-2" type="text" />
            <span className='col-span-2'></span>
          </>
        )}

      </>
    );
  };

  return (
    <>
      <div className="gvd-assign-create mb-3 bg-white border flex flex-col request-processing-indicator">
        <div className="flex flex-row flex-grow overflow-y-auto relative h-auto p-3 w-full">
          <div className="grid grid-cols-12 gap-2 items-center">
            {getDropdown('Group', assignGroups, 'GroupingID', 'GroupingDesc', selectedGroup, onSelectedGroupChange)}
            {getDropdown('Variant', assignVariants, 'VariantID', 'VariantDesc', selectedVariant, onSelectedVariantChange)}
            {getDropdown('Detail', assignDetails, 'DetailID', 'DetailDesc', selectedDetail, onSelectedDetailChange)}
          </div>
          <div className="flex flex-col ml-3 gap-2">
            <button className="bg-primary  hover:bg-lightPrimary text-white py-2 px-3 flex justify-center items-center rounded" onClick={assign}>Assign</button>
            <button className="bg-gray-500 hover:bg-gary-600 text-white py-2 px-3 flex justify-center items-center rounded" onClick={cancel}>Cancel</button>
          </div>
        </div>

        {processing && (
          <div className="request-processing">
            <span>Processing...</span>
          </div>
        )}
      </div>
    </>
  );
};

export default AssignCreateGVDContainer;