import React from 'react'
import DashboardContainer from '../../dashboard-components/DashboardContainer';
import WrapperGPCFilters from './components/WrapperGPCFilters';

import './MyProducts.scss'

const MyProducts = () => {
  return (
    <DashboardContainer title={"My Products"}>
      <div className="supplier-my-products-container">
        <WrapperGPCFilters />
      </div>
    </DashboardContainer>
  )
};
export default MyProducts;