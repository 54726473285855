import React, { useState } from 'react';
import './ProductBricks.css';

const TreeItemComp = ({ style, itemName, children }) => {

  const [showSubItem, setShowSubItem] = useState(false);
  const [styleState] = useState({
    padding: '13px',
    cursor: 'pointer',
    ...style
  });

  const showHideSubItem = () => setShowSubItem(!showSubItem);

  const containerCss = {
    marginLeft: '1rem',
    marginTop: '0.3rem',
    marginBottom: '0.3rem'
  };

  return (
    <div>
      <div className="FunctionalGroupComp" onClick={showHideSubItem} style={styleState}>{itemName}</div>
      {showSubItem && (
        <div style={containerCss}>{children}</div>
      )}
    </div>
  );
};

export default TreeItemComp;