import React from "react";
import { useNavigate } from "react-router-dom";

const AppCompleteRegistration = () => {
  
  const handleClose = () => {
    window.close();
  };


  return (
    <div className="SignUpPageContainer">
      <form>
        <img
          style={{ width: "200px", marginBottom: "1rem", alignSelf: "center" }}
          src="BBA-Logo-TBl-S.png"
          alt=""
        />
        <p>Congratulations, your account has been verified!</p>
        <p style={{fontWeight:"normal"}}>Please close this page, return to the app and login</p>
        <div className="FormButtonContainer">
          <button onClick={handleClose}>Close</button>
        </div>
      </form>
    </div>
  );
};

export default AppCompleteRegistration;
