import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useAppSelector, useAppDispatch } from "../../store/store";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import Box from '@mui/material/Box';
import {
  addForm,
  orderSelector,
  fetchCountry,
  fetchState,
  fetchNumberOfEmployees,
  fetchAnnualTurnover,
  fetchIndustries,
  fetchAccountDetails,
  submitAccountDetails,
  setIndex,
} from "../../store/slices/getStartedSlice";
import { useAuthContext } from '../../context/AuthContext';
import GetStartedTab from "./GetStartedTab";
import Loader from "../../components/loader/Loader";
import schema from "./validationSchemas"

const GetStartedAccountDetails = () => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userAuth = useAuthContext();
  const [isFormLoaded, setIsFormLoaded] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const { form, country, state, numberOfEmployees, annualTurnover, industries } = useAppSelector(orderSelector);
  const { t } = useTranslation(["common"]);
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    setError,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    const dataValues = getValues();
    const validationErrors = [];

    // Custom validation for number_of_employees
    if (dataValues?.number_of_employees === "1000136") {
      validationErrors.push({
        field: 'number_of_employees',
        message: 'Number of employees is required',
      });
    }

    // Custom validation for annual_turnover
    if (dataValues?.annual_turnover === "1000128") {
      validationErrors.push({
        field: 'annual_turnover',
        message: 'Annual turnover is required',
      });
    }

    // Custom validation for Industry
    if (dataValues?.industry === "1000098") {
      validationErrors.push({
        field: 'industry',
        message: 'Industry is required, select OTHER if not in list',
      });
    }

    // Custom validation for country_id
    if (dataValues?.country_id === "000") {
      validationErrors.push({
        field: 'country_id',
        message: 'Country is required,',
      });
    }

    if (!form?.state) {
      validationErrors.push({
        field: 'state',
        message: 'State is required',
      });
    }

    // Check if there are any errors
    if (validationErrors.length > 0) {
      // Set all collected errors
      validationErrors.forEach(error => {
        setError(error.field, {
          type: 'manual',
          message: error.message,
        });
      });

      return;
    }

    // Your form submission logic
    const json = await dispatch(submitAccountDetails(form));

    if (json.status === 204) {
      dispatch(setIndex(1));
      navigate("/GetStarted/AccountPrefix");
    }
  };

  const setFormField = (key, value) => {
    let data = Object.assign({}, form);
    data[key] = value;
    dispatch(addForm(data));
    if (key == "country_id") {
      dispatch(fetchState(value));
    }
  };

  const delayPromise = () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(true);
      }, 4000);
    });
  };

  const fetchUpdatedForm = async () => {
    let data = Object.assign({}, form);
    setLoaded(true)
    if(!isFormLoaded){
        for (const [key, value] of Object.entries(data)) {
          setValue(key, value);
        }
        if(Object.keys(data).length>23){
          setIsFormLoaded(true);
        }
    }

    if(isFormLoaded){
      setLoaded(false)
    }

    if(form?.country_id && (state || []).length==0){
      dispatch(fetchState(form?.country_id));
    }
  }

  useEffect(()=>{
    fetchUpdatedForm()
  }, [form])


  useEffect(() => {
    // Uncomment this block if you want to navigate to "/DashboardHome" if the user hasn't signed up.
    // if (!user?.hasSignedUp) {
    //   navigate("/DashboardHome");
    // }
    delayPromise().then((result) => {
      if(isFormLoaded || result){
        setLoaded(false)
      }
    });
    fetchCountryData()
  }, []);

  const fetchCountryData = async () => {
    await dispatch(fetchCountry());
    let data = Object.assign({}, form);
    data = {
      ...data,
      country_id:"036",
      account_type:"supplier"

    }
    dispatch(addForm(data));
    if(process.env.REACT_APP_SELECTED_COUNTRY_ID){
      dispatch(fetchState(process.env.REACT_APP_SELECTED_COUNTRY_ID));
    }
    await Promise.all([
      dispatch(fetchNumberOfEmployees()),
      dispatch(fetchAnnualTurnover()),
      dispatch(fetchIndustries()),
      dispatch(fetchAccountDetails()),
    ]);
  }

  return (
      <div className="w-full max-w-7xl mx-auto py-10">
      {/* Navigation component */}
      <GetStartedTab />
        {
            loaded ?
            <div className={'absolute inset-0 m-auto w-16 h-16'}>
              <Loader width={'w-full'} heigth={'h-full'} />
            </div>:
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="py-3 mt-10">
                    <div className="rounded-xl shadow-md p-4 bg-white">
                      <h1 className="font-bold text-lg">{t("common:heading_contact_person").toString()}</h1>
                      <div className="gap-5 grid lg:grid-cols-2 grid-cols-1 mt-10">
                        {/* Input fields with labels */}
                        <div className="flex gap-3 flex-col">
                          {/* First Name */}
                          <Box htmlFor="first_name" sx={{ color: 'primary.main' }}>{t("common:first_name").toString()}</Box>
                          <input
                              {...register("first_name")}
                              onChange={(e) => setFormField("first_name", e.target.value)}
                              type="text"
                              placeholder="Enter first name"
                              value={form?.first_name || ""}
                              id="first_name"
                              className="rounded-md w-full relative px-3 py-2 border shadow-sm sm:text-sm"
                          />
                          {/* Display validation errors */}
                          {errors.first_name && (
                              <Box className="text-sm  peer-[&:not(:placeholder-shown):not(:focus):invalid]:block" sx={{ color: 'secondary.main' }}>{errors.first_name?.message}</Box>
                          )}
                        </div>
                        {/* Input fields with labels */}
                        <div className="flex gap-3 flex-col">
                          {/* Last Name */}
                          <Box htmlFor="last_name" sx={{ color: 'primary.main' }}>{t("common:last_name").toString()}</Box>
                          <input
                              {...register("last_name")}
                              onChange={(e) => setFormField("last_name", e.target.value)}
                              type="text"
                              placeholder="Enter last name"
                              value={form?.last_name || ""}
                              id="last_name"
                              className="rounded-md w-full relative px-3 py-2 border shadow-sm sm:text-sm"
                          />
                          {/* Display validation errors */}
                          {errors.last_name && (
                              <Box className="text-sm  peer-[&:not(:placeholder-shown):not(:focus):invalid]:block" sx={{ color: 'secondary.main' }}>{errors.last_name?.message}</Box>

                          )}
                        </div>
                        {/* Input fields with labels */}
                        <div className="flex gap-3 flex-col">
                          {/* Last Name */}
                          <Box htmlFor="prefered_name" sx={{ color: 'primary.main' }}>{t("common:prefered_name").toString()}</Box>
                          <input
                              {...register("prefered_name")}
                              type="text"
                              id="prefered_name"
                              placeholder="Enter prefered name"
                              value={form?.prefered_name || ""}
                              onChange={(e) => setFormField("prefered_name", e.target.value)}
                              className="rounded-md w-full relative px-3 py-2 border shadow-sm sm:text-sm"
                          />
                          {errors.prefered_name && (
                              <Box className="text-sm  peer-[&:not(:placeholder-shown):not(:focus):invalid]:block" sx={{ color: 'secondary.main' }}>{errors.prefered_name?.message}</Box>
                          )}

                        </div>
                        {/* Input fields with labels */}
                        <div className="flex gap-3 flex-col">
                          {/* Position */}
                          <Box htmlFor="position" sx={{ color: 'primary.main' }}>{t("common:position").toString()}</Box>
                          <input
                              {...register("position")}
                              onChange={(e) => setFormField("position", e.target.value)}
                              type="text"
                              value={form?.position || ""}
                              placeholder="Enter position"
                              id="position"
                              className="rounded-md w-full relative px-3 py-2 border shadow-sm sm:text-sm"
                          />
                          {/* Display validation errors */}
                          {errors.position && (
                              <Box className="text-sm  peer-[&:not(:placeholder-shown):not(:focus):invalid]:block" sx={{ color: 'secondary.main' }}>{errors.position?.message}</Box>
                          )}
                        </div>
                        {/* Input fields with labels */}
                        <div className="flex gap-3 flex-col">
                          {/* Last Name */}
                          <Box htmlFor="direct_line" sx={{ color: 'primary.main' }}>{t("common:direct_line").toString()}</Box>
                          <input
                              {...register("direct_landline_number")}
                              value={form?.direct_landline_number || ""}
                              onChange={(e) => setFormField("direct_landline_number", e.target.value.replace(/\s/g, ''))}
                              type="text"
                              placeholder="Enter landline number"
                              className="rounded-md w-full relative px-3 py-2 border shadow-sm sm:text-sm"
                          />
                          {errors.direct_landline_number && (
                              <Box className="text-sm  peer-[&:not(:placeholder-shown):not(:focus):invalid]:block" sx={{ color: 'secondary.main' }}>{errors.direct_landline_number?.message}</Box>
                          )}
                        </div>
                        {/* Input fields with labels */}
                        <div className="flex gap-3 flex-col">
                          {/* Mobile Number */}
                          <Box htmlFor="mobile_number" sx={{ color: 'primary.main' }}>{t("common:mobile_number").toString()}</Box>
                          <input
                              {...register("mobile_number")}
                              onChange={(e) => setFormField("mobile_number", e.target.value.replace(/\s/g, ''))}
                              type="text"
                              placeholder="Enter mobile number"
                              value={form?.mobile_number || ""}
                              id="number"
                              className="rounded-md w-full relative px-3 py-2 border shadow-sm sm:text-sm"
                          />
                          {/* Display validation errors */}
                          {errors.mobile_number && (
                              <Box className="text-sm  peer-[&:not(:placeholder-shown):not(:focus):invalid]:block" sx={{ color: 'secondary.main' }}>{errors.mobile_number?.message}</Box>
                          )}
                        </div>
                      </div>
                      {errors?.[""]?.message && (
                          <Box className="text-sm mt-2 peer-[&:not(:placeholder-shown):not(:focus):invalid]:block" sx={{ color: 'secondary.main' }}>{errors?.[""]?.message}</Box>
                      )}
                    </div>
                    <div className="rounded-xl shadow-md p-4 mt-10 bg-white">
                      <h1 className="font-bold text-lg">{t("common:heading_company_information").toString()} </h1>
                      <div className="gap-5 grid lg:grid-cols-2 grid-cols-1 mt-10">
                        {/* Input fields with labels */}
                        <div className="flex gap-3 flex-col">
                          {/* Account type */}
                          <Box htmlFor="company_name" sx={{ color: 'primary.main' }}>{t("common:company_name").toString()}</Box>
                          <input
                              {...register("company_name", {
                                required: "company name is required",
                              })}
                              onChange={(e) => setFormField("company_name", e.target.value)}
                              type="text"
                              value={form?.company_name || ""}
                              id="company_name"
                              placeholder="Enter company name"
                              className="rounded-md w-full relative px-3 py-2 border shadow-sm sm:text-sm"
                          />
                          {/* Display validation errors */}
                          {errors.company_name && (
                              <Box className="text-sm  peer-[&:not(:placeholder-shown):not(:focus):invalid]:block" sx={{ color: 'secondary.main' }}>{errors.company_name?.message}</Box>
                          )}
                        </div>
                        {/* Input fields with labels */}
                        <div className="flex gap-3 flex-col">
                          {/* Trading namee */}
                          <Box htmlFor="trading_name" sx={{ color: 'primary.main' }}>{t("common:trading_name").toString()}</Box>
                          <input
                              {...register("trading_name", {
                                required: "trading name is required",
                              })}
                              onChange={(e) => setFormField("trading_name", e.target.value)}
                              type="text"
                              value={form?.trading_name || ""}
                              id="trading_name"
                              placeholder="Enter trading number"
                              className="rounded-md w-full relative px-3 py-2 border shadow-sm sm:text-sm"
                          />
                          {/* Display validation errors */}
                          {errors.trading_name && (
                              <Box className="text-sm  peer-[&:not(:placeholder-shown):not(:focus):invalid]:block" sx={{ color: 'secondary.main' }}>{errors.trading_name?.message}</Box>
                          )}
                        </div>
                        {/* Input fields with labels */}
                        <div className="flex gap-3 flex-col">
                          {/* Company abn */}
                          <Box htmlFor="company_abn" sx={{ color: 'primary.main' }}>{t("common:company_abn").toString()}</Box>
                          <input
                              {...register("company_abn", {
                                required: "company abn is required",
                              })}
                              onChange={(e) => setFormField("company_abn", e.target.value.replace(/\s/g, ''))}
                              type="text"
                              value={form?.company_abn || ""}
                              id="companyabn"
                              placeholder="Enter company abn"
                              className="rounded-md w-full relative px-3 py-2 border shadow-sm sm:text-sm"
                          />
                          {/* Display validation errors */}
                          {errors.company_abn && (
                              <Box className="text-sm  peer-[&:not(:placeholder-shown):not(:focus):invalid]:block" sx={{ color: 'secondary.main' }}>{errors.company_abn?.message}</Box>
                          )}
                        </div>
                        {/* Input fields with labels */}
                        <div className="flex gap-3 flex-col">
                          {/* Account type */}
                          <Box htmlFor="number_of_employees" sx={{ color: 'primary.main' }}>{t("common:number_of_employees").toString()}</Box>
                          <select
                              {...register("number_of_employees")}
                              onChange={(e) => setFormField("number_of_employees", e.target.value)}
                              value={form?.number_of_employees || ""}
                              className='rounded-md  relative px-3 py-2 border shadow-sm sm:text-sm'
                          >
                            {(numberOfEmployees || []).map((item, index)=>(
                                <option key={index} value={item.SystemSelectID === "1000136" ? "" : item.SystemSelectID }>{item.SelectionName}</option>
                            ))}
                          </select>
                          {/* Display validation errors */}
                          {errors.number_of_employees && (
                              <Box className="text-sm  peer-[&:not(:placeholder-shown):not(:focus):invalid]:block" sx={{ color: 'secondary.main' }}>{errors.number_of_employees?.message}</Box>
                          )}
                        </div>
                        {/* Input fields with labels */}
                        <div className="flex gap-3 flex-col">
                          {/* Account type */}
                          <Box htmlFor="account_type" sx={{ color: 'primary.main' }}>{t("common:account_type").toString()}</Box>
                          <select
                              {...register("account_type")}
                              onChange={(e) => setFormField("account_type", e.target.value)}
                              value={form?.account_type || ""}
                              disabled
                              className='rounded-md w-full relative px-3 py-2 border shadow-sm sm:text-sm'
                          >
                            <option selected={"supplier" === form.account_type} value="supplier">Supplier</option>
                            <option selected={"buyer" === form.account_type} value="buyer">Buyer</option>
                          </select>
                          {/* Display validation errors */}
                          {errors.account_type && (
                              <Box className="text-sm  peer-[&:not(:placeholder-shown):not(:focus):invalid]:block" sx={{ color: 'secondary.main' }}>{errors.account_type?.message}</Box>
                          )}
                        </div>
                        {/* Input fields with labels */}
                        <div className="flex gap-3 flex-col">
                          {/* Account type */}
                          <Box htmlFor="turnover" sx={{ color: 'primary.main' }}>{t("common:annual_turnover").toString()}</Box>
                          <select
                              {...register("annual_turnover")}
                              onChange={(e) => setFormField("annual_turnover", e.target.value)}
                              value={form?.annual_turnover || ""}
                              className='rounded-md w-full relative px-3 py-2 border shadow-sm sm:text-sm'
                          >
                            {(annualTurnover || []).map((item, index)=>(
                                <option key={index} value={item.SystemSelectID}>{item.SelectionName}</option>
                            ))}
                          </select>
                          {errors.annual_turnover && (
                              <Box className="text-sm  peer-[&:not(:placeholder-shown):not(:focus):invalid]:block" sx={{ color: 'secondary.main' }}>{errors.annual_turnover?.message}</Box>
                          )}
                        </div>

                        {/* Input fields with labels */}
                        <div className="flex gap-3 flex-col">
                          {/* industry */}
                          <Box htmlFor="industry" sx={{ color: 'primary.main' }}>{t("common:industry").toString()}</Box>
                          <select
                              {...register("industry")}
                              onChange={(e) => setFormField("industry", e.target.value)}
                              value={form?.industry || ""}
                              className='rounded-md w-full relative px-3 py-2 border shadow-sm sm:text-sm'
                          >
                            {(industries || []).map((item, index)=>(
                                <option key={index} value={item.SystemSelectID}>{item.SelectionName}</option>
                            ))}
                          </select>
                          {/* Display validation errors */}
                          {errors.industry && (
                              <Box className="text-sm  peer-[&:not(:placeholder-shown):not(:focus):invalid]:block" sx={{ color: 'secondary.main' }}>{errors.industry?.message}</Box>
                          )}
                        </div>
                        {/* Input fields with labels */}
                        <div className="flex gap-3 flex-col">
                          {/* other */}
                          <Box htmlFor="other" sx={{ color: 'primary.main' }}>{t("common:other").toString()}</Box>
                          <input
                              {...register("OtherIndustry")}
                              onChange={(e) => setFormField("OtherIndustry", e.target.value)}
                              type="text"
                              value={form?.OtherIndustry || ""}
                              disabled={((industries || []).filter(i=>i.SystemSelectID==form?.industry)?.[0] || {})?.SelectionName!="-- OTHER --"}
                              className="rounded-md w-full relative px-3 py-2 border shadow-sm sm:text-sm"
                          />
                          {/* Display validation errors */}
                          {errors.OtherIndustry && (
                              <Box className="text-sm  peer-[&:not(:placeholder-shown):not(:focus):invalid]:block" sx={{ color: 'secondary.main' }}>{errors.OtherIndustry?.message}</Box>
                          )}
                        </div>
                        {/* Input fields with labels */}
                        <div className="flex gap-3 flex-col">
                          {/* Company phone number */}
                          <Box htmlFor="company_phone_number" sx={{ color: 'primary.main' }}>{t("common:company_phone_number").toString()}</Box>
                          <input
                              {...register("company_phone_number", {
                                required: "phone number is required",
                              })}
                              onChange={(e) =>
                                  setFormField("company_phone_number", e.target.value.replace(/\s/g, ''))
                              }
                              type="text"
                              placeholder="Enter company phone number"
                              value={form?.company_phone_number || ""}
                              id="company_phone_number"
                              className="rounded-md w-full relative px-3 py-2 border shadow-sm sm:text-sm"
                          />
                          {/* Display validation errors */}
                          {errors.company_phone_number && (
                              <Box className="text-sm  peer-[&:not(:placeholder-shown):not(:focus):invalid]:block" sx={{ color: 'secondary.main' }}>{errors.company_phone_number?.message}</Box>
                          )}
                        </div>
                        {/* Input fields with labels */}
                        <div className="flex gap-3 flex-col">
                          {/* Company address 1 */}
                          <Box htmlFor="Website" sx={{ color: 'primary.main' }}>{t("common:website").toString()}</Box>
                          <input
                              {...register("website", {
                                required: "Website is required",
                              })}
                              type="text"
                              onChange={(e) =>
                                  setFormField("website", e.target.value)
                              }
                              id="Website"
                              placeholder="Enter website"
                              value={form?.website || ""}
                              className="rounded-md w-full relative px-3 py-2 border shadow-sm sm:text-sm"
                          />
                          {errors.website && (
                              <Box className="text-sm  peer-[&:not(:placeholder-shown):not(:focus):invalid]:block" sx={{ color: 'secondary.main' }}>{errors.website?.message}</Box>
                          )}
                        </div>
                        {/* Input fields with labels */}
                        <div className="flex gap-3 flex-col">
                          {/* Company address 1 */}
                          <Box htmlFor="company_address_1" sx={{ color: 'primary.main' }}>{t("common:company_address_1").toString()}</Box>
                          <input
                              {...register("company_address_1", {
                                required: "address 1 is required",
                              })}
                              onChange={(e) =>
                                  setFormField("company_address_1", e.target.value)
                              }
                              type="text"
                              placeholder="Enter company address"
                              value={form?.company_address_1 || ""}
                              id="company_address_1"
                              className="rounded-md w-full relative px-3 py-2 border shadow-sm sm:text-sm"
                          />
                          {/* Display validation errors */}
                          {errors.company_address_1 && (
                              <Box className="text-sm  peer-[&:not(:placeholder-shown):not(:focus):invalid]:block" sx={{ color: 'secondary.main' }}>{errors.company_address_1?.message}</Box>
                          )}
                        </div>
                        {/* Input fields with labels */}
                        <div className="flex gap-3 flex-col">
                          {/* Company address 2 */}
                          <Box htmlFor="company_address_2" sx={{ color: 'primary.main' }}>{t("common:company_address_2").toString()}</Box>
                          <input
                              {...register("company_address_2", {
                                required: "address 2 is required",
                              })}
                              onChange={(e) =>
                                  setFormField("company_address_2", e.target.value)
                              }
                              type="text"
                              placeholder="Enter company address 2"
                              value={form?.company_address_2 || ""}
                              id="company_address_2"
                              className="rounded-md w-full relative px-3 py-2 border shadow-sm sm:text-sm"
                          />
                          {/* Display validation errors */}
                          {errors.company_address_2 && (
                              <Box className="text-sm  peer-[&:not(:placeholder-shown):not(:focus):invalid]:block" sx={{ color: 'secondary.main' }}>{errors.company_address_2?.message}</Box>
                          )}
                        </div>
                        {/* Input fields with labels */}
                        <div className="flex gap-3 flex-col">
                          {/* city */}
                          <Box htmlFor="city" sx={{ color: 'primary.main' }}>{t("common:city").toString()}</Box>
                          <input
                              {...register("city", { required: "city is required" })}
                              onChange={(e) => setFormField("city", e.target.value)}
                              type="text"
                              value={form?.city || ""}
                              id="city"
                              placeholder="Enter the city"
                              className="rounded-md w-full relative px-3 py-2 border shadow-sm sm:text-sm"
                          />
                          {/* Display validation errors */}
                          {errors.city && (
                              <Box className="text-sm  peer-[&:not(:placeholder-shown):not(:focus):invalid]:block" sx={{ color: 'secondary.main' }}>{errors.city?.message}</Box>
                          )}
                        </div>
                        {/* Input fields with labels */}
                        <div className="flex gap-3 flex-col">
                          {/* state */}
                          <Box htmlFor="state" sx={{ color: 'primary.main' }}>{t("common:state").toString()}</Box>
                          <select
                              {...register("state")}
                              value={form?.state || ""}
                              onChange={(e) => setFormField("state", e.target.value)}
                              className='rounded-md w-full relative px-3 py-2 border shadow-sm sm:text-sm'
                          >
                            <option value="">Select State</option>
                            {(state || []).map((item, index) => (
                                <option key={index} value={item.StateName} selected={item?.StateName == form?.state}>
                                  {item.StateName}
                                </option>
                            ))}
                          </select>
                          {/* Display validation errors */}
                          {errors.state && (
                              <Box className="text-sm  peer-[&:not(:placeholder-shown):not(:focus):invalid]:block" sx={{ color: 'secondary.main' }}>{errors.state?.message}</Box>
                          )}
                        </div>
                        {/* Input fields with labels */}
                        <div className="flex gap-3 flex-col">
                          {/* Postcode */}
                          <Box htmlFor="postcode" sx={{ color: 'primary.main' }}>{t("common:postcode").toString()}</Box>
                          <input
                              {...register("postcode", { required: "postcode is required" })}
                              onChange={(e) => setFormField("postcode", e.target.value)}
                              type="text"
                              value={form?.postcode || ""}
                              id="postcode"
                              placeholder="Enter the postcode"
                              className="rounded-md w-full relative px-3 py-2 border shadow-sm sm:text-sm"
                          />
                          {/* Display validation errors */}
                          {errors.postcode && (
                              <Box className="text-sm  peer-[&:not(:placeholder-shown):not(:focus):invalid]:block" sx={{ color: 'secondary.main' }}>{errors.postcode?.message}</Box>
                          )}
                        </div>

                        {/* Input fields with labels */}
                        <div className="flex gap-3 flex-col">
                          {/* country */}
                          <Box htmlFor="country" sx={{ color: 'primary.main' }}>{t("common:country").toString()}</Box>
                          <select
                              {...register("country_id")}
                              onChange={(e) => setFormField("country_id", e.target.value)}
                              value={form?.country_id}
                              //id={"country_id"}
                              className='rounded-md w-full relative px-3 py-2 border shadow-sm sm:text-sm'
                          >
                            {(country || []).map((item, index) => (
                                <option key={index} value={item.CountryCode} selected={item.CountryCode === form.country_id}>
                                  {item.CountryName}
                                </option>
                            ))}
                          </select>
                          {/* Display validation errors */}
                          {errors.country_id && (
                              <Box className="text-sm  peer-[&:not(:placeholder-shown):not(:focus):invalid]:block" sx={{ color: 'secondary.main' }}>{errors.country_id?.message}</Box>
                          )}
                        </div>
                      </div>
                    </div>
                    {/* Next button  */}
                    <div className="mt-3">
                      <button disabled={loaded}
                              type="submit"
                              className="px-4 py-2 bg-primary uppercase text-white rounded ripple"
                      >
                        {t("common:next").toString()}
                      </button>
                    </div>
                  </div>
                </form>
        }


    </div>
  );
};

export default GetStartedAccountDetails;
