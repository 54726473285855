import getDefaultRequest, { getAuthRequest } from "./API.js";
import { getToken } from "../provider/AuthProvider";

export const Auth = () => {

  const login = async (email, password) => {
    try {
      return await getDefaultRequest().post('auth/signin', { email, password });
    } catch (error) {
      return error.response;
    }
  }

  const signupInfo = async (query) => {
    try {
      return await getAuthRequest(getToken()).post('/tab/SIGG', {
        FK_UserID: query.FK_UserID,
        SignupID: query.SignupID,
        FK_CouponUsed: query?.FK_CouponUsed,
      });
    } catch (error) {
      return error.response;
    }
  }

  const PreRegister = async (Name, Email, Password) => {
    try {
      return await getDefaultRequest().post('auth/preregister', { Name, Email, Password });
    } catch (error) {
      return error.response;
    }
  }

  const ForgotPassword = async (Email) => {
    try {
      return await getDefaultRequest().post('auth/forgotpw', { email: Email });
    } catch (error) {
      return error.response;
    }
  }

  const ResetPassword = async (Email, Password, PasswordConfirmation, Token) => {
    try {
      return await getDefaultRequest().post('auth/resetpw', {
        email: Email,
        password: Password,
        password_confirmation: PasswordConfirmation,
        token: Token,
      });
    } catch (error) {
      return error.response;
    }
  }


  const signInPrompt = async (payload) => {
    try {
      return await getDefaultRequest().post('/tab/USRP', payload);
    } catch (error) {
      return error.response;
    }
  }

  return { login, PreRegister, ForgotPassword, ResetPassword, signupInfo, signInPrompt };

};