import React, { useState } from 'react';
import { SelectedGVDDetailContext } from '../context/SelectedGVDDetailContext';
import AssignCreateGVDContainer from './AssignCreateGVDContainer';
import AssignedWordsContainer from './AssignedWordsContainer';
import GVDContainer from './GVDContainer';

const WrapperSelectedGVDDetail = () => {

  const [detail, setDetail] = useState({ Group: '', Variant: '', Detail: '' });

  const setSelectedDetail = (newVariant) => {
    setDetail(newVariant);
  };

  const refreshGVDs = () => setDetail({ Group: '', Variant: '', Detail: '' });

  return (
    <SelectedGVDDetailContext.Provider value={{ selectedDetail: detail, setSelectedDetail, refreshGVDs }}>
      <AssignCreateGVDContainer />
      <GVDContainer />
      <AssignedWordsContainer />
    </SelectedGVDDetailContext.Provider>
  );
};

export default WrapperSelectedGVDDetail;