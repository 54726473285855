import React from 'react';
import './ModalActions.css';

const ModalActions = (props) => {
  return (
    <div className="ModalActions">
      {props.children}
    </div>
  );
};
export default ModalActions;