import React from 'react';
import PageSkeletonComponent from '../../components/PageSkeletonComponent/PageSkeletonComponent';
import './LoyaltyProgram.css';

const LoyaltyProgram = () => {
  return (
    <PageSkeletonComponent>

      <h1>LOYALTY - Program Page</h1>

      <hr />

      <h2>Purpose of Page</h2>
      <b>OUTLINE OF WHAT THIS PAGE IS USED FOR AND ANY FUNCTIONALITY THAT IS KNOWN</b>

      <h2>Who Can Access</h2>
      <b>WHO IS EXPECTED TO ACCESS THIS PAGE</b>

    </PageSkeletonComponent>
  );
};

export default LoyaltyProgram;