import React, { useState } from 'react';
import FunctionalGroupContainer from './FuncGroupContainer';
import { FunctionalGroupWordsContext } from './FuncGroupContext';
import FunctionalGroupWordsContainer from './FuncGroupWordsContainer';

const FunctionalGroupWordsWrapper = () => {

  const [selectedVariant, setSelectedVariant] = useState(null);

  const setSelectedVariantByContext = (newVariant) => {
    setSelectedVariant(newVariant);
  };

  return (
    <FunctionalGroupWordsContext.Provider value={{ selectedVariant, setSelectedVariant: setSelectedVariantByContext }}>
      <FunctionalGroupContainer />
      <FunctionalGroupWordsContainer />
    </FunctionalGroupWordsContext.Provider>
  );
};

export default FunctionalGroupWordsWrapper;