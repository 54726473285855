import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useState } from "react";

const ApplicationDialog = ({ applicationDialog, setapplicationDialog, Decision, singleDetails }) => {

    return (
        <Transition show={applicationDialog} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-[9999]"
                onClose={() => setapplicationDialog(false)}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                <div className="flex justify-between items-center">
                                    <h6 className="font-semibold text-lg">
                                        Application Decision:
                                    </h6>
                                    <div
                                        onClick={() => setapplicationDialog(false)}
                                        className="cursor-pointer"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth={1.5}
                                            stroke="currentColor"
                                            className="w-6 h-6"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M6 18L18 6M6 6l12 12"
                                            />
                                        </svg>
                                    </div>
                                </div>
                                <div className="pt-3">
                                    <p className="text-sm my-2">
                                        The application has been processed as{" "}
                                        <b>{Decision?.ApplicationOutcome == "A" ? 'Approved' : (Decision?.ApplicationOutcome == "R" ? "Rejected" : "Request more info")}</b>
                                    </p>
                                    <div className="mt-3 relative mb-5">
                                        <p className="font-bold text-sm">
                                            Confirmation Email Sent:
                                            <div className="absolute right-10 top-1 bottom-0 bg-green-500 rounded-full w-8 h-8 flex justify-center items-center">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth={1.5}
                                                    stroke="currentColor"
                                                    className="w-6 h-6 text-white"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="m4.5 12.75 6 6 9-13.5"
                                                    />
                                                </svg>
                                            </div>
                                        </p>
                                    </div>


                                    <div className="mt-3 relative">
                                        <p className="font-bold text-sm">
                                            Company Details:
                                        </p>
                                        <div className="ml-10 mt-3 w-full">
                                            <p className="w-[90%]">
                                                Parent:{" "}
                                                <b>
                                                    {singleDetails?.ParentCompanyID} {singleDetails?.ParentCompanyName}
                                                </b>
                                            </p>
                                            <p className="w-[90%]">
                                                Company:{" "}
                                                <b>
                                                    {singleDetails?.CompanyID} {singleDetails?.CompanyName}
                                                </b>
                                            </p>
                                            <p className="w-[90%]">
                                                Country of Ownership Assigned:{" "}
                                                <b>{singleDetails?.ApplicationSuggestedCountryOfOrigin}</b>
                                            </p>
                                        </div>
                                        <div className="absolute top-10 right-10 bg-green-500 rounded-full w-8 h-8 flex justify-center items-center">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                strokeWidth={1.5}
                                                stroke="currentColor"
                                                className="w-6 h-6 text-white"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    d="m4.5 12.75 6 6 9-13.5"
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="mt-3 relative w-full">
                                        <p className="font-bold text-sm">
                                            User Details:
                                        </p>
                                        <div className="ml-10 mt-3 w-full">
                                            <p className="w-[90%]">
                                                User:{" "}
                                                <b>{singleDetails?.ApplicantUserID} {singleDetails?.NameFirst} {singleDetails?.NameSurname}</b>
                                            </p>
                                            <p className="w-[90%]">
                                                Parent:{" "}
                                                <b>
                                                    {Decision?.ApplicationOutcome == "R" ? 'N/A' : (singleDetails?.ParentCompanyID + " " + singleDetails?.ParentCompanyName)}
                                                </b>
                                            </p>
                                        </div>
                                        {
                                            Decision?.ApplicationOutcome == "A" ?
                                                <div className="absolute top-10 right-10 bg-green-500 rounded-full w-8 h-8 flex justify-center items-center">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        strokeWidth={1.5}
                                                        stroke="currentColor"
                                                        className="w-6 h-6 text-white"
                                                    >
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            d="m4.5 12.75 6 6 9-13.5"
                                                        />
                                                    </svg>
                                                </div> :
                                                <div className="absolute top-8 right-10 bg-red-500 rounded-full w-8 h-8 flex justify-center items-center">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        strokeWidth={1.5}
                                                        stroke="currentColor"
                                                        className="w-6 h-6 text-white"
                                                    >
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            d="M6 18 18 6M6 6l12 12"
                                                        />
                                                    </svg>
                                                </div>
                                        }
                                    </div>
                                </div>
                                <div className="flex items-center justify-center gap-2 mt-5">
                                    <button
                                        onClick={() => setapplicationDialog(false)}
                                        className="bg-gray-400 px-4 rounded py-1.5 text-white"
                                    >
                                        Close
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};

export default ApplicationDialog;
