import React, { Fragment } from "react";
import { Tab } from "@headlessui/react";

const SelectField = ({ label, optionsKey, optionsLabel, form, name, options, register, error, setFormField }) => (
    <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3">
        <h6 className="w-full max-w-[200px] text-right mt-2">{label} :</h6>
        <div className={'flex flex-col w-full gap-2'}>
            <select
                {...register(name)}
                id={name}
                name={name}
                onChange={(e) => setFormField(name, e.target.value)}
                className="rounded-md style font-bold w-full relative px-3 py-2 border shadow-sm sm:text-sm"
            >
                {options.map((option) => (
                    <option className="font-bold" key={option[optionsKey]} value={option[optionsKey]} selected={option[optionsKey] == form[name]}>
                        {option[optionsLabel]}
                    </option>
                ))}
            </select>
            {error && <p className="text-red-500 text-sm">{error.message}</p>}
        </div>
    </div>
);

const TextField = ({ label, name, placeholder, register, error, setFormField, form, setValue }) => {
    const isIndustryDisabled = form?.FK_MainIndustry != "1000153" && name == 'IndustryOther' ? true : false;
    if (isIndustryDisabled && form.OtherIndustry !== "") {
        setFormField('OtherIndustry', "")
        setValue(name, "");
    }
    return (
        <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3">
            <h6 className="w-full max-w-[200px] text-right">{label} :</h6>
            <div className={'flex flex-col w-full gap-2'}>
                <input
                    {...register(name)}
                    disabled={isIndustryDisabled}
                    type="text"
                    name={name}
                    onChange={(e) => setFormField(name, e.target.value)}
                    className="rounded-md font-bold w-full relative px-3 py-2 border shadow-sm sm:text-sm"
                    placeholder={placeholder}
                />
                {error && <p className="text-red-500 text-sm">{error.message}</p>}
            </div>
        </div>
    );
};


const FormField = ({ field, register, setFormField }) => (
    <Fragment key={field.label}>
        {React.createElement(field.component, { ...field, register, setFormField })}
    </Fragment>
);

const CompanyDetailTabs = ({ register, errors, countryOptions, setFormField, stateOptions, numberOfEmployeesOptions, annualTurnoverOptions, industriesOptions, getValues, form, setValue, setSelectedTab }) => {
    const tabPanelsData = [
        {
            label: 'Address Details',
            fields: [
                { component: TextField, label: 'Address 1', name: 'AddressStreet1', placeholder: 'Address 1', error: errors.AddressStreet1, setFormField, form, setValue },
                { component: TextField, label: 'Address 2', name: 'AddressStreet2', placeholder: 'Address 2', error: errors.AddressStreet2, setFormField, form, setValue },
                { component: TextField, label: 'City', name: 'AddressCity', placeholder: 'City', error: errors.AddressCity, setFormField, form, setValue },
                { component: SelectField, label: 'State', name: 'AddressState', options: stateOptions, optionsKey: 'StateCode', optionsLabel: 'StateName', register, error: errors.AddressState, setFormField, form },
                { component: TextField, label: 'Postcode', name: 'AddressPostCode', placeholder: 'Postcode', error: errors.AddressPostCode, setFormField, form, setValue },
                { component: SelectField, label: 'Country', name: 'AddressCountry', options: countryOptions, optionsKey: 'CountryCode', optionsLabel: 'CountryName', register, error: errors.AddressCountry, setFormField, form },
            ],
        },
        {
            label: 'Contact Details',
            fields: [
                { component: TextField, label: 'Company Phone', name: 'PhoneCompany', placeholder: 'Company Phone', error: errors.PhoneCompany, setFormField, form, setValue },
            ],
        },
        {
            label: 'Statistics',
            fields: [
                { component: SelectField, label: 'Number of Employees', name: 'FK_EmployeeCount', options: numberOfEmployeesOptions, optionsKey: 'SystemSelectID', optionsLabel: 'SelectionName', error: errors.FK_EmployeeCount, setFormField, form },
                { component: SelectField, label: 'Annual Turnover', name: 'FK_CompanyTurnover', options: annualTurnoverOptions, optionsKey: 'SystemSelectID', optionsLabel: 'SelectionName', error: errors.FK_CompanyTurnover, setFormField, form },
                { component: SelectField, label: 'Industry', name: 'FK_MainIndustry', options: industriesOptions, optionsKey: 'SystemSelectID', optionsLabel: 'SelectionName', register, error: errors.FK_MainIndustry, setFormField, form },
                { component: TextField, label: 'Other Industry', name: 'IndustryOther', placeholder: '', error: errors.IndustryOther, setFormField, form, setValue },
            ],
        },
        {
            label: 'Users',
            content: <div className="h-72 flex justify-center items-center"><h1 className="lg:text-4xl text-2xl">New Feature Coming Soon</h1></div>,
        },
    ];

    return (
        <Tab.Group>
            <Tab.List className="text-sm mt-2 w-full gap-3 lg:flex-nowrap md:flex-nowrap flex-wrap font-medium text-center text-gray-500 flex rounded bg-primary pt-3 px-2">
                {tabPanelsData.map((panel, index) => (
                    <Tab key={index} as={Fragment}>
                        {({ selected }) => (
                            <button
                                onClick={() => {
                                    setSelectedTab(panel.label)
                                }}
                                className={`${selected
                                    ? "bg-white text-black"
                                    : "text-white"
                                    } uppercase !overflow-visible relative rounded-t font-medium text-sm px-10 py-2`}
                            >
                                {panel.label}
                            </button>
                        )}
                    </Tab>
                ))}
            </Tab.List>
            <Tab.Panels>
                {tabPanelsData.map((panel, index) => (
                    <Tab.Panel key={index}>
                        {panel.fields ? (
                            <div className="mb-3 space-y-4 rounded-t-none rounded-b-xl shadow-md p-4 bg-white h-[calc(100vh-500px)] overflow-y-auto">
                                {panel.fields.map((field) => (
                                    <FormField key={field.label} field={field} register={register} setFormField={setFormField} getValues={getValues} />
                                ))}
                            </div>
                        ) : (
                            panel.content
                        )}
                    </Tab.Panel>
                ))}
            </Tab.Panels>
        </Tab.Group>
    );
};

export default CompanyDetailTabs;
