import { getToken } from "../provider/AuthProvider";
import { getAuthRequest } from "./API";

const getGroups = async (BrickID) => {
  try {
    return await getAuthRequest(getToken()).post('pwd/getGroups', {
      BrickID: BrickID
    });
  } catch (error) {
    return error;
  }
};

const getGroupVars = async (BrickID, Group) => {
  try {
    return await getAuthRequest(getToken()).post('pwd/getGroupVars', {
      BrickID: BrickID, Group: Group
    });
  } catch (error) {
    return error;
  }
};

const getGroupVarDetails = async (BrickID, Group, Variant) => {
  try {
    return await getAuthRequest(getToken()).post('pwd/getGroupVarDetails', {
      BrickID: BrickID, Group: Group, Variant: Variant
    });
  } catch (error) {
    return error;
  }
};

const getAssignedWords = async (BrickID, Group, Variant, Detail) => {
  try {
    return await getAuthRequest(getToken()).post('pwd/getAssignedWords', {
      BrickID: BrickID, Group: Group, Variant: Variant, Detail: Detail
    });
  } catch (error) {
    return error;
  }
};

const getUnassignedWords = async (BrickID, Keywords) => {
  try {
    return await getAuthRequest(getToken()).post('pwd/getUnassignedWords', {
      BrickID: BrickID, Keywords: Keywords
    });
  } catch (error) {
    return error;
  }
};

const assignGVDToWordMatch = async (BrickID, Group, Variant, Detail, VWMID) => {
  try {
    return await getAuthRequest(getToken()).post('pwd/assignGVDToWordMatch', {
      BrickID: BrickID, Group: Group, Variant: Variant, Detail: Detail, VWMID: VWMID
    });
  } catch (error) {
    return error;
  }
};

const newGVD = async (BrickID, Group, Variant, Detail) => {
  try {
    return await getAuthRequest(getToken()).post('pwd/newGVD', {
      BrickID: BrickID, Group: Group, Variant: Variant, Detail: Detail
    });
  } catch (error) {
    return error.response;
  }
};

const listProductsRelatedToWordMatch = async (VWMID) => {
  try {
    return await getAuthRequest(getToken()).post('pwd/listProductsRelatedToWordMatch', { VWMID });
  } catch (error) {
    return error.response;
  }
};

const listIgnoresSubstitutesForWordMatch = async (VWMID) => {
  try {
    return await getAuthRequest(getToken()).post('pwd/listIgnoresSubstitutesForWordMatch', { VWMID });
  } catch (error) {
    return error.response;
  }
};

const processIgnoresSubstitutes = async (VWMID, combinations) => {
  try {
    return await getAuthRequest(getToken()).post('pwd/processIgnoresSubstitutes', { VWMID, combinations });
  } catch (error) {
    return error.response;
  }
};

const getBrickDetails = async (BrickID) => {
  try {
    return await getAuthRequest(getToken()).get('search/brick/' + BrickID);
  } catch (error) {
    return error;
  }
};

export {
  getGroups,
  getGroupVars,
  getGroupVarDetails,
  getAssignedWords,
  getUnassignedWords,
  assignGVDToWordMatch,
  newGVD,
  listProductsRelatedToWordMatch,
  listIgnoresSubstitutesForWordMatch,
  processIgnoresSubstitutes,
  getBrickDetails
};