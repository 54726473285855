import {useEffect, useState} from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useNavigate } from "react-router-dom";
import { useAppSelector,useAppDispatch } from  "../../store/store";
import {orderSelector, addForm, fetchClientSecret} from "../../store/slices/getStartedSlice";
import GetStartedAccountPaymentComponent from "./GetStartedAccountPaymentComponent";
import {getCouponCode, updateCouponCode} from "../../api/SignUp";
import GetStartedTab from "./GetStartedTab";
import Dialog from "@mui/material/Dialog";
import Toolbar from "@mui/material/Toolbar";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);



const GetStartedAccountPayment = () => {

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(true);
  const [error, setError] = useState("");
  const [coupon, setCoupon] = useState("");
  const [isCoupon, setIsCoupon] = useState(false);
  const [isPaymentLoad, setIsPaymentLoad] = useState(false);

  const {
    form,
    annualTurnover,
    clientSecret
  } = useAppSelector(orderSelector);

  const setFormField = (key, value) => {
    let data = Object.assign({}, form);
    data[key] = value;
    dispatch(addForm(data));
  };

  const applyCoupon = async () => {
    setError("")
    const payment = ((annualTurnover || []).filter(i => i.SystemSelectID == form?.annual_turnover)?.[0] || {});
    let price = (payment?.SelectionPrice || 0);
    setFormField("coupon", {});
    if (!isCoupon) {
      const json = await getCouponCode(coupon || "~");
      if (json.status == 200) {

        const rCoupon = json.data?.couponInfo?.[0];

        if (rCoupon?.CouponID) {
          if (rCoupon?.CouponType == "Fixed" && rCoupon?.QuantityAvailable > rCoupon?.QuantityUsed) {
            updateCouponCode({CouponID:parseInt(rCoupon?.CouponID), QuantityUsed:parseInt(rCoupon?.QuantityUsed)+1});
            setFormField("coupon", json.data?.couponInfo?.[0]);
            price = (rCoupon.CouponValue * 1.1).toFixed(2);
            if (price) {
              await dispatch(fetchClientSecret({amount: price * 100}));
              setIsPaymentLoad(true)
            }
            setOpen(false);
          } else {
            setError("Coupon Code is invalid. Please try a different one.");
          }
        }
      } else {
        setError("Coupon Code is invalid. Please try a different one.")
      }
    } else {
      setOpen(false);
      if (price) {
        await dispatch(fetchClientSecret({ amount: price * 1.1 *100 }));
        setIsPaymentLoad(true)
      }
    }
  }

  return (
      <div
          className={`w-full h-full flex-grow overflow-auto py-10`}
      >
        {Object.keys(form).length > 0 &&
            <GetStartedTab />
        }
        {stripePromise && clientSecret && isPaymentLoad && (
            <Elements stripe={stripePromise} options={{ clientSecret }}>
              <GetStartedAccountPaymentComponent navigate={navigate} />
            </Elements>)}
        <Dialog
            fullWidth={true}
            maxWidth="xs"
            open={open}
            onClose={()=>{return false}}
            id="scrollbar"
        >
          <div className="shadow-none" sx={{ position: "relative" }}>
            <Toolbar className="flex items-center justify-center">
              <h3 className="black Medium text-xl">Do you have a coupon?</h3>
            </Toolbar>
          </div>
          <div className="p-5 flex justify-center items-center flex-col gap-5">
            <input
                type="text"
                onChange={(e)=>setCoupon(e.target.value)}
                value={coupon}
                placeholder="Enter Coupon Code"
                className="appearance-none rounded-md w-full relative px-3 py-2 border border-gray-800 placeholder-gray-700 focus:outline-none shadow-sm sm:text-sm"
            />
            <label
                htmlFor="couponcode"

                className="cursor-pointer inline-flex items-center text-[15px] font-normal"
            >
              <input
                  id="couponcode"
                  type="checkbox"
                  className={`hidden peer form-check-input`}
                  onChange={(e)=>setIsCoupon(e.target.checked)}
                  checked={isCoupon}
              />

              <span className="mr-3 peer-checked:bg-gray-800 bg-white h-[16px] w-[16px] before:content-[''] relative before:absolute before:bottom-[4.7px] before:left-[1.1px] before:h-[5px] before:w-[10px] before:border-b-2 before:border-l-2 before:-rotate-[45deg] before:border-white inline-block border-2 border-gray-800 rounded-sm"></span>
              I do not have a coupon
            </label>
            {!!error &&
                <p className="text-red-400">{error}</p>
            }
          </div>

          <div className="p-3 flex items-center justify-center gap-x-3">


            <button
                onClick={applyCoupon}
                className="w-44 py-2.5 fs-15 leading-5 hover:opacity-80 bg-[#00205b] text-white rounded-sm shadow-md gap-x-3 flex items-center justify-center"
            >
              Apply Coupon
            </button>
          </div>
        </Dialog>
      </div>
  );
};

export default GetStartedAccountPayment;
