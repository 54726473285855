import { Dialog, Listbox, Tab, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import ApplicationDialog from "../../components/ApplicationDialog";
import { useAppDispatch, useAppSelector } from "../../store/store";
import {
    signupApproval,
    fethchParentCompanyDetailsByID,
    fetchCountryOrigin,
    fetchHistoryDataByID,
    fetchChecksDropdown,
    fetchsaveHistoryData,
    fetchSaveSubmitData,
    fetchNumberOfEmployees,
    fetchAnnualTurnover,
    fetchIndustries,
    fetchState,
    sendEmailToUser,
} from "../../store/slices/signupApprovalSlice";
import AssignParentCompanyDialog from "../../components/Dialogs/AssignParentCompanyDialog";
import { useAuthContext } from "../../context/AuthContext";
import Loader from "../../components/loader/Loader";
import { sendemail } from "../../api/SignupApproval";

const Dropdown = ({ selected, setSelected, options, disabled }) => (
    <>
        {
            options.length > 0 ?
                <Listbox value={selected} onChange={setSelected} disabled={disabled}>
                    <div className="relative w-full">
                        <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-sm border">
                            <span className="block truncate font-bold">{selected?.SelectionName || selected?.CountryName || 'Select an option'}</span>
                        </Listbox.Button>
                        <Transition
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Listbox.Options className="absolute z-50 mt-1 max-h-60 w-full overflow-hidden rounded-md bg-white py-1 text-base shadow-lg sm:text-sm">
                                {options.map((option, index) => (
                                    <Listbox.Option
                                        key={index}
                                        className={({ active }) =>
                                            `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-gray-100 text-gray-900' : 'text-gray-900'
                                            }`
                                        }
                                        value={option}
                                    >
                                        {({ selected }) => (
                                            <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                                                {option?.SelectionName ?? option?.CountryName}
                                            </span>
                                        )}
                                    </Listbox.Option>
                                ))}
                            </Listbox.Options>
                        </Transition>
                    </div>
                </Listbox> :
                <Loader />
        }
    </>

);

const Textarea = ({ value, onChange, disabled }) => (
    <textarea
        name=""
        id=""
        disabled={disabled}
        value={value}
        onChange={(ev) => onChange(ev.target.value)}
        className="rounded-md w-full border py-1.5 px-2"
        cols="30"
        rows="5"
    ></textarea>
);

const RadioButton = ({ label, value }) => (
    <label className="flex py-3 items-center gap-2">
        <input type="radio" className="w-4 h-4" name="option" value={value} />
        <span>{label}</span>
    </label>
);

const SaveCancelButton = ({ type, handleSave, disabled, setCancelModal }) => {
    return (
        <div className="flex justify-center mt-5 items-center gap-4">
            <button
                // disabled={disabled}
                onClick={() => setCancelModal(true)}
                className="bg-gray-400 text-white px-5 py-2 rounded-lg"
            >
                Cancel
            </button>
            <button
                disabled={disabled}
                onClick={() => handleSave(type)}
                className={`${disabled ? "opacity-70 bg-primary" : "bg-primary"
                    } text-white px-5 py-2 rounded-lg`}
            >
                Save
            </button>
        </div>
    );
}


const ViewApplication = () => {
    const { SignupID } = useParams();
    const userAuth = new useAuthContext();
    const user = userAuth.user;
    const { singleDetails, CountryOrigin, history, options, numberOfEmployees, annualTurnover, industries, state } = useAppSelector(signupApproval);
    const dispatch = useAppDispatch();

    const [Checks, setChecks] = useState({
        type: 'Checks',
        FK_SignupID: SignupID,
        FK_UserID: parseInt(user?.UserID),
        Check: null,
        FK_SignupCheck: null,
        ResearchLink: null,
        SignupCheckTitle: null,
        SignupCheckText: null
    })
    const [RequestInfo, setRequestInfo] = useState({
        type: 'RequestInfo',
        FK_SignupID: SignupID,
        FK_UserID: parseInt(user?.UserID),
        SignupCheckTitle: null,
        SignupCheckText: null,
        ClearMoreInfo: false,
        Text: null,
        email: singleDetails?.Email
    })
    const [Ownership, setOwnership] = useState({
        type: 'Ownership',
        FK_SignupID: SignupID,
        FK_UserID: parseInt(user?.UserID),
        CountryOfOwnership: null,
        ApplicationSuggestedCountryOfOrigin: null,
        SignupCheckTitle: null,
        SignupCheckText: null
    })
    const [Submit, setSubmit] = useState({
        type: 'Submit',
        SignupID: SignupID,
        FK_SignupID: SignupID,
        FK_UserID: parseInt(user?.UserID),
        AuditUserID: parseInt(user?.UserID),
        ApplicationSubmittedRecommendation: singleDetails?.ApplicationSubmittedRecommendation ?? null,
        SignupCheckTitle: singleDetails?.ApplicationSubmittedRecommendation == "A" ? 'Approved' : 'Rejected',
        SignupCheckText: null
    })

    const [Decision, setDecision] = useState({
        type: 'Decision',
        FK_SignupID: SignupID,
        SignupID: SignupID,
        FK_UserID: parseInt(user?.UserID),
        AuditUserID: parseInt(user?.UserID),
        ApplicationOutcome: singleDetails?.ApplicationOutcome ?? null,
        SignupCheckTitle: singleDetails?.ApplicationOutcome == "A" ? 'Approved' : 'Rejected',
        SignupCheckText: null
    })

    const [ParentCompanyDialog, setParentCompanyDialog] = useState(false);
    const [cancelModal, setCancelModal] = useState(false);
    const [applicationDialog, setApplicationDialog] = useState(false);

    console.log('singleDetails', singleDetails)

    useEffect(() => {
        const fetchData = async () => {
            try {
                await Promise.all([
                    dispatch(fethchParentCompanyDetailsByID(SignupID)),
                    dispatch(fetchHistoryDataByID(parseInt(SignupID))),
                    dispatch(fetchCountryOrigin()),
                    dispatch(fetchChecksDropdown({ SystemCode: 'SIGNUP', SelectionCode: 'CHECK' })),
                    dispatch(fetchNumberOfEmployees()),
                    dispatch(fetchAnnualTurnover()),
                    dispatch(fetchIndustries())
                ]);
            } catch (error) {
                // Handle any errors here
                console.error('Error fetching data:', error);
            } finally {
                dispatch(fetchState(singleDetails?.ApplicationCountryCodeN3));
                singleDetails?.ApplicationSuggestedCountryOfOrigin && setOwnership((previous) => ({
                    ...previous,
                    CountryOfOwnership: CountryOrigin.find(o => o.CountryCodeN3 === singleDetails?.ApplicationSuggestedCountryOfOrigin) ||
                    {
                        "CountryCodeN3": "036",
                        "FK_ContinentCodeAN2": "OC",
                        "CountryCodeAN2": "AU",
                        "CountryCodeAN3": "AUS",
                        "CountryName": "AUSTRALIA",
                        "CountryNameDisplay": "Australia"
                    },
                }));
            }
        };

        fetchData();
    }, [dispatch, SignupID]);

    const convertUnixTimestampToDateString = (timestamp) => {
        const date = new Date(timestamp * 1000); // Convert seconds to milliseconds
        const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', timeZoneName: 'short' };
        return date.toLocaleDateString(undefined, options);
    };

    // console.log('CountryOrigin', CountryOrigin.find((o) => o.CountryCodeN3 == singleDetails?.ApplicationCountryCodeN3).CountryNameDisplay)

    const tabPanelData = [
        {
            label: "Details",
            fields: [
                // Contact Details
                { label: "First Name:", value: singleDetails?.NameFirst },
                { label: "Last Name:", value: singleDetails?.NameSurname },
                { label: "Preferred Name:", value: singleDetails?.NamePreferred },
                { label: "Position:", value: singleDetails?.Position },
                { label: "Direct Landline Number:", value: singleDetails?.PhoneDirect },
                { label: "Mobile Number:", value: singleDetails?.PhoneMobile },
                // Company Details
                { label: "Company Name:", value: singleDetails?.ApplicationCompanyName },
                { label: "Trading Name:", value: singleDetails?.ApplicationTradingName },
                { label: "ABN:", value: singleDetails?.ApplicationCompanyABN },
                // { label: "Number of Employees:", value: numberOfEmployees && numberOfEmployees.find((o) => o.SystemSelectID == singleDetails?.EmployeeCount).SelectionName },
                {
                    label: "Number of Employees:", value: singleDetails?.EmployeeCount && numberOfEmployees
                        ? numberOfEmployees.find((o) => o.SystemSelectID == singleDetails?.EmployeeCount)?.SelectionName || ""
                        : ""
                },
                { label: "Account Type:", value: "Supplier" },
                {
                    label: "Annual Turnover:", value: singleDetails?.CompanyTurnover && annualTurnover
                        ? annualTurnover.find((o) => o.SystemSelectID == singleDetails?.CompanyTurnover)?.SelectionName || ""
                        : ""
                },
                {
                    label: "Industry:", value: singleDetails?.FK_MainIndustry && industries
                        ? industries.find((o) => o.SystemSelectID == singleDetails?.FK_MainIndustry)?.SelectionName || ""
                        : ""
                },
                { label: "Phone:", value: singleDetails?.ApplicationPhoneCompany },
                { label: "Website:", value: singleDetails?.ApplicationWebsite },
                // Company Address Details
                { label: "Address 1:", value: singleDetails?.ApplicationAddress1 },
                { label: "Address 2:", value: singleDetails?.ApplicationAddress2 },
                { label: "City:", value: singleDetails?.ApplicationCity },
                {
                    label: "State:",
                    value: Array.isArray(state) && state.length > 0 && singleDetails?.ApplicationState
                        ? (state.find((o) => o.StateCode == singleDetails?.ApplicationState) || {}).StateName || ""
                        : singleDetails?.ApplicationState || ""

                },
                { label: "PostCode:", value: singleDetails?.ApplicationPostcode },
                {
                    label: "Country:", value: singleDetails?.ApplicationCountryCodeN3 && CountryOrigin.length > 5
                        ? (CountryOrigin.find((o) => o.CountryCodeN3 == singleDetails?.ApplicationCountryCodeN3)).CountryNameDisplay || ""
                        : ""
                },

            ],
        },
        {
            label: "Prefix",
            fields: [
                { label: "GS1 Entity GLN:", value: singleDetails?.EntityGLN },
                { label: "GS1 Company Name:", value: singleDetails?.CompanyName },
                { label: "Claimed Prefix:", value: singleDetails?.Prefix },
                { label: "Claimed GTIN (Barcode):", value: singleDetails?.ClaimedGTIN },
            ],
        },
        {
            label: "Payment",
            fields: [
                { label: "Coupon ID:", value: singleDetails?.CouponID },
                { label: "Coupon Code:", value: singleDetails?.CouponCode },
                { label: "Coupon Name:", value: singleDetails?.CouponName },
                { label: "Stripe Transaction Id:", value: singleDetails?.StripePaymentID },
                {
                    label: "Stripe Transaction Date:", value: singleDetails?.stripeTransactionDate && convertUnixTimestampToDateString(singleDetails?.stripeTransactionDate)
                },
                {
                    label: "Stripe Transaction Amount:",
                    value: `${singleDetails?.stripeTransactionAmount ? `$${(singleDetails?.stripeTransactionAmount / 100.00).toFixed(2)} (${singleDetails?.stripeCurrency.toUpperCase()}) Inc GST` : ''}`
                },
                { label: "Stripe URL:", value: `${singleDetails?.StripePaymentID ? `${process.env.REACT_APP_STRIPE_DASHBOARD_URL}${singleDetails?.StripePaymentID}` : ''}` },
                { label: "User Email:", value: singleDetails?.Email },
                { label: "Full Name:", value: `${singleDetails?.NameFirst} ${singleDetails?.NameSurname}` },
                {
                    label: "Country or region:",
                    value: CountryOrigin.length > 0
                        ? CountryOrigin
                            .filter((o) => o.CountryCodeN3 === singleDetails?.ApplicationCountryCodeN3)
                            .map((filteredCountry) => filteredCountry.CountryName)
                        : ""
                },
                { label: "Address:", value: `${singleDetails?.ApplicationAddress1} ${singleDetails?.ApplicationAddress2}  ${singleDetails?.ApplicationState}  ${singleDetails?.ApplicationCity}` },
            ],
        },
    ];

    const tabPanelAction = [
        {
            label: "Checks",
        },
        {
            label: "Request Info",
        },
        {
            label: "Ownership",
        },
        {
            label: "Submit",
        },
        {
            label: "Decision",
        }
    ];

    const handleSave = (data) => {
        let payload = null;

        if (data === 'Check') {
            payload = dispatch(fetchsaveHistoryData(Checks));
        }

        if (data === 'RequestInfo') {
            if (singleDetails?.Email) {
                dispatch(sendEmailToUser({
                    To: singleDetails?.Email,
                    Subject: RequestInfo?.Text,
                    EmailBody: RequestInfo?.SignupCheckText
                }));
            }

            setTimeout(() => {
                payload = dispatch(fetchsaveHistoryData(RequestInfo));
            });
        }

        if (data === 'Ownership') {
            dispatch(fetchSaveSubmitData({
                ApplicationSuggestedCountryOfOrigin: parseInt(Ownership?.ApplicationSuggestedCountryOfOrigin),
                FK_SignupID: Ownership.FK_SignupID
            }))
            payload = dispatch(fetchsaveHistoryData(Ownership));
        }

        if (data === 'Submit') {
            dispatch(fetchSaveSubmitData(Submit))
            payload = dispatch(fetchsaveHistoryData(Submit));
        }

        if (data === 'Decision') {
            dispatch(fetchSaveSubmitData(Decision))
            payload = dispatch(fetchsaveHistoryData(Decision));
        }

        setTimeout(() => {
            // payload.then(res => {
            //     if (res?.SignupHistoryID) {
            //         dispatch(fetchHistoryData({ SignupHistoryID: parseInt(res?.SignupHistoryID) }));
            //     } else if (data === 'Decision') {
            //         dispatch(fetchHistoryData(Decision));
            //     }
            // })
            dispatch(fetchHistoryDataByID(SignupID))
        });
    };

    const renderDataField = (label, value) => (
        value && (
            <p className="text-sm">
                {label !== 'date' && label + ":"} {<span className="font-bold">{value}</span>}
            </p>
        )
    );

    const isDecisionSubmitted = singleDetails?.ApplicationOutcome !== null;

    return (
        <>
            <div className="w-full mx-auto">
                <div className="space-y-4 grid grid-cols-12 gap-5">
                    <div className="flex flex-col lg:col-span-8 col-span-12">
                        <div>
                            <Tab.Group>
                                <div className="flex justify-between items-center mt-2 rounded bg-primary pt-3 px-2">
                                    <Tab.List className="space-x-1">
                                        {tabPanelData.map((data, index) => (
                                            <Tab key={index} as={Fragment}>
                                                {({ selected }) => (
                                                    <button
                                                        className={`${selected ? "bg-white border-transparent pb-2 pt-4 text-black" : " py-2 text-white border-white border-t border-x"
                                                            } overflow-visible relative font-medium outline-none text-sm rounded-t px-10`}
                                                    >
                                                        {data.label}
                                                    </button>
                                                )}
                                            </Tab>
                                        ))}
                                    </Tab.List>
                                </div>
                                <Tab.Panels className="rounded-b-xl shadow-md p-5 bg-white h-[calc(100vh-450px)] overflow-y-auto">
                                    {tabPanelData.map((panel, panelIndex) => (
                                        <>
                                            <Tab.Panel key={panelIndex}>
                                                <div className="space-y-1">
                                                    {panel.fields.map((field, fieldIndex) => (
                                                        <>
                                                            <div
                                                                key={fieldIndex}
                                                                className={`${field.label === "Coupon Name:" && "border-b border-black pb-3"
                                                                    } text-sm flex gap-3`}
                                                            >
                                                                <p className="text-end capitalize w-full max-w-[200px] mr-1">
                                                                    {field.label}
                                                                </p>
                                                                {field.label === "Stripe URL:" ?
                                                                    <a className="font-bold underline" target="_blank" href={field.value}>{field.value}</a>
                                                                    :
                                                                    <span className="font-bold">{field.value}</span>
                                                                }
                                                            </div>
                                                        </>
                                                    ))}
                                                </div>
                                            </Tab.Panel>
                                        </>
                                    ))}
                                </Tab.Panels>
                            </Tab.Group>

                        </div>
                        <div>
                            <Tab.Group>
                                <div className="flex justify-between items-center mt-2 rounded bg-primary pt-3 px-2">
                                    <Tab.List className="space-x-1">
                                        {tabPanelAction.map((data, index) => (
                                            <Tab key={index} as={Fragment}>
                                                {({ selected }) => (
                                                    <button
                                                        className={`${selected ? "bg-white border-transparent pb-2 pt-4 text-black" : " py-2 text-white border-white border-t border-x"
                                                            } overflow-visible relative font-medium outline-none text-sm rounded-t px-10`}
                                                    >
                                                        {data.label}
                                                    </button>
                                                )}
                                            </Tab>
                                        ))}
                                    </Tab.List>
                                </div>
                                <Tab.Panels
                                    className={
                                        "rounded-b-xl shadow-md p-5 bg-white"
                                    }
                                >
                                    <Tab.Panel>
                                        <div className="space-y-4">
                                            <div className="text-sm items-center flex gap-3">
                                                <p className="text-end capitalize w-full max-w-[200px]">
                                                    Checks:
                                                </p>
                                                <Dropdown
                                                    selected={Checks?.Check}
                                                    disabled={isDecisionSubmitted}
                                                    setSelected={(newValue) =>
                                                        setChecks((prevChecks) => ({
                                                            ...prevChecks,
                                                            Check: newValue,
                                                            FK_SignupCheck: newValue?.SystemSelectID,
                                                            SignupCheckTitle: 'Checks: ' + newValue?.SelectionName
                                                        }))
                                                    }
                                                    options={options}
                                                />
                                            </div>
                                            <div className="text-sm items-center flex gap-3">
                                                <p className="text-end capitalize w-full max-w-[200px]">
                                                    Research Link:
                                                </p>
                                                <input
                                                    type="text"
                                                    value={Checks.ResearchLink}
                                                    className="rounded-md w-full border py-1.5 px-2"
                                                    disabled={isDecisionSubmitted}
                                                    onChange={(event) =>
                                                        setChecks((prevChecks) => ({
                                                            ...prevChecks,
                                                            ResearchLink: event.target.value
                                                        }))}
                                                />
                                            </div>
                                            <div className="text-sm items-start flex gap-3">
                                                <p className="text-end capitalize w-full max-w-[200px]">
                                                    Comments:
                                                </p>
                                                <Textarea
                                                    value={Checks.SignupCheckText}
                                                    disabled={isDecisionSubmitted}
                                                    onChange={(newValue) =>
                                                        setChecks((prevChecks) => ({
                                                            ...prevChecks,
                                                            SignupCheckText: newValue
                                                        }))
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <SaveCancelButton setCancelModal={setCancelModal} type={'Check'} handleSave={(value) => handleSave(value)} disabled={isDecisionSubmitted} />
                                    </Tab.Panel>
                                    <Tab.Panel>
                                        <div className="space-y-4">
                                            <div className="text-sm items-center flex gap-3">
                                                <p className="text-end capitalize w-full max-w-[200px]">
                                                    To:
                                                </p>
                                                <span className="font-bold capitalize">
                                                    {singleDetails?.Email}
                                                </span>
                                            </div>
                                            <div className="text-sm items-center flex gap-3">
                                                <p className="text-end capitalize w-full max-w-[200px]">
                                                    Subject:
                                                </p>
                                                <input
                                                    type="text"
                                                    placeholder="Requesting more info about your application"
                                                    value={RequestInfo.Text}
                                                    disabled={isDecisionSubmitted}
                                                    onChange={(newValue) =>
                                                        setRequestInfo((prevChecks) => ({
                                                            ...prevChecks,
                                                            SignupCheckTitle: 'Email:' + newValue.target.value,
                                                            Text: newValue.target.value
                                                        }))
                                                    }
                                                    className="rounded-md w-full border py-1.5 px-2"
                                                />
                                            </div>
                                            <div className="text-sm items-start flex gap-3">
                                                <p className="text-end capitalize w-full max-w-[200px]">
                                                    Message:
                                                </p>
                                                <Textarea
                                                    value={RequestInfo.SignupCheckText}
                                                    disabled={isDecisionSubmitted}
                                                    onChange={(newValue) =>
                                                        setRequestInfo((prevChecks) => ({
                                                            ...prevChecks,
                                                            SignupCheckText: newValue
                                                        }))
                                                    }
                                                />
                                            </div>
                                            {
                                                singleDetails.MoreInfoCount > 0 &&
                                                <div className="text-sm items-start flex gap-3">
                                                    <p className="text-end capitalize w-full max-w-[200px]">
                                                        Clear more info:
                                                    </p>
                                                    <input
                                                        type="checkbox"
                                                        checked={RequestInfo.ClearMoreInfo}
                                                        disabled={isDecisionSubmitted}
                                                        className="rounded-md  border py-1.5"
                                                        onChange={(e) =>
                                                            setRequestInfo((prevChecks) => ({
                                                                ...prevChecks,
                                                                ClearMoreInfo: e.target.checked
                                                            }))
                                                        }
                                                    />
                                                </div>
                                            }

                                        </div>
                                        <SaveCancelButton setCancelModal={setCancelModal} type={'RequestInfo'} handleSave={(value) => handleSave(value)} disabled={isDecisionSubmitted} />
                                    </Tab.Panel>
                                    <Tab.Panel>
                                        <div className="space-y-4">
                                            <div className="text-sm items-center flex gap-3">
                                                <p className="text-end capitalize w-full max-w-[200px]">
                                                    Country of Ownership:
                                                </p>
                                                <Dropdown selected={Ownership.CountryOfOwnership} disabled={isDecisionSubmitted}
                                                    setSelected={(newValue) =>
                                                        setOwnership((prevChecks) => ({
                                                            ...prevChecks,
                                                            CountryOfOwnership: newValue,
                                                            SignupCheckTitle: 'Ownership:' + newValue.CountryName,
                                                            ApplicationSuggestedCountryOfOrigin: newValue.CountryCodeN3
                                                        }))
                                                    } options={CountryOrigin} />

                                            </div>
                                            <div className="text-sm items-start flex gap-3">
                                                <p className="text-end capitalize w-full max-w-[200px]">
                                                    Comments:
                                                </p>
                                                <Textarea
                                                    value={Ownership.SignupCheckText}
                                                    disabled={isDecisionSubmitted}
                                                    onChange={(newValue) =>
                                                        setOwnership((prevChecks) => ({
                                                            ...prevChecks,
                                                            SignupCheckText: newValue
                                                        }))
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <SaveCancelButton setCancelModal={setCancelModal} type={'Ownership'} handleSave={(value) => handleSave(value)} disabled={isDecisionSubmitted} />
                                    </Tab.Panel>
                                    <Tab.Panel>
                                        <div className="space-y-2">
                                            <p className="text-sm">
                                                I am recommending this application
                                                to be:
                                            </p>
                                            <div className="text-sm items-start flex gap-3">
                                                <p className="text-end mt-11 capitalize w-full max-w-[200px]">
                                                    Comments:
                                                </p>
                                                <div className="w-full">
                                                    <div className="flex items-center gap-4">
                                                        <label className="flex py-3 items-center gap-2">
                                                            <input
                                                                type="radio"
                                                                className="w-4 h-4"
                                                                disabled={isDecisionSubmitted}
                                                                name="SubmitOption"
                                                                checked={Submit.ApplicationSubmittedRecommendation === "A"}
                                                                onChange={(newValue) =>
                                                                    setSubmit((prevChecks) => ({
                                                                        ...prevChecks,
                                                                        ApplicationSubmittedRecommendation: newValue.target.value,
                                                                        SignupCheckTitle: 'Submitted for Decision as APPROVE'
                                                                    }))
                                                                }
                                                                value={"A"}
                                                            />
                                                            <span>Approved</span>
                                                        </label>
                                                        <label className="flex py-3 items-center gap-2">
                                                            <input
                                                                type="radio"
                                                                className="w-4 h-4"
                                                                disabled={isDecisionSubmitted}
                                                                name="SubmitOption"
                                                                checked={Submit.ApplicationSubmittedRecommendation === "R"}
                                                                onChange={(newValue) =>
                                                                    setSubmit((prevChecks) => ({
                                                                        ...prevChecks,
                                                                        ApplicationSubmittedRecommendation: newValue.target.value,
                                                                        SignupCheckTitle: ' Action: Submitted for Decision as REJECT'
                                                                    }))
                                                                }
                                                                value={"R"}
                                                            />
                                                            <span>Rejected</span>
                                                        </label>
                                                    </div>
                                                    <Textarea
                                                        value={Submit.SignupCheckText}
                                                        disabled={isDecisionSubmitted}
                                                        onChange={(newValue) =>
                                                            setSubmit((prevChecks) => ({
                                                                ...prevChecks,
                                                                SignupCheckText: newValue
                                                            }))
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <SaveCancelButton setCancelModal={setCancelModal} type={'Submit'} handleSave={(value) => handleSave(value)} disabled={isDecisionSubmitted} />
                                    </Tab.Panel>
                                    <Tab.Panel>
                                        <div className="space-y-2">
                                            <div className="flex flex-col">
                                                <p className="text-end text-sm capitalize w-full max-w-[100px]">
                                                    Decision:
                                                </p>
                                                <div className="flex ml-28 items-center gap-4">
                                                    <label className="flex py-3 items-center gap-2">
                                                        <input
                                                            type="radio"
                                                            className="w-4 h-4"
                                                            id="approveOption"
                                                            value={"A"}
                                                            disabled={isDecisionSubmitted}
                                                            checked={Decision.ApplicationOutcome === "A"}
                                                            onChange={(event) =>
                                                                setDecision((prevChecks) => ({
                                                                    ...prevChecks,
                                                                    ApplicationOutcome: event.target.value,
                                                                    SignupCheckTitle: 'Decision: Approved'
                                                                }))
                                                            }
                                                        />
                                                        <span>Approve</span>
                                                    </label>
                                                    <label className="flex py-3 items-center gap-2">
                                                        <input
                                                            type="radio"
                                                            className="w-4 h-4"
                                                            id="rejectOption"
                                                            value={"R"}
                                                            disabled={isDecisionSubmitted}
                                                            checked={Decision.ApplicationOutcome === "R"}
                                                            onChange={(event) =>
                                                                setDecision((prevChecks) => ({
                                                                    ...prevChecks,
                                                                    ApplicationOutcome: event.target.value,
                                                                    SignupCheckTitle: 'Decision: Rejected'
                                                                }))
                                                            }
                                                        />
                                                        <span>Reject</span>
                                                    </label>
                                                    <label className="flex py-3 items-center gap-2">
                                                        <input
                                                            type="radio"
                                                            className="w-4 h-4"
                                                            id="requestInfoOption"
                                                            value={"2"}
                                                            disabled={isDecisionSubmitted}
                                                            checked={Decision.ApplicationOutcome === "2"}
                                                            onChange={(event) =>
                                                                setDecision((prevChecks) => ({
                                                                    ...prevChecks,
                                                                    ApplicationOutcome: "2",
                                                                    SignupCheckTitle: 'Decision: Request more info'
                                                                }))
                                                            }
                                                        />
                                                        <span>Request more info</span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="text-sm items-start flex gap-3">
                                                <p className="text-end capitalize w-full max-w-[100px]">Comments:</p>
                                                <div className="flex flex-col grow">
                                                    <Textarea
                                                        value={Decision.SignupCheckText}
                                                        disabled={isDecisionSubmitted}
                                                        onChange={(newValue) =>
                                                            setDecision((prevChecks) => ({
                                                                ...prevChecks,
                                                                SignupCheckText: newValue,
                                                            }))
                                                        }
                                                    />
                                                    <p className="text-red-500 text-sm">Enter a comment for your decision</p>
                                                </div>
                                            </div>
                                        </div>
                                        <SaveCancelButton setCancelModal={setCancelModal} type={true} handleSave={(value) => Decision.ApplicationOutcome == '2' ? handleSave('Decision') : setParentCompanyDialog(value)} disabled={isDecisionSubmitted} />
                                    </Tab.Panel>
                                </Tab.Panels>
                            </Tab.Group>
                        </div>
                    </div>
                    <div className="lg:col-span-4 col-span-12">
                        <h1 className="text-xl font-semibold">History</h1>
                        <div className="border h-[calc(100vh-82px)] overflow-y-auto flex-col bg-white mt-3 flex rounded border-black p-3">
                            {history.length > 0 &&
                                history.slice().reverse().map((data, index) => (
                                    <div key={index} className="flex flex-col border-b border-dashed py-2 border-black">
                                        {renderDataField('date', new Intl.DateTimeFormat('en-US', {
                                            weekday: 'long',
                                            year: 'numeric',
                                            month: 'long',
                                            day: 'numeric',
                                            hour: 'numeric',
                                            minute: 'numeric',
                                            hour12: true
                                        }).format(new Date(data?.DateCreated)))}
                                        {renderDataField('Action', data?.SignupCheckTitle)}
                                        {renderDataField('Actioned By', data?.ActionedBy)}
                                        {renderDataField('Research URL', data?.ResearchLink)}
                                        {renderDataField('Comments', data?.SignupCheckText)}
                                        <div className="divide-y"></div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
            <ApplicationDialog applicationDialog={applicationDialog} singleDetails={singleDetails} setapplicationDialog={setApplicationDialog} Decision={Decision} sethandleSave={() => handleSave('Decision')} />
            <AssignParentCompanyDialog CountryOrigin={CountryOrigin} parentCompany={singleDetails} assignParentCompanyDialog={ParentCompanyDialog} Decision={Decision} setAssignParentCompanyDialog={setParentCompanyDialog} handleSave={() => handleSave('Decision')} sethandleSave={() => {
                handleSave('Decision')
                setParentCompanyDialog(false)
                setApplicationDialog(true)
            }} />
            <Transition show={cancelModal} as={Fragment}>
                <Dialog
                    as="div"
                    className="relative z-[9999]"
                    onClose={() => setCancelModal(false)}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                    <div className="flex justify-between items-center">
                                        <h6 className="font-semibold text-lg">
                                            Your data is not saved
                                        </h6>
                                        <div
                                            onClick={() => setCancelModal(false)}
                                            className="cursor-pointer"
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                strokeWidth={1.5}
                                                stroke="currentColor"
                                                className="w-6 h-6"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    d="M6 18L18 6M6 6l12 12"
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    );
};

export default ViewApplication;
