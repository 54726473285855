import React from "react";

const Footer = ({ login,toggle }) => {
    if (!login) {
        return (
            <div className="p-5 bg-primary fixed bottom-0 w-full">
                <p className="text-white">Powered by ProductHub</p>
            </div>
        );
    }

    return (
        <div className={`p-5 bg-primary transition-all duration-200 ease-in float-right w-full`}>
            <p className="text-white">Powered by ProductHub</p>
        </div>
    );
};

export default Footer;
