import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../rootReducer";
import { listProducts } from "../../pages/SupplierProductsPage/API";
import defaultFilterValue from "../../pages/SupplierProductsPage/productGridFilters";

export interface ProductsPageState {
  active: any;
  limit: any;
  page: any;
  filterValues:any;
  dataSource: any;
  loading: boolean;
  errorMsg: object;
}

export const initialState: ProductsPageState = {
  active: 1,
  limit: 10,
  page:1,
  loading: false,
  errorMsg: null,
  filterValues: [
    {name: "CompanyName",type: "string",operator: "contains", value: ""},
    {name: "Brand", type: "string",operator: "contains",value: ""},
    {name: "BrandSub", type: "string",operator: "contains",value: ""},
    {name: "Description", type: "string",operator: "contains",value: ""},
    {name: "GTIN",  type: "string",operator: "eq",value:""},
    {name: "RecordType", type: "string",operator: "eq",value: "GS1"}
  ],
  dataSource: {}
};

export const productsPageSlice = createSlice({
  name: "productsPage",
  initialState,
  reducers: {
    setErrorMsg: (state: ProductsPageState, { payload }: PayloadAction<any>) => {
      state.errorMsg = payload;
    },
    setLoading: (state: ProductsPageState, { payload }: PayloadAction<any>) => {
      state.loading = payload;
    },
    setPage: (state: ProductsPageState, { payload }: PayloadAction<any>) => {
      state.page = payload;
    },
    setLimit: (state: ProductsPageState, { payload }: PayloadAction<any>) => {
      state.limit = payload;
    },
    setDataSource: (state: ProductsPageState, { payload }: PayloadAction<any>) => {
      state.dataSource = payload;
    },
    setFilterValues: (state: ProductsPageState, { payload }: PayloadAction<any>) => {
      let updatedFilterValues = payload.map((filter) => {
        if (filter?.name === 'RecordType') {
          switch (state.active) {
            case 1:
              return { ...filter, value: 'GS1' };
            case 2:
              return { ...filter, value: 'CAT' };
            default:
              return { ...filter, value: 'NON' };
          }
        }
        return filter;
      });
      state.filterValues = updatedFilterValues
    },
    setActive: (state: ProductsPageState, { payload }: PayloadAction<any>) => {
      let updatedFilterValues = state.filterValues.map((filter) => {
        switch (filter?.name) {
          case 'RecordType':
            switch (payload) {
              case 1:
                return { ...filter, value: 'GS1' };
              case 2:
                return { ...filter, value: 'CAT' };
              default:
                return { ...filter, value: 'NON' };
            }
            // Reset values of other filters to their default state
     /*     case 'CompanyName':
          case 'Brand':
          case 'BrandSub':
          case 'TradeItemDesc':
          case 'GTIN':
          return { ...filter, value: "" };*/
          default:
            return filter;
        }
      });
      state.page = 1;
      state.filterValues = updatedFilterValues;
      state.active = payload;
    },
  },
});

/*export const fetchData = (skip, limit, sortInfo, active, filterValue) => async (dispatch: any) => {
  const json = await listProducts(
    skip,
    limit,
    sortInfo,
    active,
    filterValue
);
  return json;
};*/

export const fetchData = (ParentCompanyID,filter,page,limit) => async (dispatch: any) => {
  const json = await listProducts(ParentCompanyID,filter,page,limit);
  return json;
};

export const {
  setPage,
  setLimit,
  setActive,
  setFilterValues,
  setLoading,
  setErrorMsg,
  setDataSource
} = productsPageSlice.actions;

export const productsPage = (state: RootState) => state.productsPage;

export default productsPageSlice.reducer;
