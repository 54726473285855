import React, { useContext, useEffect, useState } from 'react';
import TreeItemComp from './TreeItemComp';
import FuncGroupVarDetailContainer from './FuncGroupVarDetailContainer';
import { SelectedBrickContext } from './SelectedBrickContext';
import { getGroupVars } from '../../../api/GroupVariantDetail';

const FunctionalGroupVarContainer = ({funcGroup}) => {

  const [variants, setVariants] = useState([]);

  const [funcGroupState] = useState(funcGroup);

  const { selectedBrick } = useContext(SelectedBrickContext);

  useEffect(function () {
    fetchVariants();
  }, [funcGroupState]);

  const fetchVariants = async () => {
    const reqVariants = await getGroupVars(selectedBrick, funcGroupState.Group);
    setVariants(reqVariants !== false ? reqVariants.data : []);
  };

  const rowEven = {
    backgroundColor: '#d0dcd1'
  };

  const rowOdd = {
    backgroundColor: '#f2f2f2'
  };

  return (
    variants.map((item, key) => (
      <TreeItemComp
        key={key}
        style={(key % 2 == 0 ? rowEven : rowOdd)}
        itemName={item.Variant}
        itemData={item}>
          <FuncGroupVarDetailContainer group={funcGroupState} variant={item} />
          
      </TreeItemComp>
    ))
  );
};
export default FunctionalGroupVarContainer;