import { createContext, useContext, useState } from 'react';
import {
  isLoggedIn,
  getUser,
  setUser as setUserProvider,
  getToken,
  setToken as setTokenProvider,
  logoutUser as logoutUserProvider,
  setUserSignupInfo as setUserSignupInfoProvider,
  getUserSignupInfo,
  setParentCompanies as setParentCompaniesProvider,
  getParentCompanies

} from '../provider/AuthProvider';
import { useNavigate } from 'react-router-dom';

export const AuthContext = createContext();

export function useAuthContext() {
  return useContext(AuthContext);
}

export const useAuthState = () => {
  const [isUserLoggedIn, setIsLoggedIn] = useState(isLoggedIn());

  const [user, setUserState] = useState(getUser());

  const [signupInfo, setSignupInfo] = useState(getUserSignupInfo());

  const [token, setTokenState] = useState(getToken());

  const [parentCompanies, setParentCompanies] = useState(getParentCompanies());

  const navigate = useNavigate();

  const logoutUser = () => {
    logoutUserProvider();
    setIsLoggedIn(false);
    setUserState(null);
    setSignupInfo(null);
    setTokenState(null);
    setParentCompanies(null);
    window.location.href = '/';
  };
  const setUser = (user) => {
    setUserProvider(user);
    setUserState(user);
  };

  const setSignupInfoInformation = (user) => {
    setUserSignupInfoProvider(user);
    setSignupInfo(user);
  };

  const setParentCompaniesInformation = (user) => {
    setParentCompaniesProvider(user);
    setParentCompanies(user);
  };

  const setToken = (token) => {
    setTokenProvider(token);
    setTokenState(token);
    setIsLoggedIn(true);
  };

  const isUserApp = () => {
    return isUserLoggedIn && user.hasSignedUp && user.isAppUser && user.isApprovalBeingProcessed;
  };

  const isUserSupplier = () => {
    return isUserLoggedIn && user.hasSignedUp && user.isSupplier && user.isApprovalBeingProcessed;
  };

  const isUserRetailer = () => {
    return isUserLoggedIn && user.hasSignedUp && user.isRetailer && user.isApprovalBeingProcessed;
  };

  const isUserServiceProvider = () => {
    return isUserLoggedIn && user.hasSignedUp && user.isServiceProvider && user.isApprovalBeingProcessed;
  };

  const isEmployee = () => {
    return isUserLoggedIn && user.isEmployee;
  };

  const newUser = () => {
    return isUserLoggedIn && !user.isEmployee && !user.isSupplier && !user.isRetailer && !user.isAdmin && !user.isServiceProvider && !user.isUserApp;
  };

  return { isUserLoggedIn, isUserSupplier, isEmployee, isUserRetailer, isUserServiceProvider, user, setUser, token, setToken, logoutUser, setSignupInfoInformation, signupInfo, parentCompanies, setParentCompaniesInformation, newUser };
};