import React, { useContext, useEffect, useState } from 'react';
import GVDGroupItem from './GVDGroupItem';
import { BRICKFiltersContext } from '../contexts/BRICKFiltersContext';
import { listGroups } from '../../../api/SupplierProducts';
import { HTTP_STATUS_CODES } from '../../../api/API';

const WrapperGVD = () => {
  const { BRICKFilters, areFiltersEmpty, hideLoading, showLoading } = useContext(BRICKFiltersContext);

  const [groups, setGroups] = useState([]);

  useEffect(() => {
    fetchGroups();
  }, [BRICKFilters]);

  const fetchGroups = async () => {
    if (areFiltersEmpty())
      return;

    showLoading();
    setGroups([]);
    const res = await listGroups(BRICKFilters.SEGMENT, BRICKFilters.FAMILY, BRICKFilters.CLASS, BRICKFilters.BRICK);
    hideLoading();

    if (res.status != HTTP_STATUS_CODES.OK) return false;

    setGroups(res.data);
  };

  if (areFiltersEmpty()) {
    return null;
  }

  return groups.map((group, index) => <GVDGroupItem group={group} key={index} />);
};
export default WrapperGVD;