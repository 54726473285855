import React, { useEffect, useState } from "react";
import { HTTP_STATUS_CODES } from "../../api/API";
import { Auth } from "../../api/Auth";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useFormFieldOnChange } from "../../utils/FormHooks";

import "./AppResetPassword.css";

const AppResetPassword = () => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const [succeeded, setSucceeded] = useState(false);
  const [failed, setFailed] = useState(null);
  const [pwDontMatch, setPwDontMatch] = useState(false);
  const [processing, setProcessing] = useState(false);

  const [emailInput, setEmailInput, onEmailChange] = useFormFieldOnChange("");
  const [passwordInput, setPasswordInput, onPasswordChange] =
    useFormFieldOnChange("");
  const [confirmPwInput, setConfirmPwInput, onConfirmPwChange] =
    useFormFieldOnChange("");

  const onEmailInputChange = (e) => {
    onEmailChange(e);
    hideStatusMessages();
  };

  const onPasswordInputChange = (e) => {
    onPasswordChange(e);
    hideStatusMessages();
  };

  const onConfirmPwInputChange = (e) => {
    onConfirmPwChange(e);
    hideStatusMessages();
  };

  useEffect(() => {
    if (
      passwordInput.trim().length == "" ||
      confirmPwInput.trim().length == ""
    ) {
      setPwDontMatch(false);
      return;
    }
    setPwDontMatch(passwordInput !== confirmPwInput);
  }, [passwordInput, confirmPwInput]);

  const submitForm = async (e) => {
    e.preventDefault();

    if (processing) {
      return;
    }
    if (
      emailInput.trim().length == "" ||
      passwordInput.trim().length == "" ||
      confirmPwInput.trim().length == ""
    ) {
      return;
    }

    if (passwordInput !== confirmPwInput) {
      setPwDontMatch(true);
      return;
    }

    hideStatusMessages();

    setProcessing(true);
    const reqRes = await Auth().ResetPassword(
      emailInput,
      passwordInput,
      confirmPwInput,
      searchParams.get("token")
    );
    setProcessing(false);

    if (reqRes == false || reqRes.status != HTTP_STATUS_CODES.OK) {
      if (reqRes.status == HTTP_STATUS_CODES.UNPROCESSABLE_ENTITY) {
        setFailed(
          <span>
            Please, make sure you have entered a valid email address
            <br />
            and both passwords are the same.
          </span>
        );
      } else if (reqRes.status == HTTP_STATUS_CODES.BAD_REQUEST) {
        setFailed(
          "Please, make sure you have entered your email address correctly."
        );
      } else {
        setFailed("An error has occurred during the registration process.");
      }
      return;
    }

    resetForm();
    setSucceeded(true);
  };

  const hideStatusMessages = () => {
    setProcessing(false);
    setFailed(null);
    setSucceeded(false);
    setPwDontMatch(false);
  };

  const resetForm = () => {
    setEmailInput("");
    setPasswordInput("");
    setConfirmPwInput("");
    hideStatusMessages();
  };

  const passwordHasBeenChanged = () => {
    return (
      <>
        <p style={{ fontWeight: "normal" }}>
          Please close this window and login to app.
        </p>
        <div className="FormButtonContainer">
          <button
            onClick={() => {
              navigate("/SignIn");
            }}
          >
            Continue to login
          </button>
        </div>
      </>
    );
  };

  const formContent = () => {
    return (
      <>
        <div className="FormFieldRow">
          <label>Email</label>
          <input
            type="text"
            placeholder="Enter your email..."
            value={emailInput}
            onChange={onEmailInputChange}
          />
        </div>
        <div className="FormFieldRow">
          <label>Password</label>
          <input
            type="password"
            placeholder="Enter your password..."
            value={passwordInput}
            onChange={onPasswordInputChange}
          />
        </div>
        <div className="FormFieldRow">
          <label>Confirm your password</label>
          <input
            type="password"
            placeholder="Confirm your password..."
            value={confirmPwInput}
            onChange={onConfirmPwInputChange}
          />
        </div>
        {failed != null && <div className="FormFieldRow Error">{failed}</div>}
        {pwDontMatch && (
          <div className="FormFieldRow Error">
            You've entered different passwords!
          </div>
        )}
        <div className="FormButtonContainer">
          <button disabled={processing} onClick={submitForm}>
            Confirm
          </button>
          <button
            disabled={processing}
            onClick={(e) => {
              e.preventDefault();
              resetForm();
            }}
          >
            Reset
          </button>
        </div>
      </>
    );
  };

  return (
    <div className="SignUpPageContainer">
      <form>
        <img
          style={{ width: "200px", marginBottom: "1rem", alignSelf: "center" }}
          src="BBA-Logo-TBl-S.png"
          alt=""
        />
        <p>
          {!succeeded
            ? "Reset your password"
            : "Your password has been successfully changed!"}
        </p>
        {!succeeded ? formContent() : passwordHasBeenChanged()}
      </form>
    </div>
  );
};

export default AppResetPassword;
