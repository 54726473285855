import React, { useMemo, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight, faAngleDown } from '@fortawesome/free-solid-svg-icons'

const GVDItem = ({ description, total, children, fetchData, isSelected, onClick }) => {

  const [hasRequestedData, setHasRequestedData] = useState(false);
  const [showItemSub, setShowItemSub] = useState(false);

  const shouldShowItemSub = useMemo(() => showItemSub && isSelected(), [showItemSub]);

  const toggleSubItem = () => {
    const show = !showItemSub;
    setShowItemSub(show);

    onClick();

    if (!children) return;

    if (!hasRequestedData) {
      requestData();
    }
  };

  const requestData = async () => {
    await fetchData();

    setHasRequestedData(true);
  };

  const statusIcon = () => !children ? null : <FontAwesomeIcon icon={showItemSub ? faAngleDown : faAngleRight} />;

  return (
    <>
      <div className="cursor-pointer flex flex-col w-fit text-sm">
        <span className={"select-none p-[5px_10px] font-bold w-fit" + (isSelected() ? " !bg-[#cce4dc]" : "")} onClick={toggleSubItem}>
          {statusIcon()} {description ?? 'Not Defined'}
          {/* {total && <span className="gvd-item-description-total"> ({total})</span>} */}
        </span>
        {shouldShowItemSub && children && (
          <div className="mt-1 ml-4">
            {children}
          </div>
        )}
      </div>
    </>
  );
};

export default GVDItem;