import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../rootReducer";
import { finish, importProds, start } from '../../api/ImportFileProducts';
import {
  getProductBrand,
  getProductDetails,
  getProductSegmentInfo,
  getProductCountryOrigin,
  getProductFamily,
  getProductClass,
  getProductBrickInfo,
  getProductGroupingInfo,
  getProductVariantInfo,
  getProductDetailInfo,
  submitProductDetails,
  submitProductDetailID
} from "../../api/SupplierProducts";

export interface productState {
  processing: boolean;
  loading: boolean;
  success: boolean;
  errorMsg: string;
  product: any;
  segments: any;
  countryOrigins: any;
  family: any;
  brick: any;
  grouping: any;
  productClass: any;
  variant: any;
  details: any;
  brands: any;
  wizardIndex: number;
}

export const initialState: productState = {
  processing: false,
  loading: false,
  success: false,
  errorMsg: "",
  product:{},
  segments: [],
  countryOrigins: [],
  family: [],
  productClass: [],
  brick: [],
  grouping: [],
  variant: [],
  details: [],
  brands: [],
  wizardIndex:4
};

export const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    setErrorMsg: (state: productState, { payload }: PayloadAction<string>) => {
      state.errorMsg = payload;
    },
    setLoading: (state: productState, { payload }: PayloadAction<any>) => {
      state.loading = payload;
    },
    setProcessing: (state: productState, { payload }: PayloadAction<any>) => {
      state.processing = payload;
    },
    setIndex: (state: productState, { payload }: PayloadAction<any>) => {
      state.wizardIndex = payload;
    },
    setProductDetail: (state: productState, { payload }: PayloadAction<any>) => {
      state.product = {
        ...state.product,
        FK_SegmentID: payload?.SegmentID,
        FK_BrandID: payload?.BrandID,
        FK_FamilyID: payload.FamilyID,
        FK_ClassID: payload.ClassID,
        FK_BrickID: payload.BrickID,
        FK_GroupingID: payload.GroupingID,
        FK_VariantID: payload.VariantID,
        FK_DetailID: payload.DetailID,
        ...payload,
      };
    },
    clearProductDetail : (state: productState, { payload }: PayloadAction<any>) => {
      state.product = {};
    },
    addProduct: (state: productState, { payload }: PayloadAction<any>) => {
      state.product = payload;
    },
    setSegments: (state: productState, { payload }: PayloadAction<any>) => {
      state.segments = payload
    },
    setCountryOrigin: (state: productState, { payload }: PayloadAction<any>) => {
      state.countryOrigins = payload
    },
    setFamily: (state: productState, { payload }: PayloadAction<any>) => {
      state.family = payload
    },
    setClass: (state: productState, { payload }: PayloadAction<any>) => {
      state.productClass = payload
    },
    setBrick: (state: productState, { payload }: PayloadAction<any>) => {
      state.brick = payload
    },
    setGrouping: (state: productState, { payload }: PayloadAction<any>) => {
      state.grouping = payload
    },
    setVariant: (state: productState, { payload }: PayloadAction<any>) => {
      state.variant = payload
    },
    setDetails: (state: productState, { payload }: PayloadAction<any>) => {
      state.details = payload
    },
    setBrandInfo: (state: productState, { payload }: PayloadAction<any>) => {
      state.brands = payload
    },
  },
});

export const importStart = () => async (dispatch: any) => {
  const res = await start();
  return res;
};

export const productsImport = (UpdateID, chunck) => async (dispatch: any) => {
  const res = await importProds(UpdateID, chunck);
  return res;
};

export const importFinish = (UpdateID) => async (dispatch: any) => {
  const res = await finish(UpdateID);
  return res;
};

export const fetchProductDetails = (query) => async (dispatch) => {
  try {
    const response = await getProductDetails(query);

    if (response?.status === 200) {
      await dispatch(setProductDetail(response.data));
      const brand = await getProductBrand(response.data?.CompanyID, '1');

      /*const parentCompanies = JSON.parse(localStorage.getItem('parentCompanies'));
      const brand = await getProductBrand(parentCompanies?.ParentCompanyID, '1');*/

      if (brand?.status === 200) {
        await dispatch(setBrandInfo(brand.data?.BrandInfo));
      } else {
        console.error('Failed to fetch brand information:', brand?.status);
      }
    } else {
      console.error('Failed to fetch product details:', response?.status);
    }
  } catch (error) {
    console.error('Error fetching product details:', error);
  }
};

export const fetchSegmentInfo = () => async (dispatch: any) => {
  try {
    const response = await getProductSegmentInfo();
    if (response?.status === 200) {
      dispatch(setSegments(response.data));
    } else {
      console.error('Failed to fetch product details:', response?.status);
    }
  } catch (error) {
    console.error('Error fetching product details:', error);
  }
};

export const fetchCountryOrigin = () => async (dispatch: any) => {
  try {
    const response = await getProductCountryOrigin();
    if (response?.status === 200) {
      dispatch(setCountryOrigin(response?.data?.CountryInfo));
    } else {
      console.error('Failed to fetch product details:', response?.status);
    }
  } catch (error) {
    console.error('Error fetching product details:', error);
  }
};

export const fetchFamily = (query) => async (dispatch: any) => {
  try {
    const response = await getProductFamily(query);
    if (response?.status === 200) {
      dispatch(setFamily(response?.data));
    } else {
      console.error('Failed to fetch product details:', response?.status);
    }
  } catch (error) {
    console.error('Error fetching product details:', error);
  }
};

export const fetchClass = (query) => async (dispatch: any) => {
  try {
    const response = await getProductClass(query);
    if (response?.status === 200) {
      dispatch(setClass(response?.data));
    } else {
      console.error('Failed to fetch product details:', response?.status);
    }
  } catch (error) {
    console.error('Error fetching product details:', error);
  }
};

export const fetchBrick = (query) => async (dispatch: any) => {
  try {
    const response = await getProductBrickInfo(query);
    if (response?.status === 200) {
      dispatch(setBrick(response?.data));
    } else {
      console.error('Failed to fetch product details:', response?.status);
    }
  } catch (error) {
    console.error('Error fetching product details:', error);
  }
};

export const fetchGrouping = (query) => async (dispatch: any) => {
  try {
    const response = await getProductGroupingInfo(query);
    if (response?.status === 200) {
      dispatch(setGrouping(response?.data));
    } else {
      console.error('Failed to fetch product details:', response?.status);
    }
  } catch (error) {
    console.error('Error fetching product details:', error);
  }
};

export const fetchVariant = (query) => async (dispatch: any) => {
  try {
    const response = await getProductVariantInfo(query);
    if (response?.status === 200) {
      dispatch(setVariant(response?.data));
    } else {
      console.error('Failed to fetch VariantInfo:', response?.status);
    }
  } catch (error) {
    console.error('Error fetching VariantInfo:', error);
  }
};

export const fetchDetail = (query) => async (dispatch: any) => {
  try {
    const response = await getProductDetailInfo(query);
    if (response?.status === 200) {
      dispatch(setDetails(response?.data));
    } else {
      console.error('Failed to fetch product details:', response?.status);
    }
  } catch (error) {
    console.error('Error fetching product details:', error);
  }
};

export const fetchSubmit = (data) => async (dispatch: any) => {
  try {

    const response = await submitProductDetails(data);
    if (response?.status === 200) {
    } else {
      console.error('Failed to fetch product details:', response?.status);
    }
  } catch (error) {
    console.error('Error fetching product details:', error);
  }
};

export const fetchDetailID = (data) => async (dispatch: any) => {
  try {
    const response = await submitProductDetailID(data);
    if (response?.status === 200) {
      if (response?.data?.DetailID) {
        // If DetailID exists, return it
        return response?.data?.DetailID;
      } else {
        dispatch(setErrorMsg(response?.data?.message
            ? response?.data?.message : response?.data?.errorMessage ? response?.data?.errorMessage: "An undefined error has occurred. Please attempt the action again later."));
      }
    } else {
      console.error('Failed to fetch product details:', response?.status);
    }
  } catch (error) {
    console.error('Error fetching product details:', error);
  }

  // Return null or handle the case where DetailID is not available
  return null;
};


export const {
  setLoading,
  setErrorMsg,
  setProcessing,
  setBrandInfo,
  clearProductDetail,
  setDetails,
  setVariant,
  setGrouping,
  setBrick,
  setClass,
  setFamily,
  setCountryOrigin,
  addProduct,
  setSegments,
  setProductDetail,
  setIndex
} = productSlice.actions;

export const productSelector = (state: RootState) => state.product;

export default productSlice.reducer;
