import React from 'react';

const SelectedBrickContextDefault = {
  selectedBrick: '',
  setSelectedBrick: (brick) => { },
  refreshBrick: () => {}
};

const SelectedBrickContext = React.createContext();

export { SelectedBrickContext, SelectedBrickContextDefault };