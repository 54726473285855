import React from "react";
import { Link } from "react-router-dom";

const DashboardHeader = ({ title, nav }) => {
    return (
        <div className="flex items-center rounded-sm list-none bg-transparent p-0 mb-3">
            <h1 className="text-2xl">{title}</h1>
            {nav.length > 0 && (
                <ul className="list-none p-0 m-0">
                    {nav.map((item, index) => (
                        <li
                            key={index}
                            className="text-sm inline-block relative py-0 p-2 align-bottom text-muted"
                        >
                            <Link
                                className={`text-[#332e38] hover:underline ${nav.length > 1 && index !== nav.length - 1 && "after:absolute after:top-[3px] after:right-0 after:content-[''] after:h-4 after:w-px after:bg-[#70657b] after:rounded"}`}
                                to={item.link}
                            >
                                {item.label}
                            </Link>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default DashboardHeader;
