import React from 'react';
import PageSkeletonComponent from '../../components/PageSkeletonComponent/PageSkeletonComponent';
import './EntityUsers.css';

const EntityUsers = () => {
  return (
    <PageSkeletonComponent>

     <h1>ENTITY - Users Page</h1>

      <hr />

      <h2>Purpose of Page</h2>
      This page will list all users for an ENTITY and access levels or permissions.<br /><br />

      <h2>Who Can Access</h2>
      This page can be access by all logged in users.<br /><br />
      What the user can do on this page is determined by his access restrictions within the organisation.
      From this page the user can view/add/edit/archive Users if they have the appropriate permissions.

    </PageSkeletonComponent>
  );
};

export default EntityUsers;