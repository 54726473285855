import React from 'react';
import PageSkeletonComponent from '../components/PageSkeletonComponent/PageSkeletonComponent';
import './RetailerHome.css';

const RetailerHome = () => {
  return (
    <PageSkeletonComponent>
      <h1>RETAILER - Home Page (Dashboard)</h1>
      <hr />

      <h2>Purpose of Page</h2>
      <b>OUTLINE OF WHAT THIS PAGE IS USED FOR AND ANY FUNCTIONALITY THAT IS KNOWN</b>

      <h2>Who Can Access</h2>


      <h3>Bring Back Staff</h3>
      What can Employees do in relation to Retailers?  What should Employees be able to do in relation to Retailers?<br />
      Bring Back Staff should be able to login as another user and see what they are seeing.

      <h3>Supplier Subscribers</h3>
      A Supplier should not be able to view this area.
      
      <h3>Retailer Subscribers</h3>
      What can Retailers do in relation to products?  What should Retailers be able to do in relation to products?

      <h3>Service Subscribers</h3>
      What can Service Providers do in relation to products?  What should Service Providers be able to do in relation to products?

      <h2>Purpose of Page</h2>
      This page is the welcome page for the Product section, what we will show here or what we can do from here will be determined...
      <br /><br />A summary page showing products and status...
      <li>Number of products not shared</li>
      <li>Number of products missing images</li>
      <li>Number of products scanned by day, week, month</li>
      <br /><br />
      Where these items are shown, there should be a link to enable the user to click to go and view these products or analytics.







      <b>WHO IS EXPECTED TO ACCESS THIS PAGE</b>

    </PageSkeletonComponent>
  );
};

export default RetailerHome;