import React, { useState, useEffect } from 'react';
import { Brands } from '../../../api/Brands';
import { HTTP_STATUS_CODES } from '../../../api/API';
import ModalHeader from '../../../components/PopupDialog/ModalDialogHeader';
import PageSkeletonComponent from '../../components/PageSkeletonComponent/PageSkeletonComponent';
import ConfirmationDialog from '../../../components/PopupDialog/ConfirmationDialog';
import ModalActions from '../../../components/PopupDialog/ModalActions';
import ModalAction from '../../../components/PopupDialog/ModalAction';
import { useFormFieldOnChange } from '../../../utils/FormHooks';
import BrandActionNotValidatedRecordDialog from './BrandActionNotValidatedRecordDialog';
import SectionContainerRow from '../../components/SectionContainerRow';
import SectionContainer from '../../components/SectionContainer';
import DataTable, { ActionRow, Column } from '../../components/DataTable';
import './ProductBrands.css';

const ProductBrands = (props) =>  {

  const [existingValidatedBrands, setExistingValidatedBrands] = useState([]);
  const [validatedBrands, setValidatedBrands] = useState([]);
  const [invalidatedBrands, setInvalidatedBrands] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [substitutions, setSubstitutions] = useState([]);
  const [substitutionsHeader, setSubstitutionsHeader] = useState('Substitutions');

  const [showBrandNotValidatedModal, setShowBrandNotValidatedModal] = useState(false);
  const [showBrandValidatedModal, setShowBrandValidatedModal] = useState(false);

  const [brandGlnSearch, , onBrandGlnSearchChange] = useFormFieldOnChange('0037000000006');
  const [glnCompanyName, setGlnCompanyName] = useState('');

  const [parentBrandInputValue, setParentBrandInputValue, onParentBrandInputChange] = useFormFieldOnChange('');
  const [newBrandRec, setNewBrandRec, onNewBrandRecChange] = useFormFieldOnChange('');
  const [newBrandSubRec, setNewBrandSubRec, onNewBrandSubRecChange] = useFormFieldOnChange('');
  const [newBrandRecAlreadyExists, setNewBrandRecAlreadyExists] = useState('');
  const [showNewBrandConfirmationModal, setShowNewBrandConfirmationModal] = useState(false);

  useEffect(() => {
    checkBrandAlreadyExists.check(brandGlnSearch, newBrandRec, newBrandSubRec);
  }, [newBrandRec, newBrandSubRec]);

  const [checkBrandAlreadyExists] = useState(() => {
    const timeoutFn = (GLN, brand, brandSub) => {
      return async () => {
        if (brand.trim().length < 3) return;
        if (brandSub.trim().length > 0 && brandSub.trim().length < 3) return;
        const res = await Brands().checkBrandAlreadyExists(GLN, brand, brandSub);
        showNewBrandRecAlreadyExists(res);
      };
    };

    let timeout = null;

    return {
      check: (GLN, brand, brandSub) => {
        clearTimeout(timeout);
        setNewBrandRecAlreadyExists('');
        timeout = setTimeout(timeoutFn(GLN, brand, brandSub), 1000);
      }
    };
  });

  async function loadBrands() {
    const brands = await Brands().getAll(brandGlnSearch);
    return brands !== false ? brands.data : [];
  }

  async function updateBrands() {
    const brands = await loadBrands();
    const _validatedBrands = [];
    const _invalidatedBrands = [];
    let _glnCompanyName = '';
    brands.forEach((item, index) => {
      _glnCompanyName = item.CompanyName;
      if (item.ValidBrand) {
        _validatedBrands.push(item);
      }
      else {
        _invalidatedBrands.push(item);
      }
    });
    setGlnCompanyName(_glnCompanyName.trim().length <= 0 ? 'Company not found' : _glnCompanyName);
    setValidatedBrands(_validatedBrands);
    setInvalidatedBrands(_invalidatedBrands);
    setSubstitutions([]);
    setSubstitutionsHeader('Substitutions')
    setExistingValidatedBrands(_validatedBrands);
    closeModals();
  }

  const searchBrandsByGln = async () => {
    if (brandGlnSearch.trim().length <= 0) return;
    await updateBrands();
  };

  const editBrand = (e, brand) => {
    e.preventDefault();
  };

  const closeModals = () => {
    setSelectedBrand(null);
    setShowBrandValidatedModal(false);
    setShowBrandNotValidatedModal(false);
    setShowNewBrandConfirmationModal(false);
    setNewBrandRec('');
    setNewBrandSubRec('');
    setParentBrandInputValue('');
    setNewBrandRecAlreadyExists('');
  };

  const handleBrandValidatedModal = async (e) => {
    const rest = await Brands().validateBrand(selectedBrand.BrandID);
    if (rest === false) {
    } else {
      searchBrandsByGln();
    }
  };

  const handleBrandNotValidatedModal = async (e) => {
    if (newBrandRec.trim().length > 0) {
      return setShowNewBrandConfirmationModal(true);
    }
    else {
      if (parentBrandInputValue.trim().length <= 0) return;
      const rest = await Brands().addSubstitute(selectedBrand.BrandID, parentBrandInputValue);
      if (rest === false) {
      }
      searchBrandsByGln();
    }
  };

  const handleBrandNotValidatedConfirmation = async () => {
    const res = await Brands().createBrandSubstitute(brandGlnSearch, selectedBrand.BrandID, newBrandRec, newBrandSubRec);
    if (showNewBrandRecAlreadyExists(res)) {
      setShowNewBrandConfirmationModal(false);
      return;
    }
    searchBrandsByGln();
  };

  const showNewBrandRecAlreadyExists = (reqRes) => {
    if (reqRes.status == HTTP_STATUS_CODES.CONFLICT) {
      setNewBrandRecAlreadyExists(
        reqRes.data.isParentBrand ?
          'The brand you are trying to create already exists. Please, select it from the "Validated Brands" list.' :
          'The brand you are trying to create already exists. Please, select it from the "Brands to be validated" list.'
      );
      return true;
    }
    return false;
  };

  const onValidatedBrandClick = async (e, item) => {
    setSubstitutionsHeader(
      'Substitutions - ' + item.BrandID + ' - ' + item.Brand + ' - ' + item.BrandSub
    );
    const subs = await Brands().getSubstitutions(item.BrandID);
    if (subs == false) {

    } else {
      setSubstitutions(subs == false ? [] : subs.data);
    }
  };

  const editAction = ActionRow({
    description: 'Edit',
    actionFunction: editBrand
  });

  const validateAction = ActionRow({
    description: 'Valid',
    actionFunction: (e, brandToSelect) => {
      setSelectedBrand(brandToSelect);
      setShowBrandValidatedModal(true);
    }
  });

  const noAction = ActionRow({
    description: 'No',
    actionFunction: (e, brandToSelect) => {
      setSelectedBrand(brandToSelect);
      setShowBrandNotValidatedModal(true);
    }
  });

  const brandsColumns = [
    Column({ title: 'Brand ID', rowProperty: 'BrandID' }),
    Column({ title: 'Brand', rowProperty: 'Brand' }),
    Column({ title: 'Sub Brand', rowProperty: 'BrandSub' }),
    Column({ title: 'Substitute', rowProperty: 'Substitute' })
  ];

  const rowIdentifier = 'BrandID';

  return (
    <>
      <PageSkeletonComponent>
        <div className="BrandsContainer">
          <div className="BrandGlnSearchContainer">
            <div className="BrandsGlnSearchResult">
              <span className="Label">Company: </span>
              <span className="Company">{glnCompanyName}</span>
            </div>
            <label htmlFor="brand_gln_search">GLN: </label>
            <input type="text" id="brand_gln_search" onChange={onBrandGlnSearchChange} value={brandGlnSearch} />
            <button onClick={searchBrandsByGln}>Search</button>
          </div>

          <div className="BrandsSections">
            <SectionContainerRow>
              <SectionContainer header="Validated Brands">
                <DataTable
                  onClickRow={onValidatedBrandClick}
                  columns={brandsColumns}
                  rowIdentifier={rowIdentifier}
                  rowData={validatedBrands}
                  actions={[editAction]} />
              </SectionContainer>
              <SectionContainer header={substitutionsHeader}>
                <DataTable
                  columns={brandsColumns}
                  rowIdentifier={rowIdentifier}
                  rowData={substitutions}
                  actions={[editAction]} />
              </SectionContainer>
            </SectionContainerRow>
            <SectionContainer header="Invalidated Brands">
              <DataTable
                columns={brandsColumns}
                rowIdentifier={rowIdentifier}
                rowData={invalidatedBrands}
                actions={[validateAction, noAction]} />
            </SectionContainer>
          </div>
        </div>
      </PageSkeletonComponent>

      <ConfirmationDialog
        showModal={showBrandValidatedModal}
        onClose={closeModals}>
        <ModalHeader header="Are you sure you want to proceed with the validation action?" />

        <ModalActions>
          <ModalAction onClick={handleBrandValidatedModal} description="Yes" />
          <ModalAction onClick={closeModals} description="No" />
        </ModalActions>
      </ConfirmationDialog>

      <BrandActionNotValidatedRecordDialog
        brandToBeChanged={selectedBrand}
        showModal={showBrandNotValidatedModal}
        onClose={closeModals}
        onParentBrandInputChange={onParentBrandInputChange}
        existingValidatedBrands={existingValidatedBrands}
        onNewRecordBrandChange={onNewBrandRecChange}
        onNewRecordBrandSubChange={onNewBrandSubRecChange}
        newRecodAlreadyExists={newBrandRecAlreadyExists}
        confirmChange={handleBrandNotValidatedModal}
      />

      <ConfirmationDialog
        showModal={showNewBrandConfirmationModal}
        onClose={() => { setShowNewBrandConfirmationModal(false) }}>
        <ModalHeader header="Are you sure you want to proceed with the brand creation?" />

        <ModalActions>
          <ModalAction onClick={handleBrandNotValidatedConfirmation} description="Yes" />
          <ModalAction onClick={() => setShowNewBrandConfirmationModal(false)} description="No" />
        </ModalActions>
      </ConfirmationDialog>

    </>
  );
};

export default ProductBrands;