import React, { useState } from "react";
import Routes from "./routes/Routes";
import SideMenu from "./dashboard-menu/SideMenu";
import { AuthContext, useAuthState } from "./context/AuthContext";
import "./App.css";
import ComponentSkeleton from "./components/Skeleton";

const App = () => {
    const authState = useAuthState();

    const [toggle, setToggle] = useState(false);
    const [responsiveToggle, setResponsiveToggle] = useState(false);

    const boxShadow = {
        boxShadow: '0 1px 15px 1px rgba(0,0,0,.04), 0 1px 6px rgba(0,0,0,.08)'
    }

    return (
        <AuthContext.Provider value={authState}>
            <ComponentSkeleton>
                <div className="overflow-hidden w-full">
                    {authState.isUserLoggedIn && (
                        <SideMenu toggle={toggle} setToggle={setToggle} />
                    )}
                    {authState.isUserLoggedIn && responsiveToggle && (
                        <div onClick={()=> setResponsiveToggle(false)} className="lg:hidden block fixed w-[calc(100%-240px)] h-[calc(100vh-5px)] bottom-0 right-0 z-[999999]" />
                    )}
                    <div>
                        <div
                            className={`${
                                (!authState.isUserLoggedIn || toggle) ? "lg:w-full" : "lg:w-[calc(100%-240px)]"
                            } px-3 min-h-screen float-right m-0 w-full bg-[#f9f9f9] transition-all duration-200 ease-in flex flex-col ${
                                !authState.isUserLoggedIn && "!w-full h-screen"
                            }`}
                        >
                            <Routes />
                        </div>
                        {/* <Footer toggle={toggle} login={authState.isUserLoggedIn} /> */}
                    </div>
                </div>
            </ComponentSkeleton>
        </AuthContext.Provider>
    );
};

export default App;
