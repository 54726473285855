import React, { useContext, useState } from 'react';
import GVDItem from './GVDItem';
import { ContextSelectedBrick } from '../contexts/ContextSelectedBrick';
import { useAppSelector, useAppDispatch } from "../../../store/store";
import {
	addNewGroup
} from "../../../store/slices/gvdEditiorSlice";

const GVDItemAddGroup = ({ }) => {
  const dispatch = useAppDispatch();
  const { BRICK, refreshBrick } = useContext(ContextSelectedBrick);

  const onAddNew = async (description: string) => {
    if (!description.trim().length || !BRICK.trim().length) return;
    dispatch(addNewGroup(description, BRICK))
    refreshBrick();
  };

  return (
    <GVDItem
      description={'Click on the \'+\' sign to a add new group'}
      onAddNew={onAddNew}
      onRename={false}
      onDelete={false} />
  );
};

export default GVDItemAddGroup;