import React from 'react';
import './SectionContainerRow.css';

const SectionContainerRow = (props) => {

  return (
    <div className="SectionContainerRow">
      {props.children}
    </div>
  );
};

export default SectionContainerRow; 