import React from 'react';
import ReactCountryFlag from "react-country-flag"
import { Link } from "react-router-dom";

const renderCountryFlag = (value, data, title) => {
    // if it is New Zealand show a speficic image
    if (value == 'NZ') {
        return <ReactCountryFlag
            countryCode={value}
            svg
            cdnUrl={window.location.origin + "/country-flags/"}
            style={{
                width: '2em',
                height: '2em',
                objectFit: 'cover',
                display: 'block',
                margin: 'auto'
            }}
            title={title}
        />
    }
    return <ReactCountryFlag
        countryCode={value}
        svg
        style={{
            width: '2em',
            height: '2em',
            display: 'block',
            margin: 'auto'
        }}
        title={title}
    />
};

const defaultColumns = [
    {
        name: 'ProductImageURL',
        minWidth: 80,
        header: 'Image',
        filterable: false,
        align: 'center',
        defaultFlex: 1,
        render: ({ value, data }) => (
            <Link to={`/edit-product/${data.GTIN}`}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '95%' }}>
                    <img
                        src={value ? value : '/temp.jpg'}
                        alt="ProductImage"
                        style={{
                            width: '5em',
                            height: '5.5em',
                            objectFit: 'contain',
                            margin: 0,  // Remove margin
                            padding: 0, // Remove padding
                        }}
                    />
                </div>
            </Link>
        ),
    },
    { name: 'GTIN', minWidth: 100, header: 'GTIN', defaultFlex: 1, type: 'string', render: ({ value, data }) => <Link to={`/edit-product/${data.GTIN}`}>{value}</Link> },
    { name: 'ProductID', header: 'Id', defaultVisible: false, type: 'number', maxWidth: 40, render: ({ value, data }) => <Link to={`/edit-product/${data.GTIN}`}>{value}</Link> },
    { name: 'CompanyID', header: 'CompanyID', defaultVisible: false, type: 'number', render: ({ value, data }) => <Link to={`/edit-product/${data.GTIN}`}>{value}</Link> },
    { name: 'CompanyName', minWidth: 80, header: 'Supplier', defaultFlex: 2, render: ({ value, data }) => <Link to={`/edit-product/${data.GTIN}`}>{value}</Link> },
    { name: 'Brand', minWidth: 80, header: 'Brand', defaultFlex: 3, render: ({ value, data }) => <Link to={`/edit-product/${data.GTIN}`}>{value}</Link> },
    { name: 'BrandSub', minWidth: 80, header: 'Sub Brand', defaultFlex: 3, render: ({ value, data }) => <Link to={`/edit-product/${data.GTIN}`}>{value}</Link> },
    { name: 'Description', minWidth: 80, header: 'Description', type: 'string', defaultFlex: 4, render: ({ value, data }) => <Link to={`/edit-product/${data.GTIN}`}>{value}</Link> },
    {
        name: 'NetContent',
        defaultWidth: 96,
        className: 'text-end',
        filterable: false,
        align: 'center',
        header: 'Content',
        render: ({ value, data }) => (
            <Link to={`/edit-product/${data.GTIN}`} style={{ alignItems: 'right' }}>
                {value !== 'null' ? `${Math.floor(value)}%` : ''}
            </Link>
        )
    },
    {
        name: 'ProductCountryCodeAN2',
        defaultWidth: 85,
        header: 'Origin',
        filterable: false,
        align: 'center',
        render: ({ value, data }) => (
            <Link to={`/edit-product/${data.GTIN}`}>{renderCountryFlag(value, data, data.ProductCountry)}</Link>
        ),
    },
    {
        name: 'CompanyAddressCountryCodeAN2',
        defaultWidth: 80,
        header: 'Registered',
        filterable: false,
        align: 'center',
        render: ({ value, data }) => (
            <Link to={`/edit-product/${data.GTIN}`}>{renderCountryFlag(value, data, data.CompanyName + ' - ' + data.CompanyAddressCountry)}</Link>
        ),
    },
    {
        name: 'ParentAddressCountryCodeAN2',
        defaultWidth: 87,
        header: 'Parent',
        filterable: false,
        align: 'center',
        render: ({ value, data }) => (
            <Link to={`/edit-product/${data.GTIN}`}>{renderCountryFlag(value, data, data.ParentCompany + ' - ' + data.ParentAddressCountry)}</Link>
        ),
    },
];

export default defaultColumns;