import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  height: 0;
`;

export const Section = styled.div`
  ${props => props['column'] && `
    flex-direction: column;
  `}

  ${props => props['row'] && `
    flex-direction: row;
  `}

  display: flex;
  flex-grow: 1;  
  flex-basis: 0;
  border: 1px solid #707070;
  overflow-y: auto;

  ${props => props['no-border'] && `
    border: none;
  `}

  ${props => props['bg-color'] && `
    background-color: white;
  `}

  ${props => props['mb'] && `
    margin-bottom: 10px;
  `}

  ${props => props['mr'] && `
    margin-right: 10px;
  `}

  & > div.body {
    height: 100%;
    overflow-y: auto;
  }
`;

export const Header = styled.div`
  padding: 5px;
  border-bottom: 1px solid #707070;

  & > span {
    font-weight: bold;
  }
`;

export const Button = styled.button`
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid #707070;
  background-color: #707070;
  color: white;
  font-size: 12px;
  cursor: pointer;
`