const { useState, useRef } = require("react");

function useFormFieldOnChange(defaultValue = '') {
  const [fieldValue, setFieldValue] = useState(defaultValue);

  return [
    fieldValue, setFieldValue, (e) => setFieldValue(e.target.value)
  ]
}

function useFormFieldUtils(defaultValue = '') {
  const [fieldValue, setFieldValue] = useState(defaultValue);
  const fieldRef = useRef(null);
  const [isFieldValid, setIsFieldValid] = useState(true);

  return {
    value: fieldValue,
    setValue: setFieldValue,
    onChange: (e) => setFieldValue(e.target.value),
    ref: fieldRef,
    isValid: isFieldValid,
    setIsFieldValid,
    checkIsValid: (isValidCallback) => {
      const isValid = isValidCallback(fieldValue);
      if (!isValid) {
        fieldRef.current.focus();
      }
      setIsFieldValid(isValid);
      return isValid;
    },
    focus: () => {
      fieldRef.current && fieldRef.current.focus()
    }
  };
}

export {
  useFormFieldOnChange, useFormFieldUtils
};