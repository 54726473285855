import React, { useEffect, useState } from 'react';
import { useFormFieldOnChange } from '../../utils/FormHooks';

import './GetStartedAccountTypeSupplier.scss';

import { isValid } from 'gtin';
import { useNavigate } from 'react-router-dom';
import { claimCompany as claimCompanyApi, searchCompanyByGTIN } from '../../api/SignUp';
import { HTTP_STATUS_CODES } from '../../api/API';
import DashboardContainer from '../../dashboard-components/DashboardContainer';
import { useLocation } from 'react-router-dom';

const GetStartedAccountTypeSupplier = () => {

  const location = useLocation();
  const navigate = useNavigate();

  const [gtin, setGtin, onChangeGtin] = useFormFieldOnChange('');
  const [isValidGtin, setIsValidGtin] = useState(true);

  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [hasSelectedCompany, setHasSelectedCompany] = useState(true);

  const [isSearchingCompanies, setIsSearchingCompanies] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionError, setSubmissionError] = useState(false);
  const [hasBeenCompanyClaimed, setHasBeenCompanyClaimed] = useState(false);
  const [secsToNextStep, setSecsToNextStep] = useState(null);

  const isValidGTIN = (GTIN) => {
    try {
      return isValid(GTIN);
    } catch (error) {
      return false;
    }
  };

  const searchCompanyByGtin = (e) => {
    e.preventDefault();
    setIsValidGtin(true);
    if (!isValidGTIN(gtin)) {
      setIsValidGtin(false);
      return;
    }

    callSearchCompanyByGTIN();
  };

  const callSearchCompanyByGTIN = async () => {
    setHasSelectedCompany(true);
    setIsSearchingCompanies(true);
    const res = await searchCompanyByGTIN(gtin);
    setIsSearchingCompanies(false);

    if (res == false || res.status !== HTTP_STATUS_CODES.OK) {
      return false;
    }

    setCompanies([res.data]);
  };

  const claimCompany = (event) => {
    event.preventDefault();
    if (selectedCompany == null || selectedCompany.Company.isAlreadyClaimed) {
      setHasSelectedCompany(selectedCompany?.Company?.isAlreadyClaimed == true);
      return;
    }
    setHasSelectedCompany(true);
    callClaimCompany();
  };

  const callClaimCompany = async () => {
    setIsSubmitting(true);
    const res = await claimCompanyApi(location.state?.industry, selectedCompany.Company.EntityGLN);
    setIsSubmitting(false);

    setSubmissionError(res == false || res.status != HTTP_STATUS_CODES.NO_CONTENT);

    if (res !== false && res.status == HTTP_STATUS_CODES.NO_CONTENT) {
      setHasBeenCompanyClaimed(true);
      setSecsToNextStep(3);
    }
  };

  useEffect(() => {
    if (secsToNextStep == null) return;
    if (secsToNextStep <= 0) {
      navigate('/GetStarted/AccountTypeSupplierConfirmation');
      return;
    }
    setTimeout(() => {
      setSecsToNextStep(secsToNextStep - 1);
    }, 1000);
  }, [secsToNextStep]);

  const resetForm = (e) => {
    e.preventDefault();
    setCompanies([]);
    setGtin('');
    setSelectedCompany(null);
    setHasSelectedCompany(true);
  };

  const onChangeSelectedCompany = (company) => (e) => setSelectedCompany(company);

  return (
    <DashboardContainer title={"Claim your company"}>
      <div className="dashboard-get-started">
        <form className="form get-started-account-type supplier" style={{ width: 'auto' }}>
          <table>
            <tr>
              <td colSpan="2">
                <label>Enter a barcode below to search for your company</label>
              </td>
            </tr>
            <tr>
              <td>
                <input type="text" placeholder="Enter a barcode" value={gtin} onChange={onChangeGtin} />
              </td>
              <td>
                <button className="search-for-company" onClick={searchCompanyByGtin}>Search</button>
              </td>
            </tr>
            {!hasSelectedCompany && (
              <tr>
                <td colSpan="2" style={{ color: 'red' }}>
                  Please, select a company to proceed
                </td>
              </tr>
            )}
            {!isValidGtin && (
              <tr>
                <td colSpan="2" style={{ color: 'red' }}>
                  Please, enter a valid barcode!
                </td>
              </tr>
            )}
            {isSearchingCompanies && (
              <tr>
                <td colSpan="2" style={{ fontWeight: 'bold', fontStyle: 'italic', textAlign: 'center' }}>
                  Searching companies ...
                </td>
              </tr>
            )}
          </table>
          <table style={{ marginTop: '10px' }}>
            {companies.map((company, index) => (
              <tr key={index}>
                <td>
                  <input
                    checked={company.Company.EntityGLN == selectedCompany?.Company?.EntityGLN}
                    onChange={onChangeSelectedCompany(company)}
                    type="radio" name="company-to-claim" value={company.Company.EntityGLN} />
                </td>
                <td>
                  <label
                    onClick={(e) => setSelectedCompany(company)}
                    className="no-colon"
                  >
                    {company.Company.CompanyName} - {company.Company.EntityGLN}
                    {company.Company.isAlreadyClaimed && <>
                      <br />
                      <span style={{ color: 'red', fontStyle: 'italic', fontSize: '12px' }}>This company has been already claimed</span>
                    </>}
                  </label>
                </td>
              </tr>
            ))}
          </table>
          {isSubmitting && <tr>
            <td colSpan="2" style={{ fontWeight: 'bold', fontStyle: 'italic', textAlign: 'center' }}>
              We are processing your request ...
            </td>
          </tr>}
          {submissionError && <tr>
            <td colSpan="2" style={{ fontWeight: 'bold', fontStyle: 'italic', textAlign: 'center', color: 'red' }}>
              Something went wrong, please contact our support!
            </td>
          </tr>}
          {hasBeenCompanyClaimed && <tr>
            <td colSpan="2" style={{ fontWeight: 'bold', fontStyle: 'italic', textAlign: 'center' }}>
              All set up! You will be taken to the next step in {secsToNextStep} second(s)
            </td>
          </tr>}
          {!hasBeenCompanyClaimed && !isSubmitting && (
            <div className="action-footer">
              <button onClick={claimCompany}>Next</button>
              <button onClick={resetForm}>Reset</button>
            </div>
          )}
        </form>
      </div>
    </DashboardContainer >
  );
};

export default GetStartedAccountTypeSupplier;
