import React from "react";
import { Link } from "react-router-dom";
import SideMenuItem from "./SideMenuItem";

const SideMenuServiceProvider = () => {
    return (
        // <SideMenuItem>
        //   <Link to="/">Service Provider</Link>
        //   <SideMenuItem>
        //     <Link to="/">Home</Link>
        //     <Link to="/">Analytics</Link>
        //   </SideMenuItem>
        // </SideMenuItem>

        <li className="Ul_li--hover">
            <Link
                className="has-arrow w-[220px] mx-3 h-9 !flex items-center"
                to="/"
            >
                <i className="i-Box-Full text-lg mr-2 text-muted"></i>
                <span className="item-name text-base text-muted">
                    Service Provider
                </span>
            </Link>
            <ul className="mm-collapse space-y-4 ml-2 mt-2">
                <li className="item-name">
                    <Link
                        to="/"
                        className="mx-3 flex gap-2 items-center text-muted"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            width={20}
                            height={20}
                            className="mr-2 text-muted"
                            strokeWidth="1.5"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                            />
                        </svg>
                        <span className="item-name text-base">Home</span>
                    </Link>
                </li>
                <li className="item-name">
                    <Link
                        to="/"
                        className="mx-3 flex gap-2 items-center text-muted"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-5 text-muted h-5"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z"
                            />
                        </svg>
                        <span className="item-name text-base">Analytics</span>
                    </Link>
                </li>
            </ul>
        </li>
    );
};

export default SideMenuServiceProvider;
