import React, { useEffect, useState } from "react";
import Skeleton from "@mui/material/Skeleton";
import { useLocation } from "react-router-dom";

const ComponentSkeleton = ({ children }) => {
    const [componentDelay, setComponenetDelay] = useState(true);

    const location = useLocation();

    useEffect(() => {
        setComponenetDelay(true);
        const timer = setTimeout(() => {
            setComponenetDelay(false);
        }, 1500);

        return () => {
            clearTimeout(timer);
            setComponenetDelay(true);
        };
    }, [location.pathname]);

    if (componentDelay) {
        return (
            <div className="overflow-hidden w-full flex">
                <div className="flex flex-col h-screen w-60">
                    <Skeleton className="!transform-none !w-full !h-full !scale-0" />
                </div>
                <div className="p-5 w-full">
                    <div className="flex flex-col h-full w-full gap-4">
                        <Skeleton variant="rect" className="grow h-full w-full" />
                        <Skeleton variant="rect" className="grow h-full w-full" />
                    </div>
                </div>
            </div>
        );
    }

    return <>{children}</>;
};

export default ComponentSkeleton;
