import React from 'react';
import PageSkeletonComponent from '../components/PageSkeletonComponent/PageSkeletonComponent';
import './EntityHome.css';

const EntityHome = () => {
  return (
    <PageSkeletonComponent>

      <h1>ENTITY - Home Page</h1>

      <hr />

      <h2>Purpose of Page</h2>
      The ENTITY is the Retailer, Supplier or Service Provider.<br /><br />
      This will be the Dashboard for the Entity<br /><br />
      This page is for us to view other Entities pages for support and testing only.<br /><br />
      Not sure what we will put on the dashboard for Bring Back if an employee is logged in...<br /><br />
      The administration of Bring Back Australian Staff and Representatives will more than likely be external to this system

      <h2>Who Can Access</h2>
      This page can be access by all logged in users.<br /><br />
      What the user can do on this page is determined by his access restrictions within the organisation.

    </PageSkeletonComponent>
  );
};

export default EntityHome;