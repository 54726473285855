import React, { useContext, useState } from 'react';
import { SelectedGVDDetailContext } from '../context/SelectedGVDDetailContext';
import GVDItem from './GVDItem';
import GVDItemVariant from './GVDItemVariant';
import { ContextSelectedBrick } from '../../contexts/ContextSelectedBrick';
import { useAppSelector, useAppDispatch } from "../../../../store/store";
import {
	gvdValidate,
	fetchGroupVars
} from "../../../../store/slices/gvdValidateSlice";

const GVDItemGroup = ({ group }) => {
  const dispatch = useAppDispatch();
  const { variants } = useAppSelector(gvdValidate);
  const { BRICK } = useContext(ContextSelectedBrick);

  const { selectedDetail, setSelectedDetail } = useContext(SelectedGVDDetailContext);

  const fetchData = async () => {
 dispatch(fetchGroupVars(BRICK, group.GroupingID))
  };

  const isSelected = () => selectedDetail.GroupingID == group.GroupingID;

  const onClick = () => {
    setSelectedDetail({
      GroupingID: group.GroupingID, GroupingDesc: group.GroupingDesc,
      VariantID: '', VariantDesc: '',
      DetailID: '', DetailDesc: ''
    })
  };

  return (
    <GVDItem
      description={group.GroupingDesc}
      total={group.Total}
      onClick={onClick}
      isSelected={isSelected}
      fetchData={fetchData}>

      {variants.map((variant, key) => (
        <GVDItemVariant key={key} group={group} variant={variant} />
      ))}

    </GVDItem>
  );
};

export default GVDItemGroup;