import React, { Fragment, useEffect, useRef, useState } from "react";
import DashboardHeader from "../../dashboard-components/DashboardHeader";
import { Tab } from "@headlessui/react";
import {
    addProduct, clearProductDetail,
    fetchBrick,
    fetchClass,
    fetchCountryOrigin,
    fetchDetail,
    fetchDetailID,
    fetchFamily,
    fetchGrouping,
    fetchProductDetails,
    fetchSegmentInfo,
    fetchSubmit,
    fetchVariant,
    productSelector,
    setBrick,
    setClass,
    setDetails, setErrorMsg,
    setFamily,
    setGrouping,
    setIndex, setLoading,
    setVariant,
} from "../../store/slices/productSlice";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { useNavigate, useParams } from "react-router-dom";
import { uploadCompanyImage } from "../../store/slices/getStartedSlice";
import { Alert } from "@mui/material";
import { Listbox, Transition } from '@headlessui/react'
import { useTranslation } from "react-i18next";
import ReactCountryFlag from "react-country-flag";

const SelectDropdown = ({ label, name, options, selectedValue, onChange, isRequired }) => (
    <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3">
        <h6 className="w-full max-w-[200px] text-right">
            {isRequired && <span className="text-red-500">*</span>}{label}:
        </h6>
        <select
            name={name}
            onChange={onChange}
            className="rounded-md w-full font-bold style relative px-3 py-2 border shadow-sm sm:text-sm"
            value={selectedValue}
        >
            <option value="">--select--</option>
            {options.map((data) => (
                <option
                    className="font-bold"
                    value={data.ID}
                    key={data.ID}
                    selected={selectedValue == data.ID}
                >
                    {data?.Desc === null ? 'None' : data.Desc}
                </option>
            ))}
        </select>
    </div>
);

const textStyle = {
    color: '#555',
    fontSize: '16px',
    lineHeight: '1.5',
    margin: '10px 15px'
};

const boldTextStyle = {
    fontWeight: 'bold',
    fontSize: '25px',
    textAlign: 'center',
};

const DescContact = () => {
    return (
        <div className="mb-3 space-y-4 rounded-b-xl shadow-md p-4 bg-white h-[calc(110vh-500px)] overflow-y-auto">
            <p style={boldTextStyle}>New Feature Coming Soon</p>

            <p style={textStyle}>Welcome to the "Descriptions" tab, where you can craft compelling narratives for your products tailored to various needs. From concise short descriptions for customer receipts to comprehensive full-length details, this tab empowers you to showcase your products in the best light possible.</p>

            <p style={textStyle}>One noteworthy feature is our support for multilingual descriptions, aligning with GS1 standards to seamlessly share product information across diverse "Target Markets." By entering descriptions in multiple languages, you ensure that your products resonate with customers globally, facilitating effective communication and expanding your market reach. Your efforts here not only enhance the customer experience but also play a crucial role in maximising the potential of your products in various regions. Thank you for contributing to the global accessibility and appeal of your offerings through the "Descriptions" tab.</p>
        </div>
    );
}

const TargetContact = () => {
    return (
        <div className="mb-3 space-y-4 rounded-b-xl shadow-md p-4 bg-white h-[calc(110vh-500px)] overflow-y-auto">
            <p style={boldTextStyle}>New Feature Coming Soon</p>

            <p style={textStyle}>Explore the versatility of the "Target Markets" tab, your gateway to defining the geographical reach of your products. By default, your products are aligned with your specified target market. However, we understand that flexibility is key. This tab allows you to tailor your product availability to match the unique demands of your business.</p>

            <p style={textStyle}>Easily modify and customise your target markets by unchecking defaults and specifying markets that align with your strategic focus. Whether your limitations are specific to certain markets or you sell distinct products to different regions, this tab empowers you to synchronise your offerings with the dynamic landscape of your business. Your careful selections here ensure that your products are positioned precisely where they will thrive, optimising your market presence and streamlining your supply chain. Thank you for utilising the "Target Markets" tab to fine-tune the global footprint of your products.</p>
        </div>
    );
}

const OriginContact = () => {
    return (
        <div className="mb-3 space-y-4 rounded-b-xl shadow-md p-4 bg-white h-[calc(114vh-500px)] overflow-y-auto">
            <p style={boldTextStyle}>New Feature Coming Soon</p>

            <p style={textStyle}>Welcome to the "Origins" tab, a crucial space where you define the local essence of your products. The "Local Content" feature empowers you to provide transparency and authenticity to your customers by specifying the percentage of contents related to the target market where the product is sold.</p>

            <p style={textStyle}>Here, you can showcase the regional identity and origin of your products, instilling confidence in consumers who appreciate the local touch. The "Origins" tab aligns with the growing demand for sustainability and local sourcing. By entering accurate and detailed information about the local content in your products, you not only meet consumer expectations but also contribute to the promotion of local economies.</p>

            <p style={textStyle}>Your commitment to transparency and local engagement through the "Origins" tab not only enhances your product's appeal but also positions your brand as one that values authenticity and ethical practices. Thank you for using this tab to share the unique stories behind your products and strengthen the connection with your audience.</p>
        </div>
    );
}

const ImagesContact = () => {
    return (
        <div className="mb-3 space-y-4 rounded-b-xl shadow-md p-4 bg-white h-[calc(114vh-500px)] overflow-y-auto">
            <p style={boldTextStyle}>New Feature Coming Soon</p>

            <p style={textStyle}>Step into the visual realm with the "Images" tab, where you have the opportunity to make a lasting impression on potential customers. This tab currently focuses on the main product image, serving as a visual anchor for your offerings.</p>

            <p style={textStyle}>Effortlessly upload a captivating representation of your product, as the main image plays a pivotal role in catching the eye of online shoppers. A compelling visual can convey the essence of your product and leave a memorable imprint on consumers.</p>

            <p style={textStyle}>Stay tuned for future updates as we enhance this feature to allow the upload of multiple images. Enabling a broader range of visuals will give consumers a more comprehensive view of your products, fostering a deeper connection and trust. Your dedication to showcasing your products through the "Images" tab contributes to a visually rich and engaging online shopping experience. Thank you for bringing your products to life with striking visuals through this essential tab.</p>
        </div>
    );
}

const AttributesContact = () => {
    return (
        <div className="mb-3 space-y-4 rounded-b-xl shadow-md p-4 bg-white h-[calc(114vh-500px)] overflow-y-auto">
            <p style={boldTextStyle}>New Feature Coming Soon</p>

            <p style={textStyle}>The "Attributes" tab, built on the GS1 standard and extending the Global Classification System (GPC), allows suppliers to provide specific product details within corresponding product groupings. For instance, in a category like "Milk (Perishable)," you can define attributes such as "If Organic," "If with probiotic claim," "Level of Fat Claim," "Manufacturers Treatment/Cooking Process," and "Source."</p>

            <p style={textStyle}>This feature adds granularity to product information, linking attributes to the GPC "Brick," the fourth level of the GPC category system. The Grouping Variation Detail (GVD) also leverages this data, enhancing search capabilities by incorporating attributes alongside its Grouping, Variant, and Detail structure.</p>

            <p style={textStyle}>As our platform evolves with the integration of AI and Natural Language Learning, these attributes, GPC, and GVD functionalities pave the way for unprecedented levels of search precision and refinement. Your engagement with the "Attributes" tab contributes to a richer and more detailed representation of your products, setting the stage for advanced search experiences on our platform.</p>
        </div>
    );
}

const MeasuresContact = () => {
    return (
        <div className="mb-3 space-y-4 rounded-b-xl shadow-md p-4 bg-white h-[calc(114vh-500px)] overflow-y-auto">
            <p style={boldTextStyle}>New Feature Coming Soon</p>

            <p style={textStyle}>Enter the realm of precision with the "Measurement & Sizing" tab, where you have the tools to define and maintain all aspects related to the net contents and sizing of your products. This tab empowers you to capture essential attributes, accounting for both product dimensions and packaging considerations where applicable.</p>

            <p style={textStyle}>The data you input here serves a dual purpose. On the consumer end, it provides valuable information for making informed purchasing decisions, ensuring transparency and accuracy. Simultaneously, this data seamlessly integrates into systems used by warehouses and retailers. The measurements and sizing details are instrumental in calculating storage requirements, determining shelf space at the point of sale, and optimising freight logistics.</p>

            <p style={textStyle}>Your meticulous entries in the "Measurement & Sizing" tab not only enhance the consumer experience but also contribute to the efficiency of the supply chain. Thank you for using this feature to provide comprehensive details that play a crucial role in the logistical aspects of your product journey from storage to sale.</p>
        </div>
    );
}

const PackagingContact = () => {
    return (
        <div className="mb-3 space-y-4 rounded-b-xl shadow-md p-4 bg-white h-[calc(95vh-500px)] overflow-y-auto">
            <p style={boldTextStyle}>New Feature Coming Soon</p>
            <p style={textStyle}>In the "Packaging & Sustainability" tab, you can comprehensively detail all aspects related to your product's packaging. Capture information on packaging types, recyclability, and any potential returnability arrangements. This data not only informs consumers about your packaging choices but also plays a vital role in communicating your commitment to sustainability.</p>
        </div>
    );
}

const AvaliabilityContact = () => {
    return (
        <div className="mb-3 space-y-4 rounded-b-xl shadow-md p-4 bg-white h-[calc(105vh-500px)] overflow-y-auto">
            <p style={boldTextStyle}>New Feature Coming Soon</p>
            <p style={textStyle}>Step into the timeline of your products with the "Product Availability" tab. Here, a series of dates unfold to reveal the lifecycle of your offerings� from release dates and seasonality to discontinuation.</p>
            <p style={textStyle}>This tab not only communicates the availability of a product but also offers control over its status, whether active, viewable for historical reference, or available for comparison purposes. Manage your products dynamically with this feature, ensuring that your audience stays informed and engaged throughout the evolving journey of your offerings.</p>
        </div>
    );
}

const ParentContact = () => {
    return (
        <div className="mb-3 space-y-4 rounded-b-xl shadow-md p-4 bg-white h-[calc(105vh-500px)] overflow-y-auto">
            <p style={boldTextStyle}>New Feature Coming Soon</p>
            <p style={textStyle}>Unlock the interconnected world of your products through the "Parent & Child" tab. Here, you can define relationships between products, such as a can of soft drink available individually or in various packs (4, 6, 10, or 12). Additionally, extend this hierarchy to include shippers and pallets.</p>
            <p style={textStyle}>This feature provides a holistic view, crucial for warehousing, logistics, and retailer ordering. By specifying these relationships, you ensure seamless coordination, optimising the handling and transportation of your products. The "Parent/Child" tab acts as a strategic tool, offering a clear representation of your product ecosystem, from individual units to palletised shipments. Thank you for utilising this tab to streamline the logistical aspects of your product supply chain.</p>
        </div>
    );
}

const AuditContact = () => {
    return (
        <div className="mb-3 space-y-4 rounded-b-xl shadow-md p-4 bg-white h-[calc(105vh-500px)] overflow-y-auto">
            <p style={boldTextStyle}>New Feature Coming Soon</p>
            <p style={textStyle}>Delve into transparency with the "Audit Data" tab, your window into the history of changes to underlying data. This feature provides a detailed log of alterations, showcasing when and why modifications were made. If the change isn't a "System Change," the user responsible for the action is tracked.</p>
            <p style={textStyle}>Primarily designed for error checking and gaining insights into data adjustments, the "Audit Data" tab ensures accountability and a comprehensive understanding of shifts in product details. It's a valuable tool for maintaining data accuracy, facilitating error identification, and fostering a deeper comprehension of your product information. Thank you for utilising this tab to enhance the reliability and integrity of your data.</p>
        </div>
    );
}

const GS1Contact = () => {
    return (
        <div className="mb-3 space-y-4 rounded-b-xl shadow-md p-4 bg-white h-[calc(105vh-500px)] overflow-y-auto">
            <p style={boldTextStyle}>New Feature Coming Soon</p>
            <p style={textStyle}>Gain a comprehensive view of data distinctions with the "GS1 Data" tab. This feature allows you to discern differences between our system's data and the GS1 source data from GS1 Australia, GS1 United States, and the Global GS1 GetAll database. It serves as a valuable tool to highlight where our system has updated data compared to the original GS1 source.</p>
            <p style={textStyle}>This insight empowers users to understand and leverage the enhancements made within our system, facilitating informed decision-making. The "GS1 Data" tab simplifies the process of identifying variations, providing peace of mind and assurance that changes to be exported and uploaded are correct and accurate. Thank you for utilising this feature to navigate and optimise data consistency across global GS1 databases.</p>
        </div>
    );
}



const CategoryComponent = ({
    segments,
    family,
    productClass,
    brick,
    grouping,
    variant,
    details,
    product,
    handleChange }) => {
    return (
        <div className="mb-3 space-y-4 rounded-b-xl shadow-md p-4 bg-white h-[calc(100vh-500px)] overflow-y-auto">
            <div className="bg-gray-200 py-2 px-4 w-full max-w-sm">
                <p className="font-semibold text-base">
                    Global Product Classification (GPC)
                </p>
            </div>

            <SelectDropdown
                label="Segment"
                name="FK_SegmentID"
                options={segments}
                selectedValue={product.FK_SegmentID}
                onChange={handleChange}
                isRequired={true}
            />

            <SelectDropdown
                label="Family"
                name="FK_FamilyID"
                options={family}
                selectedValue={product.FK_FamilyID}
                onChange={handleChange}
                isRequired={true}
            />

            <SelectDropdown
                label="Class"
                name="FK_ClassID"
                options={productClass}
                selectedValue={product.FK_ClassID}
                onChange={handleChange}
                isRequired={true}
            />

            <SelectDropdown
                label="Brick"
                name="FK_BrickID"
                options={brick}
                selectedValue={product.FK_BrickID}
                onChange={handleChange}
                isRequired={true}
            />

            <div className="bg-gray-200 py-2 px-4 w-full max-w-sm">
                <p className="font-semibold text-base">
                    Grouping Variant Detail (GVD)
                </p>
            </div>

            <SelectDropdown
                label="Grouping"
                name="FK_GroupingID"
                options={grouping}
                selectedValue={product.FK_GroupingID}
                onChange={handleChange}
                isRequired={false}
            />

            <SelectDropdown
                label="Variant"
                name="FK_VariantID"
                options={variant}
                selectedValue={product.FK_VariantID}
                onChange={handleChange}
                isRequired={false}
            />

            <SelectDropdown
                label="Detail"
                name="FK_DetailID"
                options={details}
                selectedValue={product.FK_DetailID}
                onChange={handleChange}
                isRequired={false}
            />
        </div>
    );
};


const NoContact = () => {
    return (
        <div className="h-40 flex justify-center items-center"><h1 className="lg:text-4xl text-2xl">New Feature Coming Soon</h1></div>
    );
}

const SupplierProduct = () => {
    const [selectedTab, setSelectedTab] = useState("")
    const dispatch = useAppDispatch();
    const { GTIN } = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation(["common"]);
    const { product, wizardIndex, segments, countryOrigins, family, productClass, brick, grouping, variant, details, brands, errorMsg, loading } = useAppSelector(productSelector);
    const [uploadedFile, setUploadedFile] = useState(null);
    const tabPanelsData = [
        {
            label: "Descriptions",
            component: DescContact
        },
        {
            label: "Target Markets",
            component: TargetContact
        },
        {
            label: "Origins",
            component: OriginContact
        },
        {
            label: "Images",
            component: ImagesContact
        },
        {
            label: "Categories",
            component: () => <CategoryComponent
                segments={segments}
                family={family}
                productClass={productClass}
                brick={brick}
                grouping={grouping}
                variant={variant}
                details={details}
                product={product}
                handleChange={(e) => setFormField(e.target.name, e.target.value)}
            />,
        },
        {
            label: "Attributes",
            component: AttributesContact
        },
        {
            label: "Measures & Sizing",
            component: MeasuresContact
        },
        {
            label: "Packaging",
            component: PackagingContact
        },
        {
            label: "Sustainability",
            component: PackagingContact
        },
        {
            label: "Avaliability (Dates)",
            component: AvaliabilityContact
        },
        {
            label: "Parent / Child",
            component: ParentContact
        },
        {
            label: "Related Products",
            component: NoContact
        },
        {
            label: "Audit Data",
            component: AuditContact
        },
        {
            label: "GS1 Data",
            component: GS1Contact
        },
    ];


    const { BrandID, Brand, BrandSub } = product || {};
    const selectedBrandID = BrandID ?? "000";
    const selectedBrand = Brand ?? "-- Select From List";
    const selectedBrandSub = BrandSub ?? "--";

    const isBrandListAvailable = brands && brands?.[0];

    useEffect(() => {
        setSelected({
            BrandID: isBrandListAvailable ? selectedBrandID : "000",
            Brand: isBrandListAvailable ? selectedBrand : "-- Select From List",
            BrandSub: isBrandListAvailable ? selectedBrandSub : "--"
        });
    }, [product.BrandID]);

    const [selected, setSelected] = useState({ BrandID: selectedBrandID, Brand: selectedBrand, BrandSub: selectedBrandSub });
    const [showSuccess, setShowSuccess] = useState(false);


    const fileInputRef = useRef(null);
    const fetchData = async () => {
        await dispatch(setLoading(false))
        await dispatch(setErrorMsg(""))
        await dispatch(clearProductDetail())
        await dispatch(fetchProductDetails(GTIN));
    };

    const fetchClassificationData = async () => {
        if (product?.SegmentID) {
            await dispatch(fetchFamily(product?.SegmentID));
        }
        if (product?.FamilyID) {
            await dispatch(fetchClass(product?.FamilyID));
        }
        if (product?.ClassID) {
            await dispatch(fetchBrick(parseInt(product?.ClassID)));
        }
        if (product?.BrickID) {
            await dispatch(fetchGrouping(product?.BrickID));
        }
        if (product?.GroupingID) {
            await dispatch(fetchVariant(product?.GroupingID));
        }
        if (product?.VariantID) {
            await dispatch(fetchDetail(product?.VariantID));
        }
    };

    useEffect(() => {

        const fetchDataAndClassification = async () => {
            await fetchData();
            await fetchClassificationData();
        };

        fetchDataAndClassification();
    }, [GTIN]);

    const segmentInfo = async () => {
        if (wizardIndex === 4) {
            await dispatch(fetchSegmentInfo());
            await dispatch(fetchCountryOrigin());
        }
    }

    const setFormField = (key, value) => {
        let data = { ...product };

        const deleteMap = {
            FK_SegmentID: ['FK_BrickID', 'FK_ClassID', 'FK_DetailID', 'FK_GroupingID', 'FK_VariantID', 'FK_FamilyID'],
            FK_FamilyID: ['FK_BrickID', 'FK_ClassID', 'FK_DetailID', 'FK_GroupingID', 'FK_VariantID'],
            FK_ClassID: ['FK_BrickID', 'FK_DetailID', 'FK_GroupingID', 'FK_VariantID'],
            FK_BrickID: ['FK_DetailID', 'FK_GroupingID', 'FK_VariantID'],
        };

        deleteMap[key]?.forEach(propToDelete => {
            delete data[propToDelete];
            switch (propToDelete) {
                case 'FK_SegmentID':
                    dispatch(setFamily([]));
                    dispatch(setClass([]));
                    dispatch(setBrick([]));
                    dispatch(setGrouping([]));
                    dispatch(setVariant([]));
                    dispatch(setDetails([]));
                    break;
                case 'FK_FamilyID':
                    dispatch(setClass([]));
                    dispatch(setBrick([]));
                    dispatch(setGrouping([]));
                    dispatch(setVariant([]));
                    dispatch(setDetails([]));
                    break;
                case 'FK_ClassID':
                    dispatch(setBrick([]));
                    dispatch(setGrouping([]));
                    dispatch(setVariant([]));
                    dispatch(setDetails([]));
                    break;
                case 'FK_BrickID':
                    dispatch(setGrouping([]));
                    dispatch(setVariant([]));
                    dispatch(setDetails([]));
                    break;
                // Add more cases if needed
                default:
                    break;
            }
        });

        data[key] = value;
        dispatch(addProduct(data));
    };

    useEffect(() => {
        segmentInfo()
    }, [wizardIndex]);

    useEffect(() => {
        product.FK_SegmentID && dispatch(fetchFamily(product.FK_SegmentID));
        product.FK_FamilyID && dispatch(fetchClass(product.FK_FamilyID));
        product.FK_ClassID && dispatch(fetchBrick(parseInt(product.FK_ClassID)));
        product.FK_BrickID && dispatch(fetchGrouping(product.FK_BrickID));
        product.FK_GroupingID && dispatch(fetchVariant(product.FK_GroupingID));
        product.FK_VariantID && dispatch(fetchDetail(product.FK_VariantID));
    }, [product.FK_SegmentID, product.FK_FamilyID, product.FK_ClassID, product.FK_BrickID, product.FK_GroupingID, product.FK_VariantID, dispatch]);

    const handleUpload = async (e) => {

        const file = e.target.files[0];

        if (!file) {
            // Handle error: No file selected
            return;
        }

        if (file && /\.(jpg|jpeg|png)$/i.test(file.name)) {
            setUploadedFile(file);
            dispatch(setErrorMsg('')); // Clear any previous error
        } else {
            dispatch(setErrorMsg('Invalid file format. Please upload a JPG, JPEG, or PNG file.'));
        }

        setUploadedFile(file);
        const formData = new FormData();
        formData.append("type", "product");
        formData.append("id", product?.ProductID?.toString());
        formData.append("image", file);
        await dispatch(uploadCompanyImage(formData));
    };

    useEffect(() => {
        let successTimeout;

        if (showSuccess) {
            successTimeout = setTimeout(() => {
                setShowSuccess(false);
            }, 2000); // Display success message for 2 seconds
        }

        return () => {
            // Clear the timeout on component unmount or when showSuccess changes
            clearTimeout(successTimeout);
        };
    }, [showSuccess]);

    const handleQuickUpload = async (key, value) => {
        if (key === 'NetContent') {
            await dispatch(
                fetchSubmit({
                    ProductID: product.ProductID,
                    LabellingAustralianContentPercent: value,
                    [key]: value,
                })
            );
        }
        else {
            await dispatch(
                fetchSubmit({
                    ProductID: product.ProductID,
                    [key]: value,
                })
            );
        }
        setShowSuccess(true)
    }

    const submitHandler = async () => {
        dispatch(setLoading(true));
        dispatch(setErrorMsg('')); // Reset error message

        const requiredKeys = [
            { key: 'ProductID', message: 'Product id' },
            { key: 'FK_SegmentID', message: 'Segment' },
            { key: 'FK_ClassID', message: 'Class' },
            { key: 'FK_FamilyID', message: 'Family' },
            { key: 'FK_BrickID', message: 'Brick' },
        ];

        const missingKey = requiredKeys.find(({ key }) => {
            const value = product?.[key];

            // Check if the key is missing or has a value of undefined or null
            return value === undefined || value === null || (typeof value === 'string' && value.trim() === '');
        });

        if (missingKey) {
            dispatch(setLoading(false));
            const validationText = `Validation error: ${missingKey.message} is missing`;
            dispatch(setErrorMsg(validationText));
            return; // Exit early if there's a validation error
        }

        const {
            ProductID,
            FK_SegmentID,
            FK_ClassID,
            FK_FamilyID,
            FK_BrickID,
            FK_GroupingID,
            FK_VariantID,
            FK_DetailID,
        } = product;

        // Convert values to integers
        const parsedSegmentID = parseInt(FK_SegmentID);
        const parsedFamilyID = parseInt(FK_FamilyID);
        const parsedClassID = parseInt(FK_ClassID);
        const parsedBrickID = parseInt(FK_BrickID);
        const parsedGroupingID = parseInt(FK_GroupingID);
        const parsedVariantID = parseInt(FK_VariantID);
        const parsedDetailID = parseInt(FK_DetailID);

        // Dispatch fetchDetailID action
        dispatch(fetchDetailID({
            SegmentID: parsedSegmentID,
            FamilyID: parsedFamilyID,
            ClassID: parsedClassID,
            BrickID: parsedBrickID,
            GroupingID: parsedGroupingID,
            VariantID: parsedVariantID,
        }))
            .then((res) => {
                if (!errorMsg && res) {
                    dispatch(
                        fetchSubmit({
                            ProductID,
                            FK_DetailID: res,
                        })
                    );
                    setShowSuccess(true);
                }
            })
            .finally(() => {
                dispatch(setLoading(false));
            });
    };

    const cancelHandler = () => {
        navigate(-1); // Navigate back in the browser history
    };

    const handleButtonClick = () => {
        // Trigger a click on the file input
        fileInputRef.current.click();
    };

    const isPropertyFilled = (property) => property !== undefined && property !== null && property !== "";

    const is_filled = (
        isPropertyFilled(product.FK_SegmentID) &&
        isPropertyFilled(product.FK_ClassID) &&
        isPropertyFilled(product.FK_FamilyID) &&
        isPropertyFilled(product.FK_BrickID)
    );

    const renderCountryFlag = (value, data, title) => {
        // if it is New Zealand show a speficic image
        if (value == 'NZ') {
            return <ReactCountryFlag
                countryCode={value}
                svg
                cdnUrl={window.location.origin + "/country-flags/"}
                style={{
                    width: '2em',
                    height: '2em',
                    objectFit: 'cover'
                }}
                title={title}
            />
        }
        return <ReactCountryFlag
            countryCode={value}
            svg
            style={{
                width: '2em',
                height: '2em',
            }}
            title={title}
        />
    };
    return (
        <div className="w-full mx-auto">
            <DashboardHeader
                title={""}
                nav={[]}
            />

            <div className="my-5 rounded-xl shadow-md p-2 bg-white">
                <div className="grid grid-cols-12 gap-10 place-items-center">
                    <div className="lg:col-span-9 md:col-span-6 sm:col-span-9 col-span-6 w-full space-y-4">
                        <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3">
                            <h6 className="w-full max-w-[200px] text-right">
                                GTIN (Barcode):
                            </h6>
                            <p className={'font-bold'}>{product?.GTIN}</p>
                        </div>
                        <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3">
                            <h6 className="w-full max-w-[200px] text-right">Brand:</h6>
                            <Listbox value={selected} onChange={(e) => {
                                setSelected(e)
                                setFormField('FK_BrandID', e.BrandID)
                                handleQuickUpload('FK_BrandID', e.BrandID)
                            }}>
                                <div className="relative mt-1 w-full">
                                    <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-sm border">
                                        <span className="block truncate font-bold">
                                            {selected?.Brand}  -  {selected?.BrandSub}
                                        </span>
                                    </Listbox.Button>
                                    <Transition
                                        as={Fragment}
                                        leave="transition ease-in duration-100"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    >
                                        <Listbox.Options className="absolute z-50 mt-1 max-h-60 w-full overflow-hidden rounded-md bg-white py-1 text-base shadow-lg sm:text-sm">
                                            <div className="grid grid-cols-2">
                                                <div className="bg-gray-300 pl-4 py-2">
                                                    <h6 className="font-bold">Brand</h6>
                                                </div>
                                                <div className="bg-gray-300 pl-4 py-2">
                                                    <h6 className="font-bold">Sub Brand</h6>
                                                </div>
                                            </div>
                                            <div className=" overflow-y-auto h-48">
                                                {brands.map((brand, brandIdx) => (
                                                    <Listbox.Option
                                                        key={brandIdx}
                                                        className={({ active }) =>
                                                            `relative cursor-default grid border-b grid-cols-2 pl-4 py-2 select-none ${active ? "bg-gray-100 text-gray-900" : "text-gray-900"
                                                            }`
                                                        }
                                                        value={brand}
                                                    >
                                                        {({ selected }) => (
                                                            <>
                                                                <span
                                                                    className={`block truncate ${selected ? "font-medium" : "font-normal"
                                                                        }`}
                                                                >
                                                                    {brand?.Brand}
                                                                </span>
                                                                <span
                                                                    className={`block truncate ${selected ? "font-medium" : "font-normal"
                                                                        }`}
                                                                >
                                                                    {brand?.BrandSub || ""}
                                                                </span>
                                                            </>
                                                        )}
                                                    </Listbox.Option>
                                                ))}
                                            </div>
                                        </Listbox.Options>
                                    </Transition>
                                </div>
                            </Listbox>
                        </div>
                        {!isBrandListAvailable && (
                            <p className="w-full max-w-[570px] text-right text-black mt-2">For Brand Maintenance, contact our Team using Chat Support</p>
                        )}
                        <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3">
                            <h6 className="w-full max-w-[200px] text-right">
                                Description:
                            </h6>
                            <input
                                onChange={(e) => setFormField('TradeItemDesc', e.target.value)}
                                value={product?.TradeItemDesc}
                                placeholder="Description"
                                className="rounded-md w-full font-bold style relative px-3 py-2 border shadow-sm sm:text-sm"
                                type="text"
                                onBlur={(e) => {
                                    if (!e.relatedTarget || !e.currentTarget.contains(e.relatedTarget)) {
                                        handleQuickUpload('TradeItemDesc', e.target.value)
                                    }
                                }}
                            />
                        </div>
                        <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3">
                            <h6 className="w-full max-w-[200px] text-right">
                                Local Content:
                            </h6>
                            <select
                                className="rounded-md w-[20%] font-bold style relative px-3 py-2 border shadow-sm sm:text-sm"
                                onChange={(e) => {
                                    setFormField('NetContent', e.target.value)
                                    handleQuickUpload('NetContent', e.target.value)
                                }}
                            >
                                {/* Populate options dynamically from 0 to 100 */}
                                <option className="font-bold" value="null" >--Select-- </option>
                                {[...Array(101).keys()].map((value) => (
                                    <option className="font-bold" key={value} value={value} selected={product?.NetContent == value}>
                                        {value === 0 ? "NIL" : value}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3">
                            <h6 className="w-full max-w-[200px] text-right">
                                Country of Origin:
                            </h6>
                            <select
                                value={product?.CountryOfOriginCodeN3}
                                onChange={(e) => {
                                    setFormField('CountryOfOrigin', e.target.value)
                                    handleQuickUpload('CountryOfOrigin', e.target.value)
                                }}
                                className="rounded-md w-full font-bold style relative px-3 py-2 border shadow-sm sm:text-sm"
                            >
                                {countryOrigins.map((country, index) => (
                                    <option className="font-bold" key={index} value={country.CountryCodeN3} selected={product?.ProductCountryCodeN3 === country.CountryCodeN3}>
                                        {country.CountryNameDisplay}
                                    </option>
                                ))}
                            </select>
                        </div>

                        {/* <div className="flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3">
                            <h6 className="w-full max-w-[200px] text-right">
                                Country of Ownership:
                            </h6>
                            {renderCountryFlag(product?.OwnershipCountryCodeAN2, product, product.OwnershipCountryCodeAN2)}
                        </div> */}
                    </div>
                    <div className="flex w-full flex-col lg:col-span-3 md:col-span-6 col-span-12 lg:flex-nowrap md:flex-nowrap flex-wrap items-start gap-3">
    <h6 className="w-full max-w-[200px]">Product Image:</h6>
    <div className="flex items-center justify-start w-full">
        <label
            htmlFor="file"  // Corrected attribute name
            className="flex flex-col items-center justify-center w-[150px] h-[150px] border-2 border-gray-300 border-dashed rounded-lg bg-gray-100 hover:bg-gray-200"
        >
            {uploadedFile ? (
                <img
                    src={URL.createObjectURL(uploadedFile)}
                    alt="Uploaded"
                    className="w-[150px] h-[150px] object-contain"
                />
            ) : product.ProductImageURL !== null ? (
                <img src={product?.ProductImageURL} className="w-[150px] h-[145px] object-contain" alt={product?.PrefixID} />
            ) : (
                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                    <img src={'/temp.jpg'} alt={'temp'} className="w-[150px] h-[145px] object-fill rounded-1" />
                </div>
            )}
        </label>
    </div>
    <button
        onClick={handleButtonClick}
        className={`${uploadedFile ? 'bg-primary text-white' : 'bg-gray-300 text-base'} rounded flex justify-center items-center py-2 w-[150px]`}
    >
        + Image
    </button>
    <input
        type="file"
        id="file" 
        className="hidden"
        accept="image/*" 
        onChange={handleUpload}
        ref={fileInputRef}
    />
</div>
                </div>
                <div className={'justify-center flex lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-3'}>
                    {showSuccess && <Alert severity="success">{t("common:submit_details_successful").toString()}</Alert>}
                </div>
            </div>
            <Tab.Group defaultIndex={wizardIndex} onChange={(index) => { dispatch(setIndex(index)) }}>

                <Tab.List className="text-sm w-full flex flex-wrap gap-0 overflow-x-auto font-medium text-center bg-primary pt-5 text-gray-500">
                    {tabPanelsData.map((panel, index) => (
                        <Tab key={index} as={Fragment}>
                            {({ selected }) => {
                                if (selected) {
                                    setSelectedTab(panel.label)
                                }
                                return (
                                    <button
                                        className={`${selected
                                            ? 'bg-white text-black border-none'
                                            : 'text-white bg-primary border-none '
                                            } capitalize w-max !overflow-visible relative font-medium text-sm px-2 py-2`}
                                        onClick={() => {
                                            document.getElementById('scrollView').scrollIntoView({ behavior: 'smooth' });
                                            setSelectedTab(panel.label)
                                        }}
                                    >
                                        {panel.label}
                                    </button>
                                )
                            }}
                        </Tab>
                    ))}
                </Tab.List>

                <Tab.Panels id={'scrollView'}>
                    {tabPanelsData.map((panel, index) => (
                        <Tab.Panel>
                            {panel.component || ''}
                        </Tab.Panel>
                    ))}
                </Tab.Panels>
            </Tab.Group>

            <div className="mb-3">
                {errorMsg !== "" && <Alert severity="error">{errorMsg}</Alert>}
            </div>

            {selectedTab === "Categories" && <div className="mt-3 flex justify-center items-center gap-5">
                <button
                    onClick={cancelHandler}
                    disabled={loading}
                    className="bg-gray-400 py-2 rounded-lg text-white w-24 px-4"
                >
                    Cancel
                </button>

                <button
                    onClick={submitHandler}
                    disabled={loading || !is_filled}
                    className={`${loading || !is_filled ? "bg-gray-500" : "bg-primary"
                        } py-2 w-24 rounded-lg text-white`}
                >
                    {loading ? "Saving..." : !is_filled ? "Save" : "Save"}
                </button>
            </div>}
            <div className={'justify-center items-center space-x-4 w-max m-auto flex mt-3'}>
                {showSuccess && loading && <Alert severity="success">{t("common:submit_details_successful").toString()}</Alert>}
            </div>

        </div>
    );
};

export default SupplierProduct;
